import { connect } from 'react-redux';
import { click, convert } from '@App/store/actions/searchAnalytics';
import InsightsEvent from '@App/searchAnalytics/insightsEvent';
import PropTypes from 'prop-types';

/**
 * Adds an onClick handler to a component that dispatches click & conversion analytics events.
 */
export function withAnalyticsOnClickHandler(component) {
    const Wrapped = connect(
        null,
        (dispatch, props) => ({
            onClick: () => {
                // Generate click & conversion analytics events:
                dispatch(click(
                    InsightsEvent.TRACK_ADDED_TO_PROJECT,
                    props.trackReference,
                    props.trackedQueryID,
                    props.searchResultsPosition,
                ));
                dispatch(convert(
                    InsightsEvent.TRACK_ADDED_TO_PROJECT,
                    props.trackReference,
                    props.trackedQueryID,
                ));
            },
        }),
    )(component);

    Wrapped.propTypes = {
        trackedQueryID: PropTypes.string,
        searchResultsPosition: PropTypes.number,
        trackReference: PropTypes.string,
    };

    Wrapped.defaultProps = {
        trackedQueryID: null,
        searchResultsPosition: null,
    };

    return Wrapped;
}

/**
 * Adds the trackedQueryID prop to a component, from search store value.
 */
export const withTrackedQueryID = connect((state) => ({
    trackedQueryID: state.search.searchTracksQueryID,
}));
