import { useSelector } from 'react-redux';
import { emptyPermissions } from '@App/store/reducers/authentication';

/**
 * @see back/assets/front/js/app/store/reducers/authentication.js
 */
export default function useAuth() {
    const authentication = useSelector(state => state.authentication);

    if (null === authentication.id) {
        return {
            authenticated: false,
            profile: null,
            permissions: emptyPermissions,
        };
    }

    return {
        authenticated: true,
        id: authentication.id,
        profile: {
            id: authentication.id,
            ...authentication.profile,
        },
        permissions: authentication.permissions,
    };
}
