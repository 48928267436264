import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { get } from '@App/container';
import NotFound from '@App/components/page/error/NotFound';
import BreadCrumb from '@App/components/navigation/BreadCrumb';
import Loader from '@App/components/ui/Loader';
import {useQuery} from '@apollo/client';
import ShowLabel from '@App/api/query/ShowLabel.graphql';
import AlbumList from '@App/components/model/album/AlbumList';
import Nl2br from '@App/components/common/Nl2br';
import RedirectError from '@App/error/RedirectError';
import { LABEL } from '@App/routes';

export default function Label({
    reference, slug
}) {
    const labelQueryOptions = { variables: { reference }};
    const { loading, data } = useQuery(ShowLabel, labelQueryOptions);
    const label = data?.label;
    const logo = label?.logoAsLargeThumbnail;

    if (loading) {
        return <LoadingLabel />;
    }

    if (null === data.label) {
        return <NotFound />;
    }

    if (slug !== data.label.slug) {
        throw new RedirectError('Slug mismatch', { url: LABEL.getUrl({ reference, slug }) });
    }

    // Update meta tags:
    get('meta').set(
        I18n.t('meta.content.label.title', { label: label.name }),
        label.description,
        logo
    );

    return (
        <div className="content">
            <div className="container-fluid">
                <BreadCrumb dark />
                <div className="label-infos">
                    <div className="label-infos__image" style={logo ? { backgroundImage: `url('${logo}')` } : null}></div>
                    <div className="label-infos__detail">
                        <h1>
                            {label.name}
                            <span>{label.countryAsEmoji}</span>
                        </h1>
                        <p><Nl2br content={label.description} /></p>
                    </div>
                </div>
                <h2>{I18n.t('page.library.label.subTitle', { labelName: label.name})}</h2>
                <AlbumList labelReference={reference} displayLabel={false} />
            </div>
        </div>
    );
}

function LoadingLabel() {
    return (
        <div className="content">
            <div className="container-fluid">
                <BreadCrumb dark />
                <Loader />
            </div>
        </div>
    );
}

Label.propTypes = {
    reference: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
};
