import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import LatestTracks from '@App/api/query/LatestTracks.graphql';
import Query from '@App/components/api/Query';
import TrackVersionList, { mapTrackToVersion } from '@App/components/model/TrackVersionList';
import BlockTitle from '@App/components/layout/BlockTitle';
import Button from '@App/components/ui/Button';
import Loader from '@App/components/ui/Loader';
import AnalyticsPlatformTag from '@App/searchAnalytics/AnalyticsPlatformTag';
import AnalyticsTag from '@App/searchAnalytics/AnalyticsTag';
import ShowAllTagsButton from '@App/components/model/track/ShowAllTagsButton';
import { SIZE_LG } from '@App/service/MobileWatcher';

LatestReleases.propTypes = {
    hasNextPage: PropTypes.bool.isRequired,
    tracks: TrackVersionList.propTypes.versions,
    onLoadMore: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

function LatestReleases ({ loading, tracks, hasNextPage, onLoadMore }) {
    const [loadingMore, setLoadingMore] = useState(false);
    const onClick = () => {
        setLoadingMore(true);
        onLoadMore().finally(() => setLoadingMore(false));
    };

    return (
        <div key="latest-releases" id="latest-releases">
            <BlockTitle key="latest-releases-title" title={I18n.t('page.library.latest-releases.title')}>
                <ShowAllTagsButton />
            </BlockTitle>
            {loading ? <Loader key="latest-releases-loader" /> : <TrackVersionList key="latest-releases-list" versions={tracks.map(track => mapTrackToVersion(track))} />}
            {hasNextPage ? <Button key="latest-releases-more" loading={loadingMore} onClick={onClick} label={I18n.t('page.library.latest-releases.more')} /> : null}
        </div>
    );
}

function mapResult(data) {
    return {
        hasNextPage: data.searchTracks.pageInfo.hasNextPage,
        tracks: data.searchTracks.edges.map(edge => edge.node.track),
        loading: false,
    };
}

function mapLoading() {
    return {
        hasNextPage: false,
        tracks: [],
        loading: true,
    };
}

function LatestReleasesWrapper(props) {
    const { limit, mobile } = props;
    const variables = {
        limit,
        analyticsTags: [AnalyticsPlatformTag.from(mobile), AnalyticsTag.LATEST_TRACKS],
    };

    return (
        <Query
            component={LatestReleases}
            loadingComponent={LatestReleases}
            query={LatestTracks}
            variables={variables}
            mapResult={mapResult}
            mapLoading={mapLoading}
            loadMore="searchTracks"
        />
    );
}

LatestReleasesWrapper.propTypes = {
    limit: PropTypes.number,
    mobile: PropTypes.bool.isRequired,
};

LatestReleasesWrapper.defaultProps = {
    limit: 30,
};

export default connect(
    state => ({
        mobile: state.navigation.size < SIZE_LG,
    }),
)(LatestReleasesWrapper);

