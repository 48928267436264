import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import { showInformationsPanel } from '@App/store/actions/track';
import Button from '@App/components/ui/Button';

function TrackInformationsButton(props) {
    const { onClick, ...childProps } = props;
    const label = I18n.t('model.track.actions.informations');

    delete childProps.trackReference;
    delete childProps.versionReference;

    return <Button onClick={onClick} label={label} {...childProps} />;
}

TrackInformationsButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const TrackInformationsButtonWrapper = connect(
    null,
    (dispatch, props) => ({
        onClick: () => dispatch(showInformationsPanel(props.trackReference, props.versionReference))
    })
)(TrackInformationsButton);

TrackInformationsButtonWrapper.propTypes = {
    trackReference: PropTypes.string.isRequired,
    versionReference: PropTypes.string,
};

TrackInformationsButtonWrapper.defaultProps = {
    versionReference: null,
};

export default TrackInformationsButtonWrapper;
