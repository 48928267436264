import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GetTrack from '@App/api/query/GetTrack.graphql';
import Query from '@App/components/api/Query';
import Player from '@App/components/player/Player';
import { SIZE_LG } from '@App/service/MobileWatcher';
import { pause, play, playTrack } from '@App/store/actions/player';
import { getNextTrack, getPreviousTrack } from '@App/store/reducers/player';
import { trackPlayed } from '@App/store/actions/stats';
import Highlight from '@App/model/similarSearch/Highlight';

function mapResult(props, data) {
    const { authenticated, trackReference, versionReference } = props;
    const { track } = data;
    const { album, versions } = track;
    const version = getVersion(versions, versionReference);

    if (!version) {
        throw new Error(`Could not find version for track "${trackReference}" and version "${versionReference}".`);
    }

    return {
        title: track.title,
        waveform: version.waveform,
        duration: version.duration,
        audioUrl: authenticated ? version.audioFileUrl : version.previewAudioFileUrl,
        cover: album.coverAsThumbnail,
        nbVersions: versions.length,
        versionReference: version.reference,
        albumReference: album.reference,
        loading: false,
        adminEditUrl: version.adminEditUrl
    };
}

function getVersion(versions, versionReference = null) {
    if (versionReference === null) {
        return versions.find(version => version.main);
    }

    return versions.find(version => version.reference === versionReference);
}

/**
 * GraphQL wrapped Player
 *
 * @param {Object} props
 */
function PlayerWrapper(props) {
    const {
        trackReference,
        versionReference,
        startPosition,
        highlights,
        mobile,
        reduced,
        authenticated,
        playTrack,
        previousTrack,
        nextTrack,
        onPlay,
        onPause,
        modeLoop,
        hasStreamingFeature
    } = props;

    if (!trackReference) {
        return null;
    }

    return <Query
        component={Player}
        loadingComponent={Player}
        query={GetTrack}
        variables={{ reference: trackReference }}
        mapResult={data => mapResult(props, data)}
        mapLoading={() => ({ loading: true })}
        childProps={{
            trackReference,
            versionReference,
            startPosition,
            highlights,
            mobile,
            reduced,
            authenticated,
            playTrack,
            previousTrack,
            nextTrack,
            onPlay,
            onPause,
            modeLoop,
            hasStreamingFeature
        }}
    />;
}

PlayerWrapper.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    mobile: PropTypes.bool.isRequired,
    reduced: PropTypes.bool.isRequired,
    playTrack: PropTypes.func.isRequired,
    trackReference: PropTypes.string,
    versionReference: PropTypes.string,
    startPosition: PropTypes.number,
    highlights: PropTypes.arrayOf(Highlight),
    previousTrack: PropTypes.shape({
        trackReference: PropTypes.string.isRequired,
        versionReference: PropTypes.string.isRequired,
        highlights: PropTypes.arrayOf(Highlight),
    }),
    nextTrack: PropTypes.shape({
        trackReference: PropTypes.string.isRequired,
        versionReference: PropTypes.string.isRequired,
        highlights: PropTypes.arrayOf(Highlight),
    }),
    onPlay: PropTypes.func.isRequired,
    onPause: PropTypes.func.isRequired,
    modeLoop: PropTypes.bool.isRequired,
    hasStreamingFeature: PropTypes.bool.isRequired,
};

PlayerWrapper.defaultProps = {
    trackReference: null,
    versionReference: null,
    highlights: [],
    nextTrack: null,
    previousTrack: null,
    modeLoop: false,
    hasStreamingFeature: false,
};

/**
 * Redux drived Player
 */
const PlayerContainer = connect(
    state => ({
        authenticated: state.authentication.id !== null,
        mobile: state.navigation.size < SIZE_LG,
        reduced: state.navigation.size < SIZE_LG,
        trackReference: state.player.trackReference,
        versionReference: state.player.versionReference,
        startPosition: state.player.startPosition,
        highlights: state.player.highlights,
        previousTrack: getPreviousTrack(state),
        nextTrack: getNextTrack(state),
    }),
    (dispatch, props) => ({
        playTrack: (trackReference, versionReference, startPosition = 0, highlights = []) => {
            if (props.dispatchTrackPlayedStat) {
                dispatch(trackPlayed(versionReference, props.hasStreamingFeature));
            }
            dispatch(playTrack(trackReference, versionReference, startPosition, highlights));
        },
        onPlay: () => dispatch(play()),
        onPause: () => dispatch(pause()),
    })
)(PlayerWrapper);

PlayerContainer.propTypes = {
    dispatchTrackPlayedStat: PropTypes.bool,
    modeLoop: PropTypes.bool,
    hasStreamingFeature: PropTypes.bool,
};

export default connect((state) => ({
    dispatchTrackPlayedStat: !state.authentication.permissions.isAdmin && !state.authentication.permissions.isStaff,
    modeLoop: state.player.modeLoop,
    hasStreamingFeature: state.authentication?.permissions?.hasStreamingFeature === true
}))(PlayerContainer);
