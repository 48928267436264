import DownloadForbiddenPanel from '@App/components/page/DownloadForbiddenPanel';
import {TYPE_TRACK, TYPE_PROJECT, TYPE_PLAYLIST} from '@App/store/reducers/download';
import GetTrackVersions from '@App/api/query/GetTrackVersions.graphql';
import Query from '@App/components/api/Query';
import DownloadTrackPanel from '@App/components/page/DownloadTrackPanel';
import DownloadProjectPanel from '@App/components/page/DownloadProjectPanel';
import DownloadProjectForbiddenPanel from '@App/components/page/DownloadProjectForbiddenPanel';
import DownloadPlaylistPanel from '@App/components/page/DownloadPlaylistPanel';
import {useDispatch} from 'react-redux';
import { isPlayerActive } from '@App/store/reducers/player';
import {useAppSelector} from '@App/store';
import { closeDownloadPanel } from '@App/store/actions/download';

export default function DownloadPanelContainer() {
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeDownloadPanel());
  const type = useAppSelector(state => state.download.type);
  const authenticated = useAppSelector(state => state.authentication.id != null);
  const trackReference = useAppSelector(state => state.download.trackReference);
  const versionReference = useAppSelector(state => state.download.versionReference);
  const projectId = useAppSelector(state => state.download.projectId);
  const playlistId = useAppSelector(state => state.download.playlistId);
  const withPlayer = useAppSelector(state => isPlayerActive(state));
  const canDownloadPlaylist = useAppSelector(state => state.authentication.permissions.isAdmin || state.authentication.permissions.isStaff);
  const canDownload = useAppSelector((state) => state.authentication.permissions.canDownload);

  if (!type) {
    return null;
  }

  if (!authenticated) {
    return <DownloadForbiddenPanel authenticated={authenticated} onClose={onClose} withPlayer={withPlayer}/>;
  }

  switch (type) {
    case TYPE_TRACK:
      return <Query
        component={DownloadTrackPanel}
        query={GetTrackVersions}
        variables={{reference: trackReference}}
        mapResult={data => ({versions: data.track.versions})}
        childProps={{trackReference, versionReference, onClose, withPlayer}}
      />;

    case TYPE_PROJECT:
      if (!canDownload) {
        return <DownloadProjectForbiddenPanel onClose={onClose} withPlayer={withPlayer}/>;
      }

      return <DownloadProjectPanel projectId={projectId} onClose={onClose} withPlayer={withPlayer} />;

    case TYPE_PLAYLIST:
      if (!canDownloadPlaylist) {
        return <DownloadForbiddenPanel authenticated={authenticated} onClose={onClose} withPlayer={withPlayer}/>;
      }

      return <DownloadPlaylistPanel playlistId={playlistId} onClose={onClose} withPlayer={withPlayer} />;
    default:
      throw new Error(`No download panel for type "${type}".`);
  }
}
