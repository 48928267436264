import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import I18n from 'i18n-js';
import Mutation from '@App/components/api/Mutation';
import DownloadProject from '@App/api/mutation/DownloadProject.graphql';
import DownloadButton, { DownloadButton as DownloadButtonInternal } from '@App/components/download/DownloadButton';

class DownloadProjectButton extends Component {
    static propTypes = {
        ...DownloadProjectButtonWrapper.propTypes,
        mutate: PropTypes.func.isRequired,
        id: PropTypes.string,
        status: PropTypes.string,
        url: PropTypes.string,
    };

    static defaultProps = {
        ...DownloadProjectButtonWrapper.defaultProps,
        id: null,
        status: 'ready',
        url: null,
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.getLabel = this.getLabel.bind(this);
    }

    onClick() {
        const { mutate, status, projectId, format } = this.props;

        if (status === 'init' || !this.isValid()) {
            return;
        }

        mutate({ id: projectId, payload: { format } });
    }

    isValid() {
        const { projectId, format } = this.props;

        return projectId !== null && format !== null;
    }

    getLabel(status, detail) {
        if (status === 'success') {
            return <Fragment>
                <i key="icon" className="icon icon--download"></i>
                {I18n.t('panel.download-project.label.success')}
            </Fragment>;
        }

        if (status === 'pending' && detail && detail.maxStep) {
            const { current, maxStep } = detail;

            return <Fragment>
                <i key="icon" className="icon icon--more"></i>
                {I18n.t('panel.download-project.label.pending_with_progress', { current, maxStep })}
            </Fragment>;
        }

        return <Fragment>
            <i key="icon" className="icon icon--more"></i>
            {I18n.t(`panel.download-project.label.${status}`)}
        </Fragment>;
    }

    getClassName(status) {
        switch(status) {
            case 'success':
                return 'btn btn--success--full';

            default:
                return 'btn btn--primary disabled';
        }
    }

    render() {
        const { status, id } = this.props;
        const disabled = !this.isValid();

        if (id) {
            return <DownloadButton id={id} className={this.getClassName} label={this.getLabel} />;
        }

        if (status === 'init') {
            return <DownloadButtonInternal status={status} className={this.getClassName} label={this.getLabel} />;
        }

        return (
            <button type="button" className={classnames('btn btn--primary', { disabled })} onClick={this.onClick} disabled={disabled}>
                <i className="icon icon--download"></i>
                {I18n.t('panel.download-project.title')}
            </button>
        );
    }
}

function mapResult(data) {
    const { id, status, url } = data.downloadProject;

    return { id, status, url };
}

function mapLoading() {
    return { status: 'init' };
}

function getUniqueId(props) {
    const { projectId, format } = props;

    return [projectId, format].join('-');
}

/**
 * GraphQL wrapped DownloadProjectPanel
 *
 * @param {Object} props
 */
export default function DownloadProjectButtonWrapper(props) {
    return <Mutation
        key={getUniqueId(props)}
        mutation={DownloadProject}
        component={DownloadProjectButton}
        loadingComponent={DownloadProjectButton}
        mapResult={mapResult}
        mapLoading={mapLoading}
        childProps={props}
    />;
}

DownloadProjectButtonWrapper.propTypes = {
    projectId: PropTypes.string.isRequired,
    format: PropTypes.string,
};

DownloadProjectButtonWrapper.defaultProps = {
    format: null,
};
