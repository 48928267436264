import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';

export default class SearchInput extends Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onClear: PropTypes.func.isRequired,
        value: PropTypes.string,
        children: PropTypes.node,
        maxLength: PropTypes.number,
    };

    static defaultProps = {
        value: null,
        children: null,
        onSubmit: null,
        maxLength: 200,
    };

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(event) {
        event.preventDefault();

        const { onSubmit } = this.props;

        if (typeof onSubmit === 'function') {
            onSubmit();
        }
    }

    render() {
        const { value, onChange, onClear, maxLength, children } = this.props;

        return (
            <form className="searchbar__form" onSubmit={this.onSubmit}>
                <label htmlFor="searchbar" className="sr-only">{I18n.t('search.label')}</label>
                {children}
                <input
                    type="text"
                    id="searchbar"
                    placeholder={I18n.t('search.label')}
                    onChange={onChange}
                    value={value || ''}
                    autoComplete="off"
                    maxLength={maxLength}
                />
                {value ? <button type="button" className="searchbar__button clear-button" onClick={onClear}>
                    <i className="icon icon--clear" aria-hidden="true"></i>
                    <span className="sr-only">{I18n.t('search.clear.label')}</span>
                </button> : null}
            </form>
        );
    }
}
