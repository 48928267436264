import PropTypes from 'prop-types';
import Query from '@App/components/api/Query';
import AccessDeniedError from '@App/error/AccessDeniedError';
import IsAuthenticated from '@App/api/query/IsAuthenticated.graphql';

function Wrapper({  id, children }) {
    if (id === null) {
        throw new AccessDeniedError('You must login to access this page');
    }

    return children;
}

Wrapper.propTypes = {
    children: PropTypes.node,
    id: PropTypes.string,
};

Wrapper.defaultProps = {
    children: null,
    id: null,
};

export default function DenyUnlessAuthenticated(props) {
    return <Query component={Wrapper} query={IsAuthenticated} mapResult={data => data.me} childProps={props} />;
}
