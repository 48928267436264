import { HUBSPOT_PAGES } from '@App/routes';
import HubspotLink from '../../navigation/HubspotLink';

export default function LegalNotices() {
    return (
        <div>
            <h1>MENTIONS LÉGALES</h1>
            <hr/>

            <h2>Responsable de la publication</h2>
            <p>
                Le site internet « MUSIQUE & MUSIC » (ci-après « le Site ») est accessible à l’adresse url https://musique-music.com.
            </p>
            <p>
                Il est édité par la société MUSIQUE & MUSIC, SARL au capital de 70.751,59 euros, immatriculée au RCS de Paris sous le numéro B 388 928 418 et dont le siège social est sis Paris 17 ème , 3 bis Villa Guizot (ci-après « la Société »). Téléphone : 01.44.09.09.84 ; Email : <HubspotLink route={HUBSPOT_PAGES.CONTACT}>nous contacter</HubspotLink>
            </p>

            <p>
                Le directeur de la publication du Site est Monsieur Jean-Michel MARQUAILLE.
            </p>

            <h2>Hébergement</h2>
            <p>
                L&apos;hébergement du Site est assuré par la société OVH, Société par Actions Simplifiées, au capital de 10.000.000 euros, immatriculée au Registre du Commerce et des Sociétés de Roubaix – Tourcoing B 424 761 419, ayant son siège social 2 rue Kellermann 59100 Roubaix, France.
            </p>

            <h2>Crédits</h2>
            <p>
                Le Site est la propriété de la société MUSIQUE & MUSIC.
            </p>
            <p>
                © 2017 MUSIQUE & MUSIC – Tous droits réservés.
            </p>

            <h2>Marque</h2>
            <p>
                La marque semi figurative MUSIQUE & MUSIC ® est protégée.
            </p>
            <p>
                Son utilisation sans autorisation écrite de la société MUSIQUE & MUSIC, sur tout support notamment à des fins commerciales, est interdite sous peine de poursuites pénales et civiles.
            </p>
            <p>
                Pour toute autre réutilisation, <HubspotLink route={HUBSPOT_PAGES.CONTACT}>nous contacter</HubspotLink>.
            </p>
        </div>
    );
}
