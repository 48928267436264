import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import SidePanel, { SidePanelSection } from '@App/components/layout/SidePanel';
import ProjectItem from '@App/components/page/project/ProjectItem';
import AddToNewProjectButton from '@App/components/model/track/AddToNewProjectButton';

export default function SelectProjectPanel({
    onClose,
    trackReference,
    versionReference,
    searchResultsPosition,
    projects,
}) {
    const [search, setSearch] = useState('');
    const filteredProjects = useMemo(() => projects.filter(
        project => project.name.toLowerCase().includes(search.toLowerCase()) && !project.archived
    ), [search, projects]);

    return <SidePanel
        id="select-brief-panel"
        direction="right"
        title={I18n.t('panel.project.add-track.title')}
        onClose={onClose}
        active={versionReference !== null}
        sticky={true}
    >
        <SidePanelSection title={I18n.t('panel.project.add-track.select')}>
            <input
                type="text"
                onChange={(e) => setSearch(e.target.value)}
                placeholder={I18n.t('panel.project.add-track.search')}
            />
            <ul className="project-list">
                <AddToNewProjectButton
                    versionReference={versionReference}
                    trackReference={trackReference}
                    searchResultsPosition={searchResultsPosition}
                />
                {filteredProjects.map((project, index) => <ProjectItem
                    key={`${index}-${versionReference}`}
                    project={project}
                    versionReference={versionReference}
                    trackReference={trackReference}
                    searchResultsPosition={searchResultsPosition}
                />)}
            </ul>
        </SidePanelSection>
    </SidePanel>;
}

SelectProjectPanel.propTypes = {
    onClose: PropTypes.func.isRequired,
    trackReference: PropTypes.string,
    versionReference: PropTypes.string,
    projects: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            archived: PropTypes.bool.isRequired,
        }).isRequired
    ).isRequired,
    searchResultsPosition: PropTypes.number,
};

SelectProjectPanel.defaultProps = {
    versionReference: null,
    trackReference: null,
    projects: [],
    searchResultsPosition: null,
};
