import { useCallback, useState } from 'react';

export default function useSteps(steps, orderedSteps, defaultStep) {
    const [step, setStep] = useState(defaultStep);

    const nextStep = useCallback(() => {
        const idx = orderedSteps.findIndex((s) => s === step);
        const nextStep = orderedSteps[idx + 1] || null;
        if (nextStep === null) {
            return;
        }

        setStep(nextStep);
    }, [orderedSteps, step]);

    const previousStep = useCallback(() => {
        const idx = orderedSteps.findIndex((s) => s === step);
        const previousStep = orderedSteps[idx - 1] || null;
        if (previousStep === null) {
            return;
        }

        setStep(previousStep);
    }, [orderedSteps, step]);

    return {
        step,
        setStep,
        nextStep,
        previousStep,
    };
}
