import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import { formatDurationWord } from '@App/utils';
import { removeDuration } from '@App/store/actions/search';

class SearchDuration extends Component {
    static propTypes = {
        minDuration: PropTypes.number,
        maxDuration: PropTypes.number,
        remove: PropTypes.func.isRequired,
    };

    static defaultProps = {
        minDuration: null,
        maxDuration: null,
    };

    getLabel(minDuration, maxDuration) {
        const min = minDuration ? formatDurationWord(minDuration) : null;
        const max = maxDuration ? formatDurationWord(maxDuration) : null;

        if (!min) {
            return I18n.t('search.duration.max', { max });
        }

        if (!max) {
            return I18n.t('search.duration.min', { min });
        }

        return I18n.t('search.duration.range', { min, max });
    }

    render() {
        const { minDuration, maxDuration, remove } = this.props;

        return (
            <div className="search-items--item search-term">
                {this.getLabel(minDuration, maxDuration)}
                <button className="search-items--remove" type="button" onClick={remove}>
                    <i className="icon icon--clear"></i>
                </button>
            </div>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        remove: () => dispatch(removeDuration()),
    })
)(SearchDuration);
