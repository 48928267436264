import { useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import SidePanel, { SidePanelSection } from '@App/components/layout/SidePanel';

export default function DownloadProjectForbiddenPanel ({
    onClose,
    withPlayer,
}) {

    useEffect(() => {
        window.hbspt.meetings.create('.meetings-iframe-container');
    }, []);

    return <SidePanel
        id="download-panel"
        direction="right"
        title={I18n.t('panel.download-project-forbidden.title')}
        onClose={onClose}
        withPlayer={withPlayer}
        active
    >
        <SidePanelSection>
            <div>
                <p dangerouslySetInnerHTML={{__html: I18n.t('panel.download-project-forbidden.subtitle')}} />

                <hr />

                <div
                    className="meetings-iframe-container"
                    data-src="https://www.musique-music.com/meetings/antoine-marquaille/rencontrer-equipe-commerciale?embed=true">
                </div>
            </div>
        </SidePanelSection>
    </SidePanel>;
}

DownloadProjectForbiddenPanel.propTypes = {
    onClose: PropTypes.func.isRequired,
    withPlayer: PropTypes.bool.isRequired,
};
