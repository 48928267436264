import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Loader from '@App/components/ui/Loader';
import Query from '@App/components/api/Query';
import Link from '@App/components/navigation/Link';
import GetLicenceRequest from '@App/api/query/GetLicenceRequest.graphql';
import WebReportLicenceRequestForm from '@App/components/model/licenceRequest/WebReportLicenceRequestForm';
import CorporateMovieLicenceRequestForm from '@App/components/model/licenceRequest/CorporateMovieLicenceRequestForm';
import { LICENCE_REQUESTS } from '@App/routes';

class LicenceRequest extends Component {
    static propTypes = {
        licenceRequest: PropTypes.shape(),
    };

    static defaultProps = {
        licenceRequest: null,
    };

    getForm() {
        const { licenceRequest } = this.props;

        if (!licenceRequest) {
            return <Loader />;
        }

        switch (licenceRequest.type) {
            case 'WebReportLicenceRequest':
                return <WebReportLicenceRequestForm readOnly licenceRequest={licenceRequest} />;

            case 'CorporateMovieLicenceRequest':
                return <CorporateMovieLicenceRequestForm readOnly licenceRequest={licenceRequest} />;

            default:
                throw new Error(`Unsupported type "${licenceRequest.type}".`);
        }
    }

    render() {
        return (
            <div className="content form-page">
                <div className="container-fluid">
                    <ul className="breadcrumb breadcrumb--dark">
                        <li className="breadcrumb__item">
                            <Link route={LICENCE_REQUESTS.name} className="link">
                                <i className="icon icon--chevron-left"></i>
                                {I18n.t('page.licence_request_form.back')}
                            </Link>
                        </li>
                    </ul>
                    <h1>{I18n.t('page.licence_request.title')}</h1>
                    <hr/>
                    {this.getForm()}
                </div>
            </div>
        );
    }
}

function mapResult(data) {
    return { licenceRequest: data.licenceRequest };
}

function mapLoading() {
    return { licenceRequest: null };
}

export default function LicenceRequestWrapper(props) {
    const { id } = props;

    return (
        <Query
            component={LicenceRequest}
            loadingComponent={LicenceRequest}
            query={GetLicenceRequest}
            variables={{ id }}
            mapResult={mapResult}
            mapLoading={mapLoading}
        />
    );
}

LicenceRequestWrapper.propTypes = {
    id: PropTypes.string.isRequired,
};
