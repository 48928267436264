import { useState } from 'react';
import I18n from 'i18n-js';
import SidePanel, { SidePanelSection } from '@App/components/layout/SidePanel';
import FormatSelector from '@App/components/form/FormatSelector';
import DownloadPlaylistButton from '@App/components/download/DownloadPlaylistButton';

interface Props {
  onClose: () => void
  withPlayer: string
  playlistId: string
}

export default function DownloadPlaylistPanel ({
  onClose,
  withPlayer,
  playlistId,
}: Props) {

  const [format, setFormat] = useState(FormatSelector.FORMATS[0]);

  function renderFooter() {
    return <DownloadPlaylistButton
      playlistId={playlistId}
      format={format}
    />;
  }

  return <SidePanel
    id="download-panel"
    direction="right"
    title={I18n.t('panel.download-playlist.title')}
    onClose={onClose}
    withPlayer={withPlayer}
    footer={renderFooter()}
    active
  >
    <SidePanelSection title={I18n.t('panel.download-playlist.format')}>
      <FormatSelector value={format} onChange={setFormat} />
    </SidePanelSection>
  </SidePanel>;
}
