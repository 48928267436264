import { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class Button extends Component {
    static propTypes = {
        type: PropTypes.string,
        label: PropTypes.node,
        successLabel: PropTypes.string,
        onClick: PropTypes.func,
        className: PropTypes.string,
        tooltip: PropTypes.bool,
        icon: PropTypes.string,
        successIcon: PropTypes.string,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        children: PropTypes.node,
    };

    static defaultProps = {
        type: 'button',
        className: 'btn',
        tooltip: false,
        label: null,
        onClick: undefined,
        successLabel: null,
        icon: null,
        successIcon: 'check',
        loading: false,
        disabled: false,
        children: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            success: false,
        };

        this.successTimeout = null;

        this.success = this.success.bind(this);
        this.onSuccessEnd = this.onSuccessEnd.bind(this);
    }

    componentWillUnmount() {
        if (this.successTimeout) {
            // Clear the registered callbacks on unmount,
            // so we don't attempt a state change on unmounted component:
            clearTimeout(this.successTimeout);
            this.successTimeout = null;
        }
    }

    success(duration = 3000) {
        this.setState({ success: true });
        this.successTimeout = setTimeout(this.onSuccessEnd, duration);
    }

    onSuccessEnd() {
        this.setState({ success: false });
        clearTimeout(this.successTimeout);
        this.successTimeout = null;
    }

    renderIcon(icon) {
        const { loading } = this.props;

        if (loading || !icon) {
            return null;
        }

        return <i className={`icon icon--${icon}`} />;
    }

    renderLabel(label) {
        const { tooltip, loading } = this.props;

        if (loading) {
            return null;
        }

        if (tooltip) {
            return <span className="sr-only">{label}</span>;
        }

        return label;
    }

    render() {
        const { type, onClick, className, tooltip, loading, disabled, children, label, icon, successLabel, successIcon, ...props } = this.props;
        const { success } = this.state;
        const currentLabel = success ? successLabel : label;

        return (
            <button
                {...props}
                type={type}
                onClick={onClick}
                className={classnames(className, {
                    tooltip,
                    loading,
                    disabled,
                    'btn--success': success,
                    'tooltip-success': success,
                })}
                data-tooltip={tooltip ? currentLabel : undefined}
            >
                {this.renderIcon(success ? successIcon : icon)}
                {this.renderLabel(currentLabel)}
                {children}
            </button>
        );
    }
}
