import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clear } from '@App/store/actions/similarity';

class SimilaritySearchFile extends Component {
    static propTypes = {
        file: PropTypes.bool.isRequired,
        remove: PropTypes.func.isRequired,
    };

    render() {
        const { file, remove } = this.props;

        return (
            <div className="search-items--item similarity-search-file">
                {file.name}
                <button className="search-items--remove" type="button" onClick={remove}>
                    <i className="icon icon--clear"></i>
                </button>
            </div>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        remove: () => dispatch(clear()),
    })
)(SimilaritySearchFile);
