import { Component } from 'react';
import PropTypes from 'prop-types';

export default class BlockTitle extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        children: PropTypes.node,
    };

    static defaultProps = {
        children: null,
    };

    render() {
        const { title, children, ...props } = this.props;

        return (
            <div {...props} className="title-block">
                <h1 className="h1">{title}</h1>
                {children}
            </div>
        );
    }
}
