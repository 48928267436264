import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import ShareButton from '@App/components/model/share/ShareButton';
import { copyProjectUrl } from '@App/utils/share';

export default class ShareProjectButton extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.onShare = this.onShare.bind(this);
    }

    onShare() {
        const { projectId } = this.props;

        copyProjectUrl(projectId);
    }

    render() {
        const { ...props } = this.props;

        delete props.projectId;

        return <ShareButton label={I18n.t('model.project.actions.share')} onShare={this.onShare} {...props} />;
    }
}
