import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from '@App/container';
import classnames from 'classnames';
import PlayerControl from '@App/components/container/PlayerControlContainer';
import PlayerStatus from '@App/components/player/PlayerStatus';
import PlayerProgress from '@App/components/player/PlayerProgress';
import TrackButtonBar from '@App/components/model/TrackButtonBar';
import TrackVersionsButton from '@App/components/model/track/TrackVersionsButton';
import ForbiddenPlayerMessage from '@App/components/player/ForbiddenPlayerMessage';
import Button from '@App/components/ui/Button';
import Loader from '@App/components/ui/Loader';
import Highlight from '@App/model/similarSearch/Highlight';

export default class Player extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        mobile: PropTypes.bool.isRequired,
        reduced: PropTypes.bool.isRequired,
        authenticated: PropTypes.bool.isRequired,
        trackReference: PropTypes.string.isRequired,
        versionReference: PropTypes.string,
        albumReference: PropTypes.string,
        startPosition: PropTypes.number,
        highlights: PropTypes.arrayOf(Highlight),
        title: PropTypes.string,
        waveform: PropTypes.array,
        duration: PropTypes.number,
        audioUrl: PropTypes.string,
        cover: PropTypes.string,
        nbVersions: PropTypes.number,
        availableForSimilarSearch: PropTypes.bool,
        playTrack: PropTypes.func.isRequired,
        previousTrack: PropTypes.shape({
            trackReference: PropTypes.string.isRequired,
            versionReference: PropTypes.string.isRequired,
            highlights: PropTypes.arrayOf(Highlight)
        }),
        nextTrack: PropTypes.shape({
            trackReference: PropTypes.string.isRequired,
            versionReference: PropTypes.string.isRequired,
            highlights: PropTypes.arrayOf(Highlight)
        }),
        onPlay: PropTypes.func,
        onPause: PropTypes.func,
        adminEditUrl: PropTypes.string,
        modeLoop: PropTypes.bool.isRequired,
        hasStreamingFeature: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        loading: false,
        versionReference: null,
        albumReference: null,
        startPosition: null,
        title: null,
        waveform: [],
        highlights: [],
        duration: 0,
        audioUrl: null,
        cover: null,
        nbVersions: 0,
        availableForSimilarSearch: false,
        previousTrack: null,
        nextTrack: null,
        onPlay: null,
        onPause: null,
        modeLoop: false,
        hasStreamingFeature: false,
    };

    constructor(props) {
        super(props);

        this.audio = get('audio');

        this.state = {
            loaded: false,
            playing: false,
            forbidden: false,
            time: 0,
        };

        this.onLoad = this.onLoad.bind(this);
        this.onUnload = this.onUnload.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
        this.onEnd = this.onEnd.bind(this);
        this.onTime = this.onTime.bind(this);
        this.onSeek = this.onSeek.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onCloseAlert = this.onCloseAlert.bind(this);
    }

    componentDidMount() {
        this.audio.addEventListener('canplay', this.onLoad);
        this.audio.addEventListener('emptied', this.onUnload);
        this.audio.addEventListener('play', this.onPlay);
        this.audio.addEventListener('pause', this.onPause);
        this.audio.addEventListener('ended', this.onEnd);
        this.audio.addEventListener('timeupdate', this.onTime);
    }

    componentWillUnmount() {
        this.audio.removeEventListener('canplay', this.onLoad);
        this.audio.removeEventListener('emptied', this.onUnload);
        this.audio.removeEventListener('play', this.onPlay);
        this.audio.removeEventListener('pause', this.onPause);
        this.audio.removeEventListener('ended', this.onEnd);
        this.audio.removeEventListener('timeupdate', this.onTime);
    }

    componentDidUpdate(prevProps, prevState) {
        const { audioUrl, onPlay, onPause, startPosition } = this.props;
        const { loaded, playing } = this.state;

        // Audio file has changed
        if (audioUrl && audioUrl !== prevProps.audioUrl) {
            this.audio.load(audioUrl);
        }

        if (startPosition !== null && startPosition !== prevProps.startPosition) {
            this.audio.seek(startPosition);
        }

        // Audio start playing (loaded and playing)
        if (onPlay && (loaded !== prevState.loaded || playing !== prevState.playing) && loaded && playing) {
            onPlay();

            if (startPosition !== null) {
                this.audio.seek(startPosition);
            }
        }

        // Audio paused
        if (onPause && !playing && playing !== prevState.playing) {
            onPause();
        }
    }

    onLoad() {
        this.setState({ loaded: true, forbidden: false });
    }

    onUnload() {
        this.setState({ loaded: false });
    }

    onPlay() {
        this.setState({ playing: this.audio.isPlaying(), forbidden: false });
    }

    onPause() {
        this.setState({ playing: this.audio.isPlaying() });
    }

    onEnd() {
        const {
            authenticated,
            duration,
            playTrack,
            nextTrack,
            hasStreamingFeature,
        } = this.props;
        const state = { playing: this.audio.isPlaying() };

        if (hasStreamingFeature && null !== nextTrack) {
            playTrack(nextTrack.trackReference, nextTrack.versionReference, 0, nextTrack.highlights);
        }

        if (!authenticated && this.audio.getDuration() < duration) {
            state.forbidden = true;
        }

        this.setState(state);
    }

    onCloseAlert() {
        this.setState({ forbidden: false });
    }

    onTime() {
        this.setState({ time: this.audio.getTime() });
    }

    onSeek(position) {
        const { duration } = this.props;

        this.audio.seek(duration * position);
    }

    onPrevious() {
        const { playTrack, previousTrack, } = this.props;

        this.audio.pause();

        playTrack(previousTrack.trackReference, previousTrack.versionReference, 0, previousTrack.highlights);
    }

    onNext() {
        const { playTrack, nextTrack } = this.props;

        this.audio.pause();

        playTrack(nextTrack.trackReference, nextTrack.versionReference, 0, nextTrack.highlights);
    }

    renderPlayBar() {
        const { mobile,
            reduced,
            loading,
            waveform,
            audioUrl,
            highlights,
            duration,
            versionReference,
            trackReference,
            previousTrack,
            nextTrack,
            modeLoop,
            hasStreamingFeature
        } = this.props;
        const { time, loaded, playing } = this.state;

        if (mobile) {
            return <PlayerProgress duration={duration} time={time} />;
        }

        const onPrevious = previousTrack ? this.onPrevious : null;
        const onNext = nextTrack ? this.onNext : null;

        if (reduced) {
            return <Fragment>
                <PlayerControl
                    key="control"
                    loading={!loaded}
                    playing={playing}
                    onPlay={this.audio.togglePlay}
                    onPrevious={onPrevious}
                    onNext={onNext}
                    modeLoop={modeLoop}
                    hasStreamingFeature={hasStreamingFeature}
                />
                <PlayerProgress key="progress" duration={duration} time={time} />
            </Fragment>;
        }

        const id = versionReference || trackReference;

        return <Fragment>
            <PlayerControl
                key="control"
                loading={!loaded}
                playing={playing}
                onPlay={this.audio.togglePlay}
                onPrevious={onPrevious}
                onNext={onNext}
                modeLoop={modeLoop}
                hasStreamingFeature={hasStreamingFeature}
            />
            <PlayerStatus
                key="status"
                id={id}
                audioFileUrl={audioUrl}
                loading={loading}
                waveform={waveform}
                highlights={highlights}
                duration={duration}
                time={time}
                onSeek={this.onSeek}
            />
        </Fragment>;
    }

    renderButtonBar() {
        const { mobile, loading, trackReference, versionReference, albumReference, availableForSimilarSearch, adminEditUrl } = this.props;

        if (loading) {
            return <Loader />;
        }

        return <TrackButtonBar
            mobile={mobile}
            versionReference={versionReference}
            trackReference={trackReference}
            albumReference={albumReference}
            availableForSimilarSearch={availableForSimilarSearch}
            up
            adminEditUrl={adminEditUrl}
        />;
    }

    static MobileCover(props) {
        const { cover, playing, loading, onPlay } = props;
        const className = classnames('cover', {
            'cover--loading': loading,
            'cover--pause': !loading && playing,
            'cover--play': !loading && !playing,
        });

        return <Button
            label={null}
            disabled={loading}
            className={className}
            onClick={onPlay}
            style={cover ? { backgroundImage: `url('${cover}')` } : null}
        />;
    }

    static Cover(props) {
        const { cover } = props;

        return <div className="cover" style={cover ? { backgroundImage: `url('${cover}')` } : null} />;
    }

    renderTrack() {
        const { Cover, MobileCover } = this.constructor;
        const { versionReference, trackReference, cover, title, nbVersions, mobile } = this.props;
        const { loaded, playing } = this.state;

        return (
            <div className="player__details">
                {mobile ? <MobileCover cover={cover} playing={playing} loading={!loaded} onPlay={this.audio.togglePlay} /> : <Cover cover={cover} />}
                <div className="info">
                    <strong key="track-title" className="title">
                        {title ? title : <Loader />}
                    </strong>
                    <span key="track-versions">
                        {versionReference} - <TrackVersionsButton trackReference={trackReference} count={nbVersions} />
                    </span>
                </div>
            </div>
        );

    }

    renderForbiddenMessage() {
        const { forbidden } = this.state;

        if (!forbidden) {
            return null;
        }

        return <ForbiddenPlayerMessage onClose={this.onCloseAlert} />;
    }

    render() {
        const { mobile } = this.props;

        return (
            <div className={`player ${mobile ? 'player--mobile' : ''}`}>
                {this.renderTrack()}
                {this.renderPlayBar()}
                <div className="player__actions">
                    {this.renderButtonBar()}
                </div>
                {this.renderForbiddenMessage()}
            </div>
        );
    }
}
