export const MOBILE = 'mobile';
export const DESKTOP = 'desktop';

/**
 * @see https://www.algolia.com/doc/guides/getting-insights-and-analytics/search-analytics/segmenting-your-analytics-data/how-to/tag-your-users-by-platform/#identifying-your-users-device
 */
const AnalyticsPlatformTag = {
    MOBILE,
    DESKTOP,

    from(isMobile) {
        return isMobile ? MOBILE : DESKTOP;
    }
};

export default AnalyticsPlatformTag;
