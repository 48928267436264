import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Button from '@App/components/ui/Button';

export default class ShareButton extends Component {
    static propTypes = {
        onShare: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
        successLabel: PropTypes.string,
    };

    static defaultProps = {
        successLabel: null,
    };

    constructor(props) {
        super(props);

        this.button = createRef();

        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        // prevent closing the dropdown if wrapped into
        e.stopPropagation();

        this.props.onShare();
        this.button.current.success();
    }

    render() {
        const { label, successLabel, ...props } = this.props;

        delete props.onShare;

        return <Button
            ref={this.button}
            label={label}
            successLabel={successLabel || I18n.t('common.share.success')}
            {...props}
            onClick={this.onClick}
        />;
    }
}
