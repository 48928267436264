import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import I18n from 'i18n-js';
import Label from '@App/components/form/Label';
import Errors from '@App/components/form/Errors';

export default class DurationInput extends Component {
    static propTypes = {
        value: PropTypes.number,
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        required: PropTypes.bool,
        readOnly: PropTypes.bool,
        errors: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        onChange: PropTypes.func,
    };

    static defaultProps = {
        value: 0,
        required: false,
        readOnly: false,
        label: null,
        errors: [],
        onChange: () => {},
    };

    constructor(props) {
        super(props);

        this.minutesElement = null;
        this.secondsElement = null;

        this.setMinutesElement = this.setMinutesElement.bind(this);
        this.setSecondsElement = this.setSecondsElement.bind(this);
        this.hasErrors = this.hasErrors.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    setMinutesElement(element) {
        this.minutesElement = element;
    }

    setSecondsElement(element) {
        this.secondsElement = element;
    }

    get minutes() {
        return this.parseValue(this.minutesElement.value, 10);
    }

    get seconds() {
        return this.parseValue(this.secondsElement.value, 10);
    }

    parseValue(input) {
        let value;

        try {
            value = parseInt(input, 10);
        } catch (error) {
            value = null;
        }

        if (typeof value === 'number' && !Number.isNaN(value)) {
            return value;
        }

        return 0;
    }

    /**
     * @returns {Boolean}
     */
    hasErrors() {
        return [].concat(this.props.errors).length > 0;
    }

    onChange(event) {
        const { onChange } = this.props;
        const value = this.seconds + this.minutes * 60;

        onChange(value, event);
    }

    render() {
        const { id, value, label, required, errors, readOnly, ...otherProps } = this.props;
        const className = classNames('form__input', { 'form__input--error': this.hasErrors() });
        const minutes = Math.floor(value / 60);
        const seconds = value % 60;

        delete otherProps.onChange;

        return (
            <div className="form duration-input">
                {label ? <Label required={required}>{label}</Label> : null}
                <div className="form form--inline">
                    <input
                        id={id + '_minutes'}
                        ref={this.setMinutesElement}
                        type="number"
                        min="0"
                        onChange={this.onChange}
                        className={className}
                        required={required}
                        readOnly={readOnly}
                        value={minutes.toString()}
                    />
                    <Label forId={id + '_minutes'} className="form__unit">{I18n.t('form.duration.minutes')}</Label>
                </div>
                <div className="form form--inline">
                    <input
                        id={id + '_seconds'}
                        ref={this.setSecondsElement}
                        type="number"
                        min="0"
                        max="59"
                        onChange={this.onChange}
                        className={className}
                        required={required}
                        readOnly={readOnly}
                        value={seconds.toString()}
                    />
                    <Label forId={id + '_seconds'} className="form__unit">{I18n.t('form.duration.seconds')}</Label>
                </div>
                <Errors errors={errors} />
            </div>
        );
    }
}
