import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { formatDuration } from '@App/utils';
import { copyTrackInfos } from '@App/utils/trackInfos';
import ShareButton from '@App/components/model/share/ShareButton';
import SidePanel, { SidePanelSection } from '@App/components/layout/SidePanel';

export default function InformationsPanel({
    onClose,
    withPlayer,
    track,
}) {
    function renderCredit(credit) {
        const { id, name } = credit;

        return <li key={id}>{name.toLowerCase()}</li>;
    }
    function renderFooter(track) {
        return <ShareButton
            label={I18n.t('panel.informations.copy-infos')}
            successLabel={I18n.t('panel.informations.copy-infos:success')}
            onShare={() => copyTrackInfos(track)}
        />;
    }

    const { title, version, album, writers, publishers } = track;
    const { reference, duration, isrc, iswc } = version;

    return <SidePanel
        id="informations-panel"
        direction="left"
        title={I18n.t('panel.informations.title')}
        onClose={onClose}
        withPlayer={withPlayer}
        footer={renderFooter(track)}
        active
    >
        <SidePanelSection>
            <dl>
                <dt>{I18n.t('panel.informations.track.title')}</dt>
                <dd>{title}</dd>
            </dl>
            <dl>
                <dt>{I18n.t('model.track.writers', { count: writers.length })}</dt>
                <dd>
                    <ul className="credit">{writers.map(renderCredit)}</ul>
                </dd>
            </dl>
            <dl>
                <dt>{I18n.t('panel.informations.track.duration')}</dt>
                <dd>{formatDuration(duration)}</dd>
            </dl>
            <dl>
                <dt>{I18n.t('model.track.publishers', { count: publishers.length })}</dt>
                <dd>
                    <ul className="credit">{publishers.map(renderCredit)}</ul>
                </dd>
            </dl>
            <dl>
                <dt>{I18n.t('panel.informations.track.album')}</dt>
                <dd>{album.title}</dd>
            </dl>
            <dl>
                <dt>{I18n.t('panel.informations.track.reference')}</dt>
                <dd>{reference}</dd>
            </dl>
            <dl>
                <dt>{I18n.t('panel.informations.track.label')}</dt>
                <dd>{album.label.name}</dd>
            </dl>
            <dl>
                <dt>{I18n.t('panel.informations.track.isrc')}</dt>
                <dd>{isrc}</dd>
            </dl>
            <dl>
                <dt>{I18n.t('panel.informations.track.iswc')}</dt>
                <dd>{iswc}</dd>
            </dl>
        </SidePanelSection>
    </SidePanel>;
}

InformationsPanel.propTypes = {
    onClose: PropTypes.func.isRequired,
    withPlayer: PropTypes.bool.isRequired,
    track: PropTypes.shape({
        title: PropTypes.string.isRequired,
        reference: PropTypes.string.isRequired,
        album: PropTypes.shape({
            title: PropTypes.string.isRequired,
            label: PropTypes.shape({
                reference: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        version: PropTypes.shape({
            reference: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            duration: PropTypes.number.isRequired,
            iswc: PropTypes.string,
            isrc: PropTypes.string,
        }).isRequired,
        writers: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            }).isRequired
        ).isRequired,
        publishers: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            }).isRequired
        ).isRequired,
    }).isRequired,
};
