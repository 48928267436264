import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Link from '@App/components/navigation/Link';
import MyProjects from '@App/api/query/MyProjects.graphql';
import Query from '@App/components/api/Query';
import { PROJECTS } from '@App/routes';

function MyProjectsButton(props) {
    const { count } = props;

    return (
        <Link className="link" route={PROJECTS.name}>
            {I18n.t('menu.authenticated.projects', { count })}
        </Link>
    );
}

MyProjectsButton.propTypes = {
    count: PropTypes.number,
};

MyProjectsButton.defaultProps = {
    count: 0,
};

function mapResult(data) {
    const count = data.myProjects.filter(project => !project.archived).length;

    return { count };
}

export default function MyProjectsButtonWrapper() {
    return <Query component={MyProjectsButton} loadingComponent={MyProjectsButton} query={MyProjects} mapResult={mapResult} />;
}
