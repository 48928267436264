/*
 * This code was generated using the `app:js:generate:insights-event` command.
 */

export const TRACK_PLAY = 'TRACK_PLAY';
export const TRACK_PLAYED = 'TRACK_PLAYED';
export const TRACK_DOWNLOADED = 'TRACK_DOWNLOADED';
export const TRACK_VERSIONS_SHOWN = 'TRACK_VERSIONS_SHOWN';
export const TRACK_ADDED_TO_PROJECT = 'TRACK_ADDED_TO_PROJECT';

const InsightsEvent = {
    TRACK_PLAY,
    TRACK_PLAYED,
    TRACK_DOWNLOADED,
    TRACK_VERSIONS_SHOWN,
    TRACK_ADDED_TO_PROJECT,
};

export default InsightsEvent;
