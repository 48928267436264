import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AbstractTagButton from '@App/components/search/tags/AbstractTagButton';
import { isTagIncluded, isTagExcluded } from '@App/store/reducers/search';
import { addToExcludedTags, removeFromExcludedTags } from '@App/store/actions/search';

const ExcludeTagButtonWrapper = connect(
    (state, props) => {
        const { id, includeClassname, excludeClassname } = props;
        const active = isTagExcluded(state, id);
        let className = props.className || '';

        if (excludeClassname && active) {
            className += ` ${excludeClassname}`;
        }

        if (includeClassname && isTagIncluded(state, id)) {
            className += ` ${includeClassname}`;
        }

        return { active, className };
    },
    (dispatch, props) => ({
        add: () => dispatch(addToExcludedTags(props.id)),
        remove: () => dispatch(removeFromExcludedTags(props.id)),
    })
)(AbstractTagButton);

ExcludeTagButtonWrapper.propTypes = {
    id: PropTypes.string.isRequired,
    includeClassname: PropTypes.string,
    excludeClassname: PropTypes.string,
};

ExcludeTagButtonWrapper.defaultProps = {
    includeClassname: null,
    excludeClassname: null,
};

export default ExcludeTagButtonWrapper;
