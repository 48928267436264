import I18n from 'i18n-js';
import { LOGIN } from '@App/routes';

export default function LoginButton() {
    return (
        <a href={LOGIN.getUrl()} className="link link--primary">
            {I18n.t('menu.anonymous.login')}
        </a>
    );
}
