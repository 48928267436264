import PropTypes from 'prop-types';

function Errors(props) {
    const errors = [].concat(props.errors);

    return (
        errors.length > 0 && <div className="form__error">{errors.map((error, i, errors) => {
            return (
                <span key={i}>
                    {error} {i !== errors.length && <br/>}
                </span>
            );
        })}</div>
    );
}

Errors.propTypes = {
    errors: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
};

Errors.defaultProps = {
    errors: [],
};

export default Errors;
