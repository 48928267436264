import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import { downloadProject } from '@App/store/actions/download';
import Button from '@App/components/ui/Button';

class DownloadProjectButton extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const { onClick, ...props } = this.props;
        const label = I18n.t('model.project.actions.download');

        delete props.projectId;

        return <Button label={label} {...props} onClick={onClick} />;
    }
}

const DownloadProjectButtonWrapper = connect(
    null,
    (dispatch, props) => ({
        onClick: () => dispatch(downloadProject(props.projectId))
    })
)(DownloadProjectButton);

DownloadProjectButtonWrapper.propTypes = {
    projectId: PropTypes.string.isRequired,
};

export default DownloadProjectButtonWrapper;
