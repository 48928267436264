import PropTypes from 'prop-types';
import OrderStatus from '@App/model/order/OrderStatus';

export default {
    /**
     * Order details on show page.
     */
    Details: {
        /**
         * An item (a purchased track) with details for showing on the order details page.
         */
        OrderItem: PropTypes.shape({
            reference: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            cover: PropTypes.string.isRequired,
            licenseFile: PropTypes.string.isRequired,
        })
    },
    Statuses: PropTypes.oneOf([Object.values(OrderStatus)]),
};
