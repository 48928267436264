import copy from 'copy-to-clipboard';
import I18n from 'i18n-js';
import { formatDuration } from '@App/utils';

export function copyTrackInfos(track) {
    const { title, version, album, writers, publishers } = track;
    const { reference, duration, isrc, iswc } = version;
    const infos = [
        [I18n.t('panel.informations.track.title'), title],
        [I18n.t('model.track.writers', { count: writers.length }), writers.map(({ name }) => name).join(', ')],
        [I18n.t('panel.informations.track.duration'), formatDuration(duration)],
        [I18n.t('model.track.publishers', { count: publishers.length }), publishers.map(({ name }) => name).join(', ')],
        [I18n.t('panel.informations.track.album'), album.title],
        [I18n.t('panel.informations.track.reference'), reference],
        [I18n.t('panel.informations.track.label'), album.label.name],
        [I18n.t('panel.informations.track.isrc'), isrc],
        [I18n.t('panel.informations.track.iswc'), iswc],
    ];

    copy(infos.map(item => item.join(': ')).join('\n'));
}
