import classNames from 'classnames';
import PropTypes from 'prop-types';

Backdrop.propTypes = {
    active: PropTypes.bool,
    full: PropTypes.bool,
    onClick: PropTypes.func,
};

export default function Backdrop({
    active = false,
    full = false,
    onClick = undefined,
}) {
    return <div className={classNames('backdrop', {
        'backdrop--show': active,
        'backdrop--full': full,
    })} onClick={onClick} />;
}
