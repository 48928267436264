/**
 * Handle form change
 *
 * @param {String} formStateProperty
 * @param {String} fieldName
 * @param {mixed} value
 */
import { scrollToElement } from '@App/utils/scroll';

export function handleFieldChange(formStateProperty, fieldName, value, callback = null) {
    const form = this.state[formStateProperty];

    this.setState({
        [formStateProperty]: {
            ...form,
            ...{ [fieldName]: value }
        }
    }, callback);
}

/**
 * @param {String} field field name to link
 */
export function linkState(fieldName, callback = null) {
    return {
        value: this.state.form[fieldName],
        onChange: this.handleFieldChange(fieldName, callback),
        errors: this.getErrors(fieldName),
    };
}


/**
 * @param {String} path
 */
export function getErrors(path) {
    return this.props.errors[path] || [];
}

export function scrollToFirstError() {
    const firstRenderedError = document.querySelector('.form__error');
    // Scroll to first rendered error, if any:
    if (!firstRenderedError) {
        return;
    }

    const formContainer = firstRenderedError.closest('.form') || firstRenderedError.parentNode;

    scrollToElement(formContainer);
}

const FormUtils = {
    handleFieldChange,
    linkState,
    getErrors,
    scrollToFirstError,
};

export default FormUtils;
