import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Errors from '@App/components/form/Errors';
import SearchTrackVersionInput from '@App/components/form/SearchTrackVersionInput';
import TrackListRow from '@App/components/form/TrackListInput/TrackListRow';
import Table from '@App/components/layout/Table';

export default class TrackListInput extends Component {
    static propTypes = {
        value: PropTypes.arrayOf(
            PropTypes.shape({
                reference: PropTypes.string.isRequired,
                duration: PropTypes.number.isRequired,
            }).isRequired,
        ),
        errors: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        onChange: PropTypes.func,
        readOnly: PropTypes.bool,
    };

    static defaultProps = {
        value: [],
        errors: [],
        onChange: () => {},
        readOnly: false,
    };

    constructor(props) {
        super(props);

        this.renderHead = this.renderHead.bind(this);
        this.renderRow = this.renderRow.bind(this);
        this.renderEmpty = this.renderEmpty.bind(this);
        this.onTrack = this.onTrack.bind(this);
        this.hasErrors = this.hasErrors.bind(this);
    }

    /**
     * @returns {Boolean}
     */
    hasErrors() {
        return [].concat(this.props.errors).length > 0;
    }

    renderHead() {
        return (
            <tr>
                <th>{I18n.t('form.track_list.table.reference')}</th>
                <th>{I18n.t('form.track_list.table.title')}</th>
                <th>{I18n.t('form.track_list.table.writers')}</th>
                <th>{I18n.t('form.track_list.table.publishers')}</th>
                <th>{I18n.t('form.track_list.table.duration')}</th>
                <th colSpan="2">{I18n.t('form.track_list.table.used_duration')}</th>
            </tr>
        );
    }

    renderRow(track) {
        const { readOnly } = this.props;
        const { reference, duration } = track;

        return <TrackListRow
            key={reference}
            reference={reference}
            usedDuration={duration}
            onRemove={this.onRemove.bind(this, reference)}
            onDuration={this.onDuration.bind(this, reference)}
            readOnly={readOnly}
        />;
    }

    renderEmpty() {
        return <tr><td className="empty" colSpan="6">{I18n.t('form.track_list.table.empty')}</td></tr>;
    }

    onTrack(reference) {
        const { value, onChange } = this.props;

        if (!value.some(track => track.reference === reference)) {
            onChange(value.concat([{ reference, duration: 0 }]));
        }
    }

    onDuration(reference, duration) {
        const { value, onChange } = this.props;

        if (value.some(track => track.reference === reference)) {
            onChange(
                value.map(track => {
                    if (track.reference !== reference) {
                        return track;
                    }

                    return { reference, duration };
                })
            );
        }
    }

    onRemove(reference) {
        const { value, onChange } = this.props;

        if (value.some(track => track.reference === reference)) {
            onChange(value.filter(track => track.reference !== reference));
        }
    }

    render() {
        const { value, errors, readOnly, ...otherProps } = this.props;

        delete otherProps.onChange;

        return (
            <div className="track-list">
                <Table
                    values={value}
                    renderHead={this.renderHead}
                    renderRow={this.renderRow}
                    renderEmpty={this.renderEmpty}
                />
                {readOnly ? null : <SearchTrackVersionInput
                    placeholder={I18n.t('form.track_list.add')}
                    onTrack={this.onTrack}
                    exclude={value.map(track => track.reference)}
                />}
                <Errors errors={errors} />
            </div>
        );
    }
}
