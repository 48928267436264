import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import ShareButton from '@App/components/model/share/ShareButton';
import { copyTrackUrl } from '@App/utils/share';

export default class ShareTrackButton extends Component {
    static propTypes = {
        albumReference: PropTypes.string.isRequired,
        trackReference: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.onShare = this.onShare.bind(this);
    }

    onShare() {
        const { albumReference, trackReference } = this.props;

        copyTrackUrl(albumReference, trackReference);
    }

    render() {
        const { ...props } = this.props;

        delete props.albumReference;
        delete props.trackReference;

        return <ShareButton label={I18n.t('model.track.actions.share')} onShare={this.onShare} {...props} />;
    }
}
