import { Component } from 'react';
import PropTypes from 'prop-types';
import SingleChoice from '@App/components/form/SingleChoice';

export default class FormatSelector extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string,
    };

    static defaultProps = {
        value: null,
    };

    static FORMATS = ['mp3', 'aiff', 'wav'];

    render() {
        const { value, onChange } = this.props;
        const { FORMATS } = this.constructor;

        return <SingleChoice
            name="format"
            value={value}
            options={FORMATS}
            onChange={onChange}
            inline
        />;
    }
}
