import { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CardHeader from '@App/components/layout/CardHeader';

export default class Card extends Component {
    static propTypes = {
        ...CardHeader.propTypes,
        children: PropTypes.node,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        children: null,
        loading: false,
    };

    render() {
        const { loading, children } = this.props;

        return (
            <div className="card">
                <div className={classnames('card__content', { loading })}>
                    {children}
                </div>
            </div>
        );
    }
}
