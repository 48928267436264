import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import ListOrders from '@App/api/query/order/ListOrders.graphql';
import ShowOrder from '@App/api/query/order/ShowOrder.graphql';

/**
 * Fetches orders of the authenticated user.
 */
export function useOrders() {

    const { error, loading, data = []} = useQuery(ListOrders);

    const orders = useMemo(() => {
        if (error || loading) {
            return [];
        }

        return data.orders;
    }, [error, loading, data]);


    return {
        error,
        loading,
        /** The raw response data */
        data,
        orders,
    };
}


/**
 * Fetches a specific order
 */
export function useOrder(id) {
    const { error, loading, data = []} = useQuery(ShowOrder, {
        variables: { id },
        fetchPolicy: 'network-only',
    });

    const order = useMemo(() => {
        if (error || loading) {
            return null;
        }

        return data.order;
    }, [error, loading, data]);


    return {
        error,
        loading,
        /** The raw response data */
        data,
        order,
    };
}
