import { Component } from 'react';
import PropTypes from 'prop-types';

export default class PlayerProgress extends Component {
    static propTypes = {
        duration: PropTypes.number.isRequired,
        time: PropTypes.number.isRequired,
    };

    render() {
        const { duration, time } = this.props;
        const percent = time / duration * 100;

        return (
            <div className="player__progress">
                <div className="progress" style={{ width: `${percent.toFixed(3)}%`}}></div>
            </div>
        );
    }
}

