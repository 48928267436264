import { Component } from 'react';
import PropTypes from 'prop-types';

export default class MultipleChoice extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        options: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.array,
        getValue: PropTypes.func,
        renderLabel: PropTypes.func,
        renderExtra: PropTypes.func,
        labelProps: PropTypes.object,
        readOnly: PropTypes.bool,
    };

    static defaultProps = {
        value: [],
        getValue: option => option,
        renderLabel: option => option,
        renderExtra: null,
        labelProps: {},
        readOnly: false,
    };

    constructor(props) {
        super(props);

        this.renderOption = this.renderOption.bind(this);
    }

    onChange(value, event) {
        const { checked } = event.currentTarget;
        const { onChange, value: currentValue } = this.props;

        return onChange(checked ? currentValue.concat([value]) : currentValue.filter(item => item !== value), event);
    }

    renderOption(option) {
        const { name, value: currentValue, renderLabel, renderExtra, getValue, labelProps, readOnly } = this.props;
        const value = getValue(option);
        const checked = currentValue.includes(value);
        const id = `${name}-${value}`;

        return (
            <div key={id} className="checkbox">
                <input type="checkbox" value={value} id={id} name={id} checked={checked} onChange={this.onChange.bind(this, value)} disabled={readOnly} />
                <label {...labelProps} htmlFor={id}>
                    {renderLabel(option)}
                </label>
                {renderExtra ? renderExtra(option) : null}
            </div>
        );
    }

    render() {
        const { options } = this.props;

        return (
            <div>
                {options.map(this.renderOption)}
            </div>
        );
    }
}
