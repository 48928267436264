import {createContext, PropsWithChildren, useContext, useState} from 'react';

interface SimilarSearchContextType {
  file: File|null
  setFile: (file: File|null) => void
}

const SimilarSearchContext = createContext<SimilarSearchContextType>({
  file: null,
  setFile: () => {},
});

export function useSimilarSearchContext() {
  return useContext(SimilarSearchContext);
}

export default function SimilarSearchContextProvider({ children }: PropsWithChildren) {
  const [file, setFile] = useState<File|null>(null);

  const updateFile = (newFile: File|null) => {
    setFile(newFile);
  };

  return <SimilarSearchContext.Provider value={{file, setFile: updateFile}}>
    {children}
  </SimilarSearchContext.Provider>;
}
