import { useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { LOGIN, REGISTER } from '@App/routes';
import Modal from '@App/components/layout/Modal';

export default function ForbiddenPlayerMessage(props) {
    useEffect(function () {
        /** WARNING: This is a hack to prevent SSR tests (with phpunit) to respond 500 :
         * this mode doesn't recognize 'document' JavaScript keyword  */
        if(typeof document !== 'undefined') {
            document.body.classList.add('no-scroll');
            return () => document.body.classList.remove('no-scroll');
        }
    });

    const { onClose } = props ;

    return (
        <Modal
            title=""
            icon="close"
            onClose={onClose}
            classModal="register-modal"
        >
            <h1>{I18n.t('modal.forbidden.title')}</h1>
            <p className="lead">{I18n.t('modal.forbidden.subtitle')}</p>
            <ul className="list list--raw">
                <li>
                    <i className="icon icon--check" aria-hidden="true"></i>
                    {I18n.t('modal.forbidden.checkmark-1')}
                </li>
                <li>
                    <i className="icon icon--check" aria-hidden="true"></i>
                    {I18n.t('modal.forbidden.checkmark-2')}
                </li>
            </ul>
            <a href={REGISTER.getUrl()} className="btn">
                {I18n.t('modal.forbidden.register')}
            </a>
            <a href={LOGIN.getUrl()}>{I18n.t('modal.forbidden.login')}</a>
        </Modal>
    );
}

ForbiddenPlayerMessage.propTypes = {
    onClose: PropTypes.func.isRequired,
};
