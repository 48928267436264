import { Component } from 'react';
import PropTypes from 'prop-types';

export default class SingleChoice extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.any).isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string,
        getValue: PropTypes.func,
        renderLabel: PropTypes.func,
        labelProps: PropTypes.object,
        inline: PropTypes.bool,
    };

    static defaultProps = {
        value: null,
        getValue: option => option,
        renderLabel: option => option,
        labelProps: {},
        inline: false,
    };

    constructor(props) {
        super(props);

        this.renderOption = this.renderOption.bind(this);
    }

    onChange(value) {
        this.props.onChange(value);
    }

    renderOption(option) {
        const { name, value: currentValue, renderLabel, getValue, labelProps, inline } = this.props;
        const value = getValue(option);
        const checked = currentValue === value;
        const id = `${name}-${value}`;

        return (
            <div key={id} className={`radio ${inline ? 'radio--inline' : ''}`}>
                <input type="radio" value={value} id={id} name={name} checked={checked} onChange={this.onChange.bind(this, value)} />
                <label {...labelProps} htmlFor={id}>
                    {renderLabel(option)}
                </label>
            </div>
        );
    }

    render() {
        const { options } = this.props;

        return (
            <div>
                {options.map(this.renderOption)}
            </div>
        );
    }
}
