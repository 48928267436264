import { Component } from 'react';
import PropTypes from 'prop-types';
import BreadCrumb from '@App/components/navigation/BreadCrumb';
import classNames from 'classnames';

export default class PageHeader extends Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        className: 'header',
    };

    static Image(props) {
        const { src, alt, ...childProps } = props;

        return <img src={src} alt={alt} className="image" {...childProps} />;
    }

    static Title(props) {
        const { children, ...childProps } = props;

        return <h1 {...childProps}>{children}</h1>;
    }

    static Description(props) {
        const { children, ...childProps } = props;

        if (!children) {
            return null;
        }

        return <div className="description" {...childProps}>{children}</div>;
    }

    render() {
        const { className, children } = this.props;

        return (
            <header className={classNames(className, 'container-fluid')}>
                <BreadCrumb dark />
                {children}
            </header>
        );
    }
}
