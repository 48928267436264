import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import BreadCrumbItem from '@App/components/navigation/BreadCrumbItem';
import AlbumBreadCrumbItem from '@App/components/navigation/breadcrumb/AlbumBreadCrumbItem';
import LabelBreadCrumbItem from '@App/components/navigation/breadcrumb/LabelBreadCrumbItem';
import PlaylistBreadCrumbItem from '@App/components/navigation/breadcrumb/PlaylistBreadCrumbItem';
import ProjectBreadCrumbItem from '@App/components/navigation/breadcrumb/ProjectBreadCrumbItem';
import { findByName, LIBRARY, PLAYLISTS, PROJECTS, ALBUMS, ALBUM, LABEL, PLAYLIST, PROJECT, LABELS } from '@App/routes';

class BreadCrumb extends Component {
    static propTypes = {
        route: PropTypes.string.isRequired,
        parameters: PropTypes.object,
        root: PropTypes.string.isRequired,
        dark: PropTypes.bool,
    };

    static defaultProps = {
        parameters: {},
        dark: false,
    };

    renderRootBreadCrumbItem(name, prefix = 'breadcrumb') {
        return <BreadCrumbItem route={name} label={I18n.t(`${prefix}.${name}`)} />;
    }

    renderRootItem(root) {
        switch (root) {
            case LIBRARY.name:
            case LABELS.name:
            case PROJECTS.name:
            case PLAYLISTS.name:
                return this.renderRootBreadCrumbItem(root);

            case ALBUMS.name:
                return this.renderRootBreadCrumbItem(LABELS.name);

            default:
                return null;
        }
    }

    renderCurrentItem(route, parameters) {
        switch (route) {
            case ALBUM.name:
                return <AlbumBreadCrumbItem {...parameters} />;

            case LABEL.name:
                return <LabelBreadCrumbItem {...parameters} />;

            case PLAYLIST.name:
                return <PlaylistBreadCrumbItem {...parameters} />;

            case PROJECT.name:
                return <ProjectBreadCrumbItem {...parameters} />;

            default:
                return null;
        }
    }

    render() {
        const { root, route, parameters, dark } = this.props;

        return (
            <ul className={`breadcrumb ${dark ? 'breadcrumb--dark' : ''}`}>
                {this.renderRootBreadCrumbItem('library')}
                {this.renderRootItem(root)}
                {this.renderCurrentItem(route, parameters)}
            </ul>
        );
    }
}

export default connect(
    state => {
        const { route, parameters } = state.navigation;
        const { root } = findByName(route);

        return { route, parameters, root };
    }
)(BreadCrumb);
