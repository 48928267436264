import SearchSimilarTracksByFile from '@App/api/query/SearchSimilarTracksByFile.graphql';
import SearchSimilarTracksByReference from '@App/api/query/SearchSimilarTracksByReference.graphql';
import SearchSimilarTracksByUrl from '@App/api/query/SearchSimilarTracksByUrl.graphql';
import Query from '@App/components/api/Query';
import BlockTitle from '@App/components/layout/BlockTitle';
import ShowAllTagsButton from '@App/components/model/track/ShowAllTagsButton';
import TrackVersionList, { mapTrackToVersion } from '@App/components/model/TrackVersionList';
import { SimilarResultPlayer } from '@App/components/page/library/SimilarResultPlayer';
import Button from '@App/components/ui/Button';
import Loader from '@App/components/ui/Loader';
import { SIZE_LG } from '@App/service/MobileWatcher';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSimilarSearchContext } from '@App/components/context/SimilarSearchContext';

SimilarResultList.propTypes = {
    count: PropTypes.number.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    versions: TrackVersionList.propTypes.versions,
    onLoadMore: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    mobile: PropTypes.bool,
    source: PropTypes.shape({
        label: PropTypes.string.isRequired,
        duration: PropTypes.number.isRequired,
        audioFileUrl: PropTypes.string.isRequired,
        waveform: PropTypes.array.isRequired,
    }),
};

function SimilarResultList({
    versions,
    count,
    hasNextPage,
    onLoadMore,
    error,
    source,
    mobile,
    loading,
}) {
    const [loadingMore, setLoadingMore] = useState(false);
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
        setIsloading(false);
    }, [versions]);

    const onClick = () => {
        setLoadingMore(true);
        onLoadMore().finally(() => setLoadingMore(false));
    };

    if (error) {
        return <BlockTitle key="title" title={I18n.t(`similar_results.error.${error}`)} />;
    }

    const title = isLoading || loading ? I18n.t('similar_results.loading') : I18n.t('similar_results.title', { count });

    return <Fragment>
        <BlockTitle title={title}>
            <ShowAllTagsButton />
        </BlockTitle>
        {source && !mobile && <SimilarResultPlayer source={source} isLoading={isLoading} setIsLoading={setIsloading} />}
        {isLoading ? <div><Loader/></div> : <TrackVersionList versions={versions} />}
        {hasNextPage ? <Button loading={loadingMore} onClick={onClick} label={I18n.t('similar_results.more')} /> : null}
    </Fragment>;
}

function getMapResult(key) {
    return function mapResult(data) {
        const { pageInfo, totalCount, edges, source } = data[key];

        return {
            hasNextPage: pageInfo.hasNextPage,
            count: totalCount,
            versions: edges.map(edge => mapTrackToVersion(edge.node.track, edge.node.highlights)),
            loading: false,
            source,
        };
    };
}

function mapLoading() {
    return {
        hasNextPage: false,
        count: 0,
        versions: [],
        source: null,
        loading: true,
    };
}

function mapError(error, type) {
    return {
        hasNextPage: false,
        count: 0,
        versions: [],
        loading: false,
        error: type,
        source: null,
    };
}

function SimilarResultListWrapper({
    url,
    track,
    includedTags,
    excludedTags,
    labels,
    minDuration,
    maxDuration,
    limit,
    suppressVocals,
    prioritizeBpm,
    limitTime,
    offset,
    setTime,
    mobile,
}) {
    const {file} = useSimilarSearchContext();

    if (url) {
        return (
            <Query
                component={SimilarResultList}
                loadingComponent={SimilarResultList}
                errorComponent={SimilarResultList}
                query={SearchSimilarTracksByUrl}
                variables={{
                    url,
                    includedTags,
                    excludedTags,
                    labels,
                    minDuration,
                    maxDuration,
                    suppressVocals,
                    prioritizeBpm,
                    limit,
                    offset,
                    limitTime
                }}
                mapResult={getMapResult('similarTracksByUrl')}
                mapLoading={mapLoading}
                mapError={error => mapError(error, 'url')}
                loadMore="similarTracksByUrl"
                childProps={{ mobile }}
            />
        );
    }

    if (file) {
        return (
            <Query
                component={SimilarResultList}
                loadingComponent={SimilarResultList}
                errorComponent={SimilarResultList}
                query={SearchSimilarTracksByFile}
                variables={{
                    file,
                    includedTags,
                    excludedTags,
                    labels,
                    minDuration,
                    maxDuration,
                    suppressVocals,
                    prioritizeBpm,
                    offset,
                    limitTime,
                    limit,
                    id: `file-${file.name}-${file.size}-${file.lastModified}`
                }}
                mapResult={getMapResult('similarTracksByFile')}
                mapLoading={mapLoading}
                mapError={error => mapError(error, 'file')}
                loadMore="similarTracksByFile"
                childProps={{ setTime, mobile}}
            />
        );
    }

    if (track) {
        return (
            <Query
                component={SimilarResultList}
                loadingComponent={SimilarResultList}
                errorComponent={SimilarResultList}
                query={SearchSimilarTracksByReference}
                variables={{
                    reference: track,
                    includedTags,
                    excludedTags,
                    labels,
                    minDuration,
                    maxDuration,
                    limit,
                    suppressVocals,
                    prioritizeBpm,
                    offset: offset === 0 ? null : offset,
                    limitTime: limitTime === 0 ? null : limitTime,
                }}
                mapResult={getMapResult('similarTracksByReference')}
                mapLoading={mapLoading}
                mapError={error => mapError(error, 'track')}
                loadMore="similarTracksByReference"
                childProps={{ mobile }}
            />
        );
    }

    throw new Error('No input for similar search.');
}

SimilarResultListWrapper.propTypes = {
    url: PropTypes.string,
    // file: PropTypes.object,
    track: PropTypes.string,
    suppressVocals: PropTypes.bool,
    mobile: PropTypes.bool.isRequired,
    includedTags: PropTypes.arrayOf(
        PropTypes.string.isRequired
    ).isRequired,
    excludedTags: PropTypes.arrayOf(
        PropTypes.string.isRequired
    ).isRequired,
    labels: PropTypes.arrayOf(
        PropTypes.string.isRequired
    ).isRequired,
    minDuration: PropTypes.number,
    maxDuration: PropTypes.number,
    offset: PropTypes.number,
    limitTime: PropTypes.number,
};

SimilarResultListWrapper.defaultProps = {
    url: null,
    file: null,
    track: null,
    limit: 30,
    suppressVocals: false
};

export default connect(
    state => ({
        url: state.similarity.url,
        // file: state.similarity.file,
        track: state.similarity.track,
        offset: state.similarity.offset,
        limitTime: state.similarity.limit,
        includedTags: state.similarity.includedTags,
        excludedTags: state.similarity.excludedTags,
        labels: state.similarity.labels,
        minDuration: state.similarity.minDuration,
        maxDuration: state.similarity.maxDuration,
        suppressVocals: state.similarity.suppressVocals,
        prioritizeBpm: state.similarity.prioritizeBpm,
        mobile: state.navigation.size < SIZE_LG,
    }),
)(SimilarResultListWrapper);
