export function scrollToElement(element) {
    if (!element) {
        return;
    }

    const fixedHeaderHeight = document.querySelector('.fixed-header').getBoundingClientRect().height;
    const offsetTop = element.getBoundingClientRect().top + window.scrollY - fixedHeaderHeight;

    // Check scrollToOptions support:
    if ('scrollBehavior' in document.documentElement.style) {
        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth',
        });

        return;
    }

    window.scrollTo(0, offsetTop);
}
