import { useEffect } from 'react';
import PropTypes from 'prop-types';
import PlayerContainer from '@App/components/container/PlayerContainer';
import VersionsPanelContainer from '@App/components/container/VersionsPanelContainer';
import InformationsPanelContainer from '@App/components/container/InformationsPanelContainer';
import DownloadPanelContainer from '@App/components/container/DownloadPanelContainer';
import SimultaneousLoginsSpottedPanelContainer from '@App/components/container/SimultaneousLoginsSpottedPanelContainer';
import SelectBriefPanelContainer from '@App/components/container/SelectBriefPanelContainer';
import SelectProjectPanelContainer from '@App/components/container/SelectProjectPanelContainer';
import BackdropContainer from '@App/components/container/BackdropContainer';
import TopBar from '@App/components/layout/TopBar';
import SearchBar from '@App/components/search/SearchBar';
import Footer from '@App/components/layout/Footer';
import { useDispatch } from 'react-redux';
import { closeSelectProjectPanel } from '@App/store/actions/project';

export default function ContentPage({ children, allowedToDownload }) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(closeSelectProjectPanel());
    }, [dispatch]);

    return (
        <div className="content-page">
            <div className="fixed-header">
                <TopBar/>
                <SearchBar/>
            </div>
            <main className="main">
                {children}
            </main>
            <Footer />
            <PlayerContainer />
            <VersionsPanelContainer />
            <InformationsPanelContainer />
            <DownloadPanelContainer allowedToDownload={allowedToDownload} />
            <SelectBriefPanelContainer />
            <SelectProjectPanelContainer />
            <SimultaneousLoginsSpottedPanelContainer />
            <BackdropContainer />
        </div>
    );
}

ContentPage.propTypes = {
    children: PropTypes.node.isRequired,
    /**
     * On some content pages, even a user without "free" download permissions should be allowed to download,
     * for instance the purchased tracks from their order page.
     *
     * LATER: we should rather get a list of purchased references allowed to be downloaded for the user.
     */
    allowedToDownload: PropTypes.bool,
};

ContentPage.defaultProps = {
    allowedToDownload: false,
};
