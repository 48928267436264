import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import {connect} from 'react-redux';

function ReportAProblemOnAdminButton(props) {
    const { adminUrl, canManageAlbum, ...childProps } = props;

    if (!canManageAlbum) {
        return null;
    }

    delete childProps.dispatch;

    return (
        <a {...childProps} href={adminUrl}>{I18n.t('model.track.actions.report_a_problem')}</a>
    );
}

ReportAProblemOnAdminButton.propTypes = {
    adminUrl: PropTypes.string,
    canManageAlbum: PropTypes.bool.isRequired,
};

export default connect((state) => ({
    canManageAlbum: state.authentication.permissions.canManageAlbum
}))(ReportAProblemOnAdminButton);
