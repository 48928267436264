import Select from '@App/components/form/Select';

const options = [
    {label: 'Télévision', value: 'tv'},
    {label: 'Radio', value: 'radio'},
    {label: 'Cinéma', value: 'movie'},
    {label: 'Publicité', value: 'advertising'},
    {label: 'Contenu de marque', value: 'branded_content'},
    {label: 'Jeu vidéo', value: 'video_game'},
    {label: 'Contenu de divertissement', value: 'entertainment_content'},
    {label: 'Spectacle', value: 'spectacle'},
    {label: 'Supervision musicale', value: 'music_supervision'},
    {label: 'Label', value: 'label'},
    {label: 'Autre', value: 'other'}
];

/**
 * Keep file in sync with the Enum `\App\Domain\Entity\User\Sector`
 */
export default function SelectUserSector(props) {
    return <Select {...props} options={options}/>;
}
