import I18n from 'i18n-js';
import fr from './fr.json';
import frComponents from '@App/translations/frComponents';

// Init translations
I18n.fallbacks = true;
I18n.locale = 'fr-FR';
I18n.defaultLocale = 'fr-FR';

I18n.translations = {
    fr: { ...fr, Components: frComponents },
};

// Don't fail silently
I18n.missingTranslation = function onMissingTranslation(key) {
    throw new Error(`Missing translation key "${key}".`);
};

export default I18n;
