import PropTypes from 'prop-types';
import types from '@App/types';
import Loader from '@App/components/ui/Loader';
import I18n from 'i18n-js';
import CartList from '@App/components/page/cart/CartList';
import {LIBRARY} from '@App/routes';
import Link from '@App/components/navigation/Link';

ShowCartStep.propTypes = {
    tracks: PropTypes.arrayOf(types.Cart.CartItem.isRequired).isRequired,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
};

export default function ShowCartStep({ tracks, loading, onSubmit }) {
    if (loading) {
        return <Loader />;
    }

    if (tracks.length === 0) {
        return <>
            <p>{I18n.t('page.cart.empty')}</p>
            <Link className="btn btn--primary btn--large" route={LIBRARY.name}>
                {I18n.t('page.cart.actions.backToLibrary')}
            </Link>
        </>;
    }

    return <>
        <CartList tracks={tracks} />
        <div className="page-cart__actions">
            <button type="button" className="btn btn--primary" onClick={onSubmit}>
                {I18n.t('page.cart.actions.chooseLicense')} *
            </button>

            <p className="mention">
                * {I18n.t('page.cart.actions.chooseLicenseLegend')}
            </p>
        </div>
    </>;
}
