import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

export default class TabsBlockTitle extends Component {
    static propTypes = {
        tabs: PropTypes.arrayOf(
            PropTypes.string.isRequired
        ).isRequired,
        renderLabel: PropTypes.func.isRequired,
        renderContent: PropTypes.func.isRequired,
        children: PropTypes.node,
    };

    static defaultProps = {
        children: null,
    };
    constructor(props) {
        super(props);

        this.state = {
            tab: props.tabs[0],
        };

        this.onClick = this.onClick.bind(this);
        this.renderTabButton = this.renderTabButton.bind(this);
    }

    setTab(tab) {
        this.setState({ tab });
    }

    onClick(event) {
        this.setTab(event.currentTarget.value);
    }

    renderTabButton(tab) {
        const { renderLabel } = this.props;
        const active = this.state.tab === tab;

        return (
            <button key={tab} value={tab} type="button" className={`tabs__item ${active ? 'active' : ''}`} onClick={this.onClick}>
                {renderLabel(tab)}
            </button>
        );
    }

    renderChildren(children) {
        if (!children) {
            return null;
        }

        return (
            <div className="title-block__actions">
                {children}
            </div>
        );
    }

    render() {
        const { tabs, renderContent, children } = this.props;
        const { tab } = this.state;

        return <Fragment>
            <div key="title" className="title-block title-block--tabs">
                <div className="tabs">
                    {tabs.map(this.renderTabButton)}
                </div>
                {this.renderChildren(children)}
            </div>
            {renderContent(tab)}
        </Fragment>;
    }
}
