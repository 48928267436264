import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Label from '@App/components/form/Label';
import Errors from '@App/components/form/Errors';

export default class Select extends Component {
    static propTypes = {
        value: PropTypes.any,
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        displayEmptyOptions: PropTypes.bool,
        errors: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        onChange: PropTypes.func,
        options: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any.isRequired,
        })).isRequired,
    };

    static defaultProps = {
        value: null,
        type: 'text',
        required: false,
        displayEmptyOptions: null,
        errors: [],
        onChange: () => {},
    };

    constructor(props) {
        super(props);

        this.hasErrors = this.hasErrors.bind(this);
        this.renderOptions = this.renderOptions.bind(this);
    }

    /**
     * @returns {Boolean}
     */
    hasErrors() {
        return [].concat(this.props.errors).length > 0;
    }

    renderOptions() {
        const { options } = this.props;

        const selectOptions = options.map(({ label, value}, i) =>
            <option key={i} value={value}>{label}</option>
        );

        if (!this.props.required || this.props.displayEmptyOptions) {
            selectOptions.unshift(<option key="none" value={null} label="-" />);
        }

        return selectOptions;
    }

    render() {
        const { id, label, required, disabled, onChange, errors, value } = this.props;

        return (
            <div className="form">
                <Label forId={id} required={required}>{label}</Label>
                <div className="select form__select">
                    <select
                        id={id}
                        onChange={e => onChange(e.target.value === '' && !required ? null : e.target.value, e)}
                        defaultValue={null === value ? '' : value}
                        className={classNames({
                            'form__select--error': this.hasErrors(),
                        })}
                        required={required}
                        disabled={disabled}
                    >{this.renderOptions()}</select>
                </div>
                <Errors errors={errors} />
            </div>
        );
    }
}
