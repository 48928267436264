import { Component } from 'react';
import PropTypes from 'prop-types';
import FormUtils from '@App/utils/form';
import I18n from 'i18n-js';
import classNames from 'classnames';
import GraphQLUtils from '@App/utils/graphql';
import Mutation from '@App/components/api/Mutation';
import EditMyUserPreferencesMutation from '@App/api/mutation/EditMyUserPreferences.graphql';
import Checkbox from '@App/components/form/Checkbox';

class EditMyUserPreferences extends Component {
    static propTypes = {
        profile: PropTypes.shape({
            acceptsNewsletter: PropTypes.bool.isRequired,
        }).isRequired,
        mutate: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        success: PropTypes.bool,
        errors: PropTypes.object,
    };

    static defaultProps = {
        loading: false,
        errors: {},
        success: null,
    };

    constructor(props) {
        super(props);

        const { acceptsNewsletter } = props.profile;

        this.state = {
            form: {
                acceptsNewsletter,
            },
        };

        this.handleFieldChange = fieldName => value => FormUtils.handleFieldChange.bind(this)('form', fieldName, value);
        this.onSubmit = this.onSubmit.bind(this);
        this.getErrors = this.getErrors.bind(this);
        this.linkState = this.linkState.bind(this);
    }

    /**
     * @param {String} path
     */
    getErrors(path) {
        return this.props.errors[path] || [];
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.props.loading) {
            return;
        }

        const { form } = this.state;
        const { mutate } = this.props;

        mutate({ payload: form });
    }

    /**
     * @param {String} field field name to link
     */
    linkState(field) {
        return {
            value: this.state.form[field],
            onChange: this.handleFieldChange(field),
            errors: this.getErrors(field),
        };
    }

    render() {
        const { loading, success } = this.props;

        return (
            <div>
                <h2 className="h1">{I18n.t('page.my_account.edit_preferences.title')}</h2>
                <hr/>

                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-1">
                            <Checkbox {...this.linkState('acceptsNewsletter')}
                                label={I18n.t('page.my_account.edit_preferences.form.acceptsNewsletter')}
                                id="acceptsNewsletter"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-lg-6 offset-lg-1">
                            {success === true && <div className="alert">
                                <span>{I18n.t('page.my_account.edit_preferences.success')}</span>
                            </div>}

                            {success === false && <div className="alert alert--error">
                                <span>{I18n.t('page.my_account.edit_preferences.error')}</span>
                            </div>}
                        </div>
                    </div>

                    <div className="row pt-3">
                        <div className="col-lg-3 offset-lg-4">
                            <button
                                type="submit"
                                className={classNames('btn btn--primary btn--full', { disabled: loading })}
                                disabled={loading}
                            >{I18n.t('page.my_account.edit_preferences.form.submit')}</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default function EditMyUserPreferencesWithMutation(props) {
    return <Mutation
        {...props}
        component={EditMyUserPreferences}
        mutation={EditMyUserPreferencesMutation}
        loadingComponent={EditMyUserPreferences}
        errorComponent={EditMyUserPreferences}
        mapResult={data => ({ success: !!data.editMyPreferences })}
        mapLoading={() => ({ loading: true, success: null })}
        mapError={(error) => {
            const errorsByPath = GraphQLUtils.errorsByPath((error && error.graphQLErrors) || []);

            return {
                success: false,
                errors: {
                    acceptsNewsletter: errorsByPath['acceptsNewsletter'] || [],
                }
            };
        }}
    />;
}
