import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import ShareButton from '@App/components/model/share/ShareButton';
import { copyPlaylistUrl } from '@App/utils/share';

export default class SharePlaylistButton extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.onShare = this.onShare.bind(this);
    }

    onShare() {
        const { id, slug } = this.props;

        copyPlaylistUrl(id, slug);
    }

    render() {
        const { ...props } = this.props;

        delete props.slug;
        delete props.id;

        return <ShareButton label={I18n.t('model.playlist.actions.share')} onShare={this.onShare} {...props} />;
    }
}
