import AbstractMetaHandler from './AbstractMetaHandler';

export default class DomMetaHandler extends AbstractMetaHandler {
    constructor(origin) {
        super(origin);

        this.descriptionElement = document.head.querySelector('meta[name="description"]');
        this.ogTitleElement = document.head.querySelector('meta[property="og:title"]');
        this.ogDescriptionElement = document.head.querySelector('meta[property="og:description"]');
        this.ogImageElement = document.head.querySelector('meta[property="og:image"]');
        this.ogUrlElement = document.head.querySelector('meta[property="og:url"]');
    }

    set title(value) {
        document.title = value;
        this.ogTitleElement.content = value;
    }

    set description(value) {
        this.descriptionElement.content = value;
        this.ogDescriptionElement.content = value;
    }

    set image(value) {
        this.ogImageElement.content = value;
    }

    set url(value) {
        this.ogUrlElement.content = value;
    }

    get title() {
        return document.title;
    }

    get description() {
        return this.descriptionElement.content;
    }

    get image() {
        return this.ogImageElement.content;
    }

    get url() {
        return this.ogUrlElement.content;
    }
}
