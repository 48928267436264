import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AbstractTagButton from '@App/components/search/tags/AbstractTagButton';
import { isTagIncluded, isTagExcluded } from '@App/store/reducers/search';
import { addToIncludedTags, removeFromIncludedTags } from '@App/store/actions/search';

const IncludeTagButtonWrapper = connect(
    (state, props) => {
        const { id, includeClassname, excludeClassname } = props;
        const active = isTagIncluded(state, id);
        let className = props.className || '';

        if (includeClassname && active) {
            className += ` ${includeClassname}`;
        }

        if (excludeClassname && isTagExcluded(state, id)) {
            className += ` ${excludeClassname}`;
        }

        return { active, className };
    },
    (dispatch, props) => ({
        add: () => dispatch(addToIncludedTags(props.id)),
        remove: () => dispatch(removeFromIncludedTags(props.id)),
    })
)(AbstractTagButton);

IncludeTagButtonWrapper.propTypes = {
    id: PropTypes.string.isRequired,
    includeClassname: PropTypes.string,
    excludeClassname: PropTypes.string,
};

IncludeTagButtonWrapper.defaultProps = {
    includeClassname: null,
    excludeClassname: null,
};

export default IncludeTagButtonWrapper;
