import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import ListFilterTags from '@App/api/query/ListFilterTags.graphql';
import Query from '@App/components/api/Query';
import Modal from '@App/components/layout/Modal';
import Filters from '@App/components/search-filters/Filters';
import { closeFilterModal } from '@App/store/actions/navigation';
import { SIZE_LG } from '@App/service/MobileWatcher';

class SearchFilters extends Component {
    static propTypes = {
        ...SearchFiltersWrapper.propTypes,
        tags: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    render() {
        const { loading, mobile, modalOpen, tags, onCloseModal } = this.props;

        if (mobile) {
            if (!modalOpen) {
                return null;
            }

            return (
                <Modal title={I18n.t('search.filters.title')} icon="filter" onClose={onCloseModal}>
                    <div className="filters">
                        <Filters tags={tags} loading={loading} />
                    </div>
                </Modal>
            );
        }

        return (
            <aside className="filters">
                <Filters tags={tags} loading={loading} />
            </aside>
        );
    }
}

function mapResult(data) {
    const { tags } = data;

    return { tags, loading: false };
}

function mapLoading() {
    return { tags: [], loading: true };
}

function SearchFiltersWrapper(props) {
    return (
        <Query
            component={SearchFilters}
            loadingComponent={SearchFilters}
            variables={{ level: 1 }}
            query={ListFilterTags}
            mapResult={mapResult}
            mapLoading={mapLoading}
            childProps={props}
        />
    );
}

SearchFiltersWrapper.propTypes = {
    mobile: PropTypes.bool.isRequired,
    modalOpen: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
};

export default connect(
    state => ({
        mobile: state.navigation.size < SIZE_LG,
        modalOpen: state.navigation.filterModalOpen,
    }),
    dispatch => ({
        onCloseModal: () => dispatch(closeFilterModal()),
    })
)(SearchFiltersWrapper);
