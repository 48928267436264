import { useRef, useState } from 'react';
import I18n from 'i18n-js';
import Button from '@App/components/ui/Button';
import PropTypes from 'prop-types';
import { useAddTrackToCart } from '@App/api/hooks/useCart';
import useAuth from '@App/common/hooks/useAuth';
import { redirectToLogin, redirectToSubscriptionForm } from '@App/routes';
import classNames from 'classnames';

AddTrackToCartButton.propTypes = {
    reference: PropTypes.string.isRequired,
    asDropDownItem: PropTypes.bool.isRequired,
    className: PropTypes.string,
};

AddTrackToCartButton.defaultProps = {
    className: '',
    asDropDownItem: false,
};

export default function AddTrackToCartButton({ reference, asDropDownItem, ...props }) {
    const buttonRef = useRef();
    const { addToCart, loading } = useAddTrackToCart();
    const { authenticated, permissions, profile } = useAuth();
    const [ clicked, setClicked ] = useState(false);

    async function onClick(e) {
        // prevent closing the dropdown if wrapped into
        e.stopPropagation();

        if (!authenticated) {
            return redirectToLogin();
        }

        if (loading) {
            return;
        }

        if (!asDropDownItem && profile.type === 'individual') {
            // redirect to the subscription page (as this is the recommended behavior)
            return redirectToSubscriptionForm();
        }

        await addToCart(reference);

        setClicked(true);

        buttonRef.current.success();
    }

    if (!permissions.isInAllowedCountry) {
        if (asDropDownItem) {
            return null;
        }

        return <Button
            ref={buttonRef}
            label="License not available in your country"
            onClick={() => {}}
            {...props}
            className={classNames(props.className, {
                'action-button--disabled': true,
            })}
            data-tooltip="License not available in your country"
        />;
    }

    return <Button
        ref={buttonRef}
        label={I18n.t('model.track.actions.addToCart')}
        onClick={onClick}
        loading={loading}
        successIcon={!asDropDownItem ? 'cart' : ''}
        successLabel={I18n.t('model.track.actions.addToCart:success')}
        {...props}
        className={classNames(props.className, {
            'action-button--selected': clicked && !asDropDownItem,
        })}
        data-tooltip={I18n.t('model.track.actions.addToCart:success') && !asDropDownItem}
    />;
}
