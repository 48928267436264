import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeFromIncludedTags, removeFromExcludedTags } from '@App/store/actions/search';
import GetTagName from '@App/api/query/GetTagName.graphql';
import Loader from '@App/components/ui/Loader';
import Query from '@App/components/api/Query';

class SearchTag extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        remove: PropTypes.func.isRequired,
    };

    static TagName(props) {
        const { name } = props;

        return name ? <span>{name}</span> : <Loader />;
    }

    render() {
        const { TagName } = this.constructor;
        const { id, remove } = this.props;

        return (
            <div className="search-items--item search-tag">
                <Query
                    component={TagName}
                    query={GetTagName}
                    variables={{ id }}
                    mapResult={data => ({ name: data.tag.name })}
                    mapLoading={() => ({ name: null })}
                />
                <button type="button" className="search-items--remove" onClick={remove}>
                    <i className="icon icon--clear"></i>
                </button>
            </div>
        );
    }
}

export default connect(
    null,
    (dispatch, props) => ({
        remove: () => dispatch(props.excluded ? removeFromExcludedTags(props.id) : removeFromIncludedTags(props.id)),
    })
)(SearchTag);
