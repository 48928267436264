export const PENDING = 'PENDING';
export const PAID = 'PAID';

const OrderStatus = {
    PENDING,
    PAID,
};

OrderStatus.isPaid = (status) => OrderStatus.PAID === status;

export default OrderStatus;
