import { Component } from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import ProjectArchive from '@App/api/mutation/ProjectArchive.graphql';
import Mutation from '@App/components/api/Mutation';
import Button from '@App/components/ui/Button';
import Loader from '@App/components/ui/Loader';

class ArchiveProjectButton extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        mutate: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        success: PropTypes.bool,
        errors: PropTypes.object,
    };

    static defaultProps = {
        loading: false,
        success: null,
        errors: null,
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const { mutate, loading, id } = this.props;

        if (loading) {
            return;
        }

        mutate({ id });
    }

    render() {
        const { loading, ...props } = this.props;
        const buttonLabel = loading ? <Loader /> : I18n.t('model.project.actions.archive');

        delete props.id;
        delete props.success;
        delete props.errors;
        delete props.mutate;

        return <Button {...props} label={buttonLabel} onClick={this.onClick} />;
    }
}

export default function Wrapper(props) {
    return <Mutation
        mutation={ProjectArchive}
        component={ArchiveProjectButton}
        loadingComponent={ArchiveProjectButton}
        errorComponent={ArchiveProjectButton}
        mapResult={() => ({ success: true })}
        mapLoading={() => ({ loading: true, success: null })}
        childProps={props}
    />;
}

Wrapper.propTypes = {
    id: PropTypes.string.isRequired,
};
