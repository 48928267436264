import {useDispatch} from 'react-redux';
import I18n from 'i18n-js';
import { LIBRARY } from '@App/routes';
import { navigate } from '@App/store/actions/navigation';
import { setTrack } from '@App/store/actions/similarity';
import Button from '@App/components/ui/Button';

interface Props {
  trackReference: string
  asDropDownItem: boolean
}

export  default function SearchSimilarTrackButton({trackReference, asDropDownItem}: Props) {
  const label = I18n.t('model.track.actions.similar_tracks');
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setTrack(trackReference));
    dispatch(navigate(LIBRARY.name));
  };

  if (asDropDownItem) {
    return <Button onClick={handleClick} label={label} className='link link--light' />;
  }

  return <Button onClick={handleClick} label={label} tooltip icon="similar" className='action-button action-button--light' />;
}
