import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import GraphQLUtils from '@App/utils/graphql';
import Form from '@App/components/form/Form';
import TextInput from '@App/components/form/TextInput';
import NumberInput from '@App/components/form/NumberInput';
import DurationInput from '@App/components/form/DurationInput';
import ConditionInput from '@App/components/form/ConditionInput';
import TrackListInput from '@App/components/form/TrackListInput';
import SupportsChoice from '@App/components/form/SupportsChoice';
import Checkbox from '@App/components/form/Checkbox';
import Radio from '@App/components/form/Radio';
import Mutation from '@App/components/api/Mutation';
import LicenceRequestCreateCorporateMovie from '@App/api/mutation/LicenceRequestCreateCorporateMovie.graphql';
import { onAddLicenceRequest } from '@App/api/store';
import FormUtils from '@App/utils/form';
import UrlInput from '@App/components/form/UrlInput';

class CorporateMovieLicenceRequestForm extends Component {
    static propTypes = {
        id: PropTypes.string,
        loading: PropTypes.bool,
        errors: PropTypes.shape(),
        mutate: PropTypes.func.isRequired,
        onSuccess: PropTypes.func,
        licenceRequest: PropTypes.shape(),
        readOnly: PropTypes.bool,
    };

    static defaultProps = {
        id: null,
        errors: {},
        loading: false,
        onSuccess: null,
        licenceRequest: null,
        readOnly: false,
    };

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        const licencePattern = /^\d{6}$/;

        this.fields = [
            // Limited Partner
            { type: TextInput, name: 'limitedPartnerName', required: true },
            { type: TextInput, name: 'limitedPartnerAddress', required: true },
            { type: TextInput, name: 'limitedPartnerPhone', required: true },
            { type: TextInput, name: 'limitedPartnerEmail', required: true },
            { type: UrlInput, name: 'limitedPartnerWebsite' },
            { type: Radio, key: 'limitedPartnerToInvoice', required: true, name: 'invoiceTarget', choice: 'limitedPartner' },
            // Production Company
            { type: TextInput, name: 'productionCompanyName', required: true },
            { type: TextInput, name: 'productionCompanyAddress', required: true },
            { type: TextInput, name: 'productionCompanyPhone', required: true },
            { type: TextInput, name: 'productionCompanyEmail', required: true },
            { type: Radio, key: 'productionCompanyToInvoice', required: true, name: 'invoiceTarget', choice: 'productionCompany' },
            // SoundTrack Oor Illustration
            { type: TextInput, name: 'duplicatorName' },
            { type: TextInput, name: 'duplicatorAddress' },
            { type: TextInput, name: 'duplicatorPhone' },
            { type: TextInput, name: 'duplicatorEmail' },
            { type: UrlInput, name: 'duplicatorWebsite' },
            // SoundTrack Oor Illustration
            { type: TextInput, name: 'soundTrackOrIllustrationName' },
            { type: TextInput, name: 'soundTrackOrIllustrationAddress' },
            { type: TextInput, name: 'soundTrackOrIllustrationEmail' },
            { type: UrlInput, name: 'soundTrackOrIllustrationWebsite' },
            // Handle invoice
            { type: Checkbox, name: 'handleInvoice', label: I18n.t('form.handleInvoice.label') },
            // Web Report
            { type: TextInput, name: 'title', required: true },
            {
                type: DurationInput,
                name: 'duration',
                required: true,
                // validator: value => value > 0
            },
            { type: NumberInput, name: 'numberOfCopies', min: 0 },
            { type: SupportsChoice, name: 'supports', label: null },
            {
                type: TextInput,
                name: 'previousLicenceRequest',
                help: true,
                pattern: licencePattern.toString().slice(1, -1),
                // validator: value => value === null || licencePattern.test(value)
            },
            // Tracks
            {
                type: TrackListInput,
                name: 'tracks',
                label: null,
                // validator: value => value.length > 0 && value.every(track => track.duration > 0)
            },
            // SACD-SCAM
            { type: TextInput, name: 'directors', help: true },
            { type: TextInput, name: 'authors', help: true },
            // Conditions
            { type: ConditionInput, name: 'generalConditions', required: true, url: '/CGL.pdf' },
        ];
    }

    componentDidUpdate(prevProps) {
        const { id, onSuccess } = this.props;

        if (id && !prevProps.id && onSuccess) {
            return onSuccess(id);
        }
    }

    renderForm(form) {
        const fields = Object.fromEntries(form.map(field => [field.key, field]));

        return (
            <div>
                <h2>{I18n.t('form.corporate_movie_licence_request.limitedPartner')}</h2>
                <div className="row">
                    <div className="col-sm-12">{fields.limitedPartnerName}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.limitedPartnerAddress}</div>
                </div>
                <div className="row">
                    <div className="col-sm-6">{fields.limitedPartnerPhone}</div>
                    <div className="col-sm-6">{fields.limitedPartnerEmail}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.limitedPartnerWebsite}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.limitedPartnerToInvoice}</div>
                </div>

                <h2>{I18n.t('form.corporate_movie_licence_request.productionCompany')}</h2>
                <div className="row">
                    <div className="col-sm-12">{fields.productionCompanyName}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.productionCompanyAddress}</div>
                </div>
                <div className="row">
                    <div className="col-sm-6">{fields.productionCompanyPhone}</div>
                    <div className="col-sm-6">{fields.productionCompanyEmail}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.productionCompanyToInvoice}</div>
                </div>

                <h2>{I18n.t('form.corporate_movie_licence_request.duplicator')}</h2>
                <div className="row">
                    <div className="col-sm-12">{fields.duplicatorName}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.duplicatorAddress}</div>
                </div>
                <div className="row">
                    <div className="col-sm-6">{fields.duplicatorPhone}</div>
                    <div className="col-sm-6">{fields.duplicatorEmail}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.duplicatorWebsite}</div>
                </div>

                <h2>{I18n.t('form.corporate_movie_licence_request.soundTrackOrIllustration')}</h2>
                <div className="row">
                    <div className="col-sm-12">{fields.soundTrackOrIllustrationName}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.soundTrackOrIllustrationAddress}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.soundTrackOrIllustrationEmail}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.soundTrackOrIllustrationWebsite}</div>
                </div>

                <div className="hero">
                    <h3 className="hero__title">{I18n.t('form.handleInvoice.title')}</h3>
                    <div className="hero__content">
                        <p>{I18n.t('form.handleInvoice.content')}</p>
                        {fields.handleInvoice}
                    </div>
                </div>

                <h2>{I18n.t('form.corporate_movie_licence_request.corporateMovie')}</h2>
                <div className="row">
                    <div className="col-sm-12">{fields.title}</div>
                </div>
                <div className="row">
                    <div className="col-sm-6">{fields.numberOfCopies}</div>
                    <div className="col-sm-6">{fields.duration}</div>
                </div>

                <h2>{I18n.t('form.corporate_movie_licence_request.supports')}</h2>
                {fields.supports}
                {fields.previousLicenceRequest}

                <h2>{I18n.t('form.corporate_movie_licence_request.tracks')}</h2>
                {fields.tracks}

                <h2>{I18n.t('form.corporate_movie_licence_request.SACDSCAM')}</h2>
                {fields.directors}
                {fields.authors}

                <h2>{I18n.t('form.corporate_movie_licence_request.conditions')}</h2>
                {fields.generalConditions}
            </div>
        );
    }

    onSubmit(data) {
        const { mutate, loading, id } = this.props;

        if (loading || id !== null) {
            return;
        }

        mutate({ payload: {
            title: data.title,
            duration: data.duration,
            numberOfCopies: data.numberOfCopies || 0,
            handleInvoice: data.handleInvoice,
            invoiceTarget: data.invoiceTarget,
            limitedPartner: {
                // type: data.limitedPartnerType,
                name: data.limitedPartnerName,
                address: data.limitedPartnerAddress,
                phone: data.limitedPartnerPhone,
                email: data.limitedPartnerEmail,
                website: data.limitedPartnerWebsite,
            },
            productionCompany: {
                name: data.productionCompanyName,
                address: data.productionCompanyAddress,
                phone: data.productionCompanyPhone,
                email: data.productionCompanyEmail,
            },
            duplicator: {
                name: data.duplicatorName,
                address: data.duplicatorAddress,
                phone: data.duplicatorPhone,
                email: data.duplicatorEmail,
                website: data.duplicatorWebsite,
            },
            soundTrackOrIllustration: {
                name: data.soundTrackOrIllustrationName,
                address: data.soundTrackOrIllustrationAddress,
                email: data.soundTrackOrIllustrationEmail,
                website: data.soundTrackOrIllustrationWebsite,
            },
            supports: data.supports.types,
            otherSupport: data.supports.types.includes('other') ? data.supports.otherValue : null,
            webSupportUrl: data.supports.types.includes('web') ? data.supports.webValue : null,
            previousLicenceRequest: data.previousLicenceRequest,
            directors: data.directors,
            authors: data.authors,
            tracks: data.tracks,
        } }, onAddLicenceRequest).catch(() => {
            FormUtils.scrollToFirstError();
        });
    }

    load(licenceRequest = null) {
        if (!licenceRequest) {
            return {
                invoiceTarget: 'limitedPartner',
                tracks: [],
            };
        }

        return {
            title: licenceRequest.title,
            duration: licenceRequest.duration,
            numberOfCopies: licenceRequest.numberOfCopies,
            handleInvoice: licenceRequest.handleInvoice,
            invoiceTarget: licenceRequest.invoiceTarget,
            // limitedPartnerType: licenceRequest.limitedPartner.type,
            limitedPartnerName: licenceRequest.limitedPartner.name,
            limitedPartnerAddress: licenceRequest.limitedPartner.address,
            limitedPartnerPhone: licenceRequest.limitedPartner.phone,
            limitedPartnerEmail: licenceRequest.limitedPartner.email,
            limitedPartnerWebsite: licenceRequest.limitedPartner.website,
            productionCompanyName: licenceRequest.productionCompany.name,
            productionCompanyAddress: licenceRequest.productionCompany.address,
            productionCompanyPhone: licenceRequest.productionCompany.phone,
            productionCompanyEmail: licenceRequest.productionCompany.email,
            duplicatorName: licenceRequest.duplicator.name,
            duplicatorAddress: licenceRequest.duplicator.address,
            duplicatorPhone: licenceRequest.duplicator.phone,
            duplicatorEmail: licenceRequest.duplicator.email,
            duplicatorWebsite: licenceRequest.duplicator.website,
            soundTrackOrIllustrationName: licenceRequest.soundTrackOrIllustration.name,
            soundTrackOrIllustrationAddress: licenceRequest.soundTrackOrIllustration.address,
            soundTrackOrIllustrationEmail: licenceRequest.soundTrackOrIllustration.email,
            soundTrackOrIllustrationWebsite: licenceRequest.soundTrackOrIllustration.website,
            supports: {
                types: licenceRequest.supports,
                otherValue: licenceRequest.otherSupport,
                webValue: licenceRequest.webSupportUrl,
            },
            previousLicenceRequest: licenceRequest.previousLicenceRequest,
            directors: licenceRequest.directors,
            authors: licenceRequest.authors,
            tracks: licenceRequest.tracks.map(licenceTrack => ({
                reference: licenceTrack.version.reference,
                duration: licenceTrack.duration,
            })),
            generalConditions: true,
        };
    }

    render() {
        const { id, loading, errors, licenceRequest, readOnly } = this.props;

        return <Form
            name="corporate_movie_licence_request"
            fields={this.fields}
            renderForm={this.renderForm}
            loading={loading}
            success={id !== null}
            onSubmit={this.onSubmit}
            data={this.load(licenceRequest)}
            readOnly={readOnly}
            errors={errors}
        />;
    }
}

function mapResult(data) {
    return { id: data.licenceRequestCreateCorporateMovie.id };
}

function mapLoading() {
    return { loading: true };
}

function mapError(error) {
    if (!error) {
        return { errors: {} };
    }

    const graphQLErrors = GraphQLUtils.errorsByPath(error.graphQLErrors);

    const tracksErrors = [].concat(
        graphQLErrors['tracks'],
        Object.entries(graphQLErrors)
            .filter(([path]) => /^tracks\[\d+\]\..+/.test(path))
            .reduce((carry, [, errors]) => {
                errors.forEach((error) => carry.includes(error) || carry.push(error));

                return carry;
            }, [])
    ).filter(Boolean);

    return { errors: {
        title: graphQLErrors['title'],
        duration: graphQLErrors['duration'],
        numberOfCopies: graphQLErrors['numberOfCopies'],
        handleInvoice: graphQLErrors['handleInvoice'],
        invoiceTarget: graphQLErrors['invoiceTarget'],
        // limitedPartnerType: graphQLErrors['limitedPartner.type'],
        limitedPartnerName: graphQLErrors['limitedPartner.name'],
        limitedPartnerAddress: graphQLErrors['limitedPartner.address'],
        limitedPartnerPhone: graphQLErrors['limitedPartner.phone'],
        limitedPartnerEmail: graphQLErrors['limitedPartner.email'],
        limitedPartnerWebsite: graphQLErrors['limitedPartner.website'],
        productionCompanyName: graphQLErrors['productionCompany.name'],
        productionCompanyAddress: graphQLErrors['productionCompany.address'],
        productionCompanyPhone: graphQLErrors['productionCompany.phone'],
        productionCompanyEmail: graphQLErrors['productionCompany.email'],
        duplicatorName: graphQLErrors['duplicator.name'],
        duplicatorAddress: graphQLErrors['duplicator.address'],
        duplicatorPhone: graphQLErrors['duplicator.phone'],
        duplicatorEmail: graphQLErrors['duplicator.email'],
        duplicatorWebsite: graphQLErrors['duplicator.website'],
        soundTrackOrIllustrationName: graphQLErrors['soundTrackOrIllustration.name'],
        soundTrackOrIllustrationAddress: graphQLErrors['soundTrackOrIllustration.address'],
        soundTrackOrIllustrationEmail: graphQLErrors['soundTrackOrIllustration.email'],
        soundTrackOrIllustrationWebsite: graphQLErrors['soundTrackOrIllustration.website'],
        supports: {
            self: graphQLErrors['supports'],
            webSupportUrl: graphQLErrors['webSupportUrl'],
            otherSupport: graphQLErrors['otherSupport'],
        },
        previousLicenceRequest: graphQLErrors['previousLicenceRequest'],
        directors: graphQLErrors['directors'],
        authors: graphQLErrors['authors'],
        tracks: tracksErrors,
    } };
}

/**
 * GraphQL wrapped DownloadProjectPanel
 *
 * @param {Object} props
 */
export default function CorporateMovieLicenceRequestFormWraper(props) {
    return <Mutation
        mutation={LicenceRequestCreateCorporateMovie}
        component={CorporateMovieLicenceRequestForm}
        loadingComponent={CorporateMovieLicenceRequestForm}
        errorComponent={CorporateMovieLicenceRequestForm}
        mapResult={mapResult}
        mapLoading={mapLoading}
        mapError={mapError}
        childProps={props}
    />;
}
