import { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Loader from '@App/components/ui/Loader';
import { useSimilarSearchContext } from '@App/components/context/SimilarSearchContext';

class SimilarityDropBox extends Component {
    static propTypes = {
        active: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        onDrag: PropTypes.func.isRequired,
        setFile: PropTypes.func.isRequired,
    };

    static defaultProps = {
        loading: false,
    };

    /** @type {Number} Mix file size in Mo */
    static maxFileSize = 100;

    /** @type {Array} Accepted file mime-types */
    static acceptedFileTypes = [
        'audio/mpeg',
        'audio/x-m4a',
        'audio/vnd.wave',
        'audio/wav',
        'audio/wave',
        'audio/x-pn-wav',
        'audio/x-wav',
        'audio/aiff',
        'audio/x-aiff',
    ];

    constructor(props) {
        super(props);

        this.state = {
            error: null,
        };

        this.onDrop = this.onDrop.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentDidMount() {
        document.addEventListener('drop', this.onDrop);
        document.addEventListener('dragover', this.onDragStart);
    }

    componentWillUnmount() {
        document.removeEventListener('drop', this.onDrop);
        document.removeEventListener('dragover', this.onDragStart);
    }

    clear() {
        this.setState({ error: null });
    }

    setFile(file) {
        const { maxFileSize, acceptedFileTypes } = this.constructor;

        // Browsing cancelled
        if (!file) {
            return;
        }

        // Wrong file type
        if (!acceptedFileTypes.includes(file.type)) {
            // console.error(`Unsupported file type "${file.type}".`);
            return this.setState({ error: 'type' });
        }

        // File too big
        if (file.size > (maxFileSize * 1000 * 1000)) {
            // console.error(`File too big: ${file.size/1000/1000}Mo exceeds max size of ${maxFileSize}Mo.`);
            return this.setState({ error: 'size' });
        }

        // File OK
        this.setState({ error: null });
        this.props.setFile(file);
    }

    onDragStart(event) {
        event.preventDefault();
        this.props.onDrag(true);
    }

    /** Droped file in window */
    onDrop(event) {
        event.preventDefault();
        this.setFile(event.dataTransfer.files[0]);
    }

    /** Browsed file button */
    onFileChange(event) {
        this.setFile(event.currentTarget.files[0]);
    }

    renderDropLabel() {
        const { error } = this.state;

        if (error) {
            const label = I18n.t(`similarity.dropzone.error:${error}`);

            return (
                <span className="label xxl-on text-danger">
                    {label[0]} <span>{label[1]}</span>
                </span>
            );
        }

        const label = I18n.t('similarity.dropzone.default');

        return (
            <span className="label xxl-on">
                {label[0]} <span className="text-primary">{label[1]}</span>
            </span>
        );
    }

    renderLabel(file = null) {
        if (file) {
            return (
                <label>
                    <span className="label">monfichier.mp3</span>
                </label>
            );
        }

        return (
            <label htmlFor="file">
                {this.renderDropLabel()}
                <span className="label xl-off">{I18n.t('similarity.browse.0')}</span>
                <span>{I18n.t('similarity.browse.1', { value: 'mp3, aiff, wav' })}</span>
                <span>{I18n.t('similarity.browse.2', { value: this.constructor.maxFileSize })}</span>
            </label>
        );
    }

    render() {
        const { active, loading } = this.props;

        if (!active) {
            return null;
        }

        return (
            <div className="similarity__box">
                <div className="help-text">
                    <span className="title">{I18n.t('similarity.title')}</span>
                    <p>{I18n.t('similarity.help')}</p>
                </div>
                <div className="file-drop">
                    <input type="file" className="file-drop__input" id="file" onChange={this.onFileChange} />
                    <span className="file-drop__label">
                        {loading ? <Loader/> : <span className="image" />}
                        {this.renderLabel()}
                    </span>
                </div>
            </div>
        );
    }
}

function SimilarityDropBoxWrapped(props, ref) {
    const {setFile} = useSimilarSearchContext();

    const handleSetFile = (file) => {
        setFile(file);
        props.onFile(file);
    };

    return <SimilarityDropBox {...props} setFile={handleSetFile} ref={ref} />;
}

export default forwardRef(SimilarityDropBoxWrapped);
