import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import InputRange from 'react-input-range';
import { formatDuration } from '@App/utils';
import { setDuration } from '@App/store/actions/search';

class DurationRangeSlider extends Component {
    static propTypes = {
        ...InputRange.PropTypes,
        minDuration: PropTypes.number,
        maxDuration: PropTypes.number,
    };

    static defaultProps = {
        minDuration: null,
        maxDuration: null,
        minValue: 0,
        maxValue: 720,
        step: 15,
        allowSameValues: true,
        draggableTrack: true,
        formatLabel: () => '',
    };

    constructor(props) {
        super(props);

        this.state = {
            value: {
                min: props.minDuration || props.minValue,
                max: props.maxDuration || props.maxValue,
            },
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeComplete = this.onChangeComplete.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { minDuration, maxDuration, minValue, maxValue } = this.props;
        const { value } = this.state;

        // App level state duration changed
        if (minDuration !== prevProps.minDuration || maxDuration !== prevProps.maxDuration) {
            const min = minDuration || minValue;
            const max = maxDuration || maxValue;

            if (min !== value.min || max !== value.max) {
                this.setState({ value: { min, max } });
            }
        }
    }

    onChange(value) {
        const { minValue, maxValue } = this.props;

        this.setState({
            value: {
                min: Math.max(value.min, minValue),
                max: Math.min(value.max, maxValue),
            }
        });
    }

    onChangeComplete(value) {
        const { minValue, maxValue } = this.props;
        const { min, max } = value;

        this.props.setDuration(
            min > minValue ? min : null,
            max < maxValue ? max : null
        );
    }

    render() {
        const { minValue, maxValue, step, allowSameValues, draggableTrack } = this.props;
        const { value } = this.state;

        return (
            <Fragment>
                <span className="filters__label">
                    {I18n.t('search.advanced_filters.duration.label')}
                    <span>{formatDuration(value.min)} - {formatDuration(value.max)}</span>
                </span>
                <InputRange
                    value={value}
                    minValue={minValue}
                    maxValue={maxValue}
                    step={step}
                    allowSameValues={allowSameValues}
                    draggableTrack={draggableTrack}
                    onChange={this.onChange}
                    onChangeComplete={this.onChangeComplete}
                />
            </Fragment>
        );
    }
}

export default connect(
    state => ({
        minDuration: state.search.minDuration,
        maxDuration: state.search.maxDuration,
    }),
    dispatch => ({
        setDuration: (min, max) => dispatch(setDuration(min, max)),
    })
)(DurationRangeSlider);
