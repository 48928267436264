import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';

export default class SimilarityInput extends Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onClear: PropTypes.func.isRequired,
        value: PropTypes.string,
        children: PropTypes.node,
    };

    static defaultProps = {
        value: null,
        children: null,
        onSubmit: null,
    };

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(event) {
        event.preventDefault();

        const { onSubmit } = this.props;

        if (typeof onSubmit === 'function') {
            onSubmit();
        }
    }

    renderInput() {
        const { value, onChange } = this.props;

        return (
            <input
                type="text"
                id="similarity"
                placeholder={I18n.t('similarity.placeholder')}
                onChange={onChange}
                value={value || ''}
                autoComplete="off"
            />
        );
    }

    render() {
        const { value, onClear, children } = this.props;

        return (
            <form onSubmit={this.onSubmit}>
                <div className="pulse">
                    <span className="pulse__bar pulse__bar--small" />
                    <span className="pulse__bar pulse__bar--medium" />
                    <span className="pulse__bar pulse__bar--large" />
                    <span className="pulse__bar pulse__bar--medium" />
                    <span className="pulse__bar pulse__bar--small" />
                </div>
                <label htmlFor="searchbar" className="sr-only">
                    {I18n.t('similarity.placeholder')}
                </label>
                {children || this.renderInput()}
                {value ? <button type="button" onClick={onClear}>
                    <i className="icon icon--clear" aria-hidden="true" />
                    <span className="sr-only">{I18n.t('similarity.clear')}</span>
                </button> : null}
            </form>
        );
    }
}
