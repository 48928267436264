import I18n from 'i18n-js';
import DownloadPlaylist from '@App/api/mutation/DownloadPlaylist.graphql';
import DownloadButton, { DownloadButton as DownloadButtonInternal } from '@App/components/download/DownloadButton';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';

interface Props {
  playlistId: string
  format?: string
}

function getLabel(status, detail) {
  if (status === 'success') {
    return <>
      <i key="icon" className="icon icon--download" />
      {I18n.t('panel.download-playlist.label.success')}
    </>;
  }

  if (status === 'pending' && detail && detail.maxStep) {
    const { current, maxStep } = detail;

    return <>
      <i key="icon" className="icon icon--more"></i>
      {I18n.t('panel.download-playlist.label.pending_with_progress', { current, maxStep })}
    </>;
  }

  return <>
    <i key="icon" className="icon icon--more"></i>
    {I18n.t(`panel.download-playlist.label.${status}`)}
  </>;
}

export default function DownloadPlaylistButton({
  playlistId,
  format,
}: Props) {
  const [downloadMutation, { data, loading }] = useMutation(DownloadPlaylist);

  const onClick = async function() {
    downloadMutation({ variables: { id: playlistId, payload: { format } }});
  };

  const isValid = playlistId !== null && format !== null;


  const disabled = !isValid;
  const className = (status: string|null) => classNames('btn', status === 'success' ? 'btn--success--full' : ['btn--primary', 'disabled']);

  if (data?.downloadPlaylist.id) {
    return <DownloadButton id={data?.downloadPlaylist.id} className={className} label={getLabel} />;
  }

  if (loading) {
    return <DownloadButtonInternal status={'init'} className={className} label={getLabel} />;
  }

  return (
    <button type="button" className={classNames('btn btn--primary', { disabled })} onClick={onClick} disabled={disabled}>
      <i className="icon icon--download"></i>
      {I18n.t('panel.download-playlist.title')}
    </button>
  );
}
