import { createElement } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import MutationContent from '@App/components/api/MutationContent';

/**
 * Call a mutation from the API and map its result to the given component
 */
export default function Mutation(props) {
    const {
        mutation,
        component,
        mapResult,
        mapLoading,
        mapError,
        childProps,
        loadingComponent,
        errorComponent,
    } = props;
    const [mutate, { loading, error, data }] = useMutation(mutation);

    return createElement(
        MutationContent,
        {
            loading,
            error,
            data,
            mutate: (variables, update) => mutate({ variables, update }),
            component,
            mapResult,
            mapLoading,
            mapError,
            childProps,
            loadingComponent,
            errorComponent,
        }
    );
}

Mutation.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    mutation: PropTypes.object.isRequired,
    /** A callable with (data) args called on success and returning components props. */
    mapResult: PropTypes.func,
    /** A callable called on loading and returning components props. */
    mapLoading: PropTypes.func,
    /** A callable with (error, data) args called on error and returning components props. */
    mapError: PropTypes.func,
    childProps: PropTypes.object,
    loadingComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    errorComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

Mutation.defaultProps = {
    mapResult: null,
    mapLoading: null,
    mapError: null,
    childProps: {},
    loadingComponent: undefined,
    errorComponent: undefined,
};
