import PropTypes from 'prop-types';

WriterList.propTypes = {
    writers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
    })),
    maxDisplayed: PropTypes.number.isRequired,
};

WriterList.defaultProps = {
    writers: [],
    maxDisplayed: 3,
};

function WriterList({ writers, maxDisplayed }) {
    const allWritersAsString = writers.map((writer) => writer.displayName).join(', ');

    if (writers.length <= maxDisplayed) {
        return <span>{ allWritersAsString }</span>;
    }

    const displayedWriters = writers.slice(0, maxDisplayed).map((writer) => writer.displayName).join(', ').concat(' ...');

    return <span data-tooltip={allWritersAsString} className="tooltip">{ displayedWriters }</span>;
}

export default WriterList;
