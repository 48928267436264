import I18n from 'i18n-js';
import OptionItem from '@App/components/ui/OptionItem';
import IncludeTagButton from '@App/components/search/tags/IncludeTagButton';

export default function IncludeTagOptionItem(props) {
    return <IncludeTagButton
        {...props}
        component={OptionItem}
        icon="add"
        className="options__item--include"
        label={I18n.t('search.tag.include')}
    />;
}
