import I18n from 'i18n-js';

export default function LegalNotices(props) {
    const TranslatedContent = I18n.t('Components.LegalNotices');

    return (
        <div className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8 offset-sm-2">
                        <TranslatedContent {...props} />
                    </div>
                </div>
            </div>
        </div>
    );
}
