import PropTypes from 'prop-types';

/**
 * A cart item (a track)
 */
const CartItem = PropTypes.shape({
    reference: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    cover: PropTypes.string.isRequired,
});

const Cart = {
    CartItem,
};

export default Cart;
