import BlockTitle from '@App/components/layout/BlockTitle';
import PropTypes from 'prop-types';
import Query from '@App/components/api/Query';
import GetMe from '@App/api/query/GetMe.graphql';
import { redirectToProfileSubscription } from '@App/routes';
import useAuth from '@App/common/hooks/useAuth';
import CountryNotAllowed from '@App/components/page/error/CountryNotAllowed';

SubscriptionTable.propTypes = {
    profile: PropTypes.shape({
        id: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        subscription: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }),
    }),
};

function SubscriptionTable({ profile }) {
    const { permissions } = useAuth();

    if (profile.subscription !== null) {
        redirectToProfileSubscription();

        return null;
    }

    if (!permissions.isInAllowedCountry) {
        return <CountryNotAllowed />;
    }

    return <div className="content page-subscription">
        <div className="container-fluid">
            <BlockTitle title="Nos abonnements pour les indépendants & les professionnels"/>
            <p className="text-center">Accédez aisément à la plus vaste librairie musicale destinée à la synchronisation pour vos projets audiovisuels.</p>

            <div className="pricing-table">
                <stripe-pricing-table
                    pricing-table-id={window.STRIPE_PRICING_TABLE_ID}
                    publishable-key={window.STRIPE_PUBLIC_KEY}
                    client-reference-id={ profile.id }
                    customer-email={ profile.email }
                >
                </stripe-pricing-table>
            </div>
        </div>
    </div>;
}

export default function SubscriptionTableQuery() {
    return <Query component={SubscriptionTable} query={GetMe} mapResult={data => ({ profile: data.me })}/>;
}
