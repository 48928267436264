import I18n from 'i18n-js';
import PropTypes from 'prop-types';

function Label(props) {
    const { forId, children, required, className } = props;

    return (
        <label htmlFor={forId} className={className}>
            {children}
            {required &&
                <span className="required">
                    <span className="sr-only">{I18n.t('form.label.required')}</span>
                </span>
            }
        </label>
    );
}

Label.propTypes = {
    children: PropTypes.node.isRequired,
    forId: PropTypes.string,
    className: PropTypes.string.isRequired,
    required: PropTypes.bool,
};

Label.defaultProps = {
    forId: null,
    className: 'form__label',
    required: false,
};

export default Label;
