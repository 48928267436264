import I18n from 'i18n-js';
import { CART } from '@App/routes';
import Link from '@App/components/navigation/Link';
import { useCart } from '@App/api/hooks/useCart';
import PropTypes from 'prop-types';

MyCartButton.propTypes = {
    asDropdownItem: PropTypes.bool,
};

MyCartButton.defaultProps = {
    asDropdownItem: false,
};

/**
 * Header nav button to link to a cart & show nb of items
 */
export default function MyCartButton({ asDropdownItem }) {
    const { count } = useCart();

    if (asDropdownItem) {
        return <Link className="link relative" route={CART.name}>
            {I18n.t('menu.authenticated.cart.label', { count })}
        </Link>;
    }

    return <Link className="link badge--cart" data-count={count} route={CART.name}>
        <i className="icon icon--cart"></i>
        <span className="sr-only">{I18n.t('menu.authenticated.cart.sr-only')}</span>
    </Link>;
}
