import { useMemo } from 'react';
import PropTypes from 'prop-types';
import BlockTitle from '@App/components/layout/BlockTitle';
import I18n from 'i18n-js';
import { HUBSPOT_PAGES } from '@App/routes';
import HubspotLink from '@App/components/navigation/HubspotLink';
import { useOrder } from '@App/api/hooks/useOrders';
import Loader from '@App/components/ui/Loader';
import OrderDetailsList from '@App/components/page/order/OrderDetailsList';
import OrderStatus from '@App/model/order/OrderStatus';

OrderDetails.propTypes = {
    id: PropTypes.string.isRequired,
};

export default function OrderDetails({ id }) {
    const { order, loading, error } = useOrder(id);

    const tracks = useMemo(() => {
        if (loading || error) {
            return [];
        }

        return (order.items || []).map(({ track, licenseFile }) => {
            return {
                reference: track.reference,
                title: track.title,
                // Alias cover field
                cover: track.album.coverAsThumbnail,
                licenseFile,
            };
        });

    }, [loading, error, order]);

    const orderStatus = order?.status;
    const isPending = orderStatus === OrderStatus.PENDING;
    const isPaid = OrderStatus.isPaid(orderStatus);

    return <div className="content page-cart">
        <div className="container-fluid">
            <BlockTitle title={I18n.t('page.order.title', {
                orderRef: order?.invoiceNumber ?? id,
            })}/>

            {loading && <Loader/>}
            {!loading && error && <div className="alert alert--error">{I18n.t('common.errors.loading_failed')}</div>}
            {!loading && !error && <>
                <div className="card">
                    <div className="licence-type licence-type--form">
                        <div className="licence-type__main">
                            <div className="licence-type__details">
                                <div className="details">
                                    <span className="title">{I18n.t('page.order.subtitle', { date: I18n.l('date.formats.short', order.createdAt) })}</span>
                                    <span>{I18n.t('page.order.license', { licenseName: order.license.name })}</span>
                                    <span>{I18n.t('page.order.project', { projectName: order.projectName })}</span>
                                </div>
                            </div>
                            {isPaid && order.invoiceUrl && <div className="float-right">
                                <a className="btn btn--primary" href={order.invoiceUrl} target="_blank" rel="noreferrer">
                                    {I18n.t('page.order.actions.showInvoice')}
                                </a>
                            </div>}
                        </div>
                    </div>
                </div>
                {isPending && <div className="alert alert--error alert--licence" dangerouslySetInnerHTML={{
                    __html: I18n.t('page.order.status.disclaimer.PENDING'),
                }}/>}

                <OrderDetailsList tracks={tracks} canDownload={isPaid} />
            </>}
            <div>
                <span className="mention" dangerouslySetInnerHTML={{
                    __html: I18n.t('page.order.help', {
                        faqUrl: HubspotLink.getUrl(HUBSPOT_PAGES.FAQ),
                        contactUrl: HubspotLink.getUrl(HUBSPOT_PAGES.CONTACT),
                    })
                }} />
            </div>

            <div className="card">
                <div className="licence-type licence-type--form licence-type--outline">
                    <span className="title">{I18n.t('page.order.cueSheet.title')}</span>
                    <p className="mention">
                        {I18n.t('page.order.cueSheet.description')}
                    </p>
                    {/* TODO: wire PDF link */}
                    <a className="btn" target="_blank" href="https://www.musique-music.com/hubfs/Page%20de%20site%20web/App/CueSheet.xlsx" rel="noreferrer">
                        {I18n.t('page.order.cueSheet.actions.download')}
                    </a>
                </div>
            </div>
        </div>
    </div>;
}
