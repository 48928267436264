import BreadCrumb from '@App/components/navigation/BreadCrumb';
import I18n from 'i18n-js';
import LabelList from '@App/components/model/label/LabelList';

export default function Labels() {
    return (
        <div className="content">
            <div className="container-fluid">
                <BreadCrumb dark />
                <section id="labels" className="thumblist">
                    <div className="thumblist__title">
                        <h1 className="h1">{I18n.t('page.library.labels.title')}</h1>
                    </div>
                    <LabelList />
                </section>
            </div>
        </div>
    );
}
