import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Link from '@App/components/navigation/Link';
import { ALBUM } from '@App/routes';

export default function ShowAlbumButton(props) {
    const { albumReference, ...childProps } = props;

    return (
        <Link {...childProps} route={ALBUM.name} parameters={{ reference: albumReference }} noFollow>
            {I18n.t('model.track.actions.show')}
        </Link>
    );
}

ShowAlbumButton.propTypes = {
    albumReference: PropTypes.string.isRequired,
};
