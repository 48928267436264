import { Component } from 'react';
import PropTypes from 'prop-types';
import SeeAllButton from '@App/components/search/SeeAllButton';

export default class ResultBoxSection extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        children: PropTypes.node.isRequired,
        ...SeeAllButton.propTypes,
    };

    static defaultProps = {
        ...SeeAllButton.defaultProps,
    };

    render() {
        const { label, count, children, onSeeAll } = this.props;

        return (
            <div className="result-box__section">
                <div className="title">
                    <h3>{label} <span>({count})</span></h3>
                    {onSeeAll ? <SeeAllButton onSeeAll={onSeeAll} /> : null}
                </div>
                {children}
            </div>
        );
    }
}
