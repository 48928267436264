import copy from 'copy-to-clipboard';
import { ALBUM, PLAYLIST, PROJECT } from '@App/routes';

export function copyAlbumUrl(reference) {
    copy(url(getAlbumPath(reference)));
}

export function copyProjectUrl(id) {
    copy(url(getProjectPath(id)));
}

export function copyTrackUrl(albumReference, reference) {
    copy(url(getTrackPath(albumReference, reference)));
}

export function copyPlaylistUrl(id, slug) {
    copy(url(getPlaylistPath(id, slug)));
}

/**
 * Get album path
 *
 * @param  {String} reference
 *
 * @return {String}
 */
function getAlbumPath(reference) {
    return ALBUM.getUrl({ reference });
}

/**
 * Get project path
 *
 * @param  {String} id
 *
 * @return {String}
 */
function getProjectPath(id) {
    return PROJECT.getUrl({ id });
}

/**
 * Get track path
 *
 * @param  {String} albumReference
 * @param  {String} reference
 *
 * @return {String}
 */
function getTrackPath(albumReference, reference) {
    return `${getAlbumPath(albumReference)}#${reference}`;
}

/**
 * Get playlist path
 *
 * @param  {String} slug
 *
 * @return {String}
 */
function getPlaylistPath(id, slug) {
    return PLAYLIST.getUrl({ id, slug });
}

/**
 * Get absolute url for the given path
 *
 * @param  {String} path
 *
 * @return {String}
 */
function url(path) {
    const { origin } = window.location;

    return `${origin}${path}`;
}
