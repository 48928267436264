import { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import SidePanel, { SidePanelSection } from '@App/components/layout/SidePanel';
import FormatSelector from '@App/components/form/FormatSelector';
import DownloadProjectButton from '@App/components/download/DownloadProjectButton';

export default function DownloadProjectPanel ({
    onClose,
    withPlayer,
    projectId,
}) {

    const [format, setFormat] = useState(FormatSelector.FORMATS[0]);

    function renderFooter() {
        return <DownloadProjectButton
            projectId={projectId}
            format={format}
        />;
    }

    return <SidePanel
        id="download-panel"
        direction="right"
        title={I18n.t('panel.download-project.title')}
        onClose={onClose}
        withPlayer={withPlayer}
        footer={renderFooter()}
        active
    >
        <SidePanelSection title={I18n.t('panel.download-project.format')}>
            <FormatSelector value={format} onChange={setFormat} />
        </SidePanelSection>
    </SidePanel>;
}

DownloadProjectPanel.propTypes = {
    onClose: PropTypes.func.isRequired,
    withPlayer: PropTypes.bool.isRequired,
    projectId: PropTypes.string.isRequired,
};
