import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openSelectProjectPanel } from '@App/store/actions/project';
import Button from '@App/components/ui/Button';

const InheritedProps = { ...Button.propTypes };
delete InheritedProps.onClick;

AddToProjectButton.propTypes = {
    ...InheritedProps,
    trackReference: PropTypes.string.isRequired,
    versionReference: PropTypes.string.isRequired,
    searchResultsPosition: PropTypes.number,
};

/**
 * Opens the SelectProjectPanel when clicked to choose a project to add a track to.
 */
function AddToProjectButton({
    label,
    trackReference,
    versionReference,
    searchResultsPosition,
    ...props
}) {
    const dispatch = useDispatch();

    function onClick() {
        dispatch(openSelectProjectPanel(trackReference, versionReference, searchResultsPosition));
    }

    return <Button label={label} onClick={onClick}{...props} />;
}

export default AddToProjectButton;
