import PropTypes from 'prop-types';
import BreadCrumbItem from '@App/components/navigation/BreadCrumbItem';
import LoadingBreadCrumbItem from '@App/components/navigation/LoadingBreadCrumbItem';
import GetLabelName from '@App/api/query/GetLabelName.graphql';
import Query from '@App/components/api/Query';
import { LABEL } from '@App/routes';

export default function LabelBreadCrumbItem(props) {
    const { reference, slug } = props;

    return <Query
        component={BreadCrumbItem}
        loadingComponent={LoadingBreadCrumbItem}
        query={GetLabelName}
        variables={{ reference }}
        mapResult={data => ({ label: data.label.name })}
        childProps={{
            route: LABEL.name,
            parameters: { reference, slug }
        }}
    />;
}

LabelBreadCrumbItem.propTypes = {
    reference: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
};
