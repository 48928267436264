import MyProjects from '@App/api/query/MyProjects.graphql';
import MyCart from '@App/api/query/cart/MyCart.graphql';
import { ApolloCacheUtils } from '@App/utils/apollo';

/**
 * Update Apollo store on new project
 */
export function onAddProject(store, project) {
    const data = store.readQuery({ query: MyProjects });

    store.writeQuery({
        query: MyProjects,
        data: {
            ...data,
            myProjects: data.myProjects.concat(project),
        },
    });
}

/**
 * Update Apollo store on follow project
 */
export function onFollowProject(store, project) {
    const data = store.readQuery({ query: MyProjects });

    store.writeQuery({
        query: MyProjects,
        data: {
            ...data,
            followedProjects: data.followedProjects.concat(project),
        },
    });
}

/**
 * Update Apollo store on unfollow project
 */
export function onUnfollowProject(store) {
    const data = store.readQuery({ query: MyProjects });

    store.writeQuery({
        query: MyProjects,
        data: {
            ...data,
            followedProjects: data.followedProjects.filter(project => project.followed),
        },
    });
}

/**
 * Update Apollo store on adding a track to cart
 */
export function onAddTrackToCart(store, newData) {
    updateCartWithNewData(store, newData);
}

/**
 * Update Apollo store on removing a track from cart
 */
export function onRemoveTrackFromCart(store, newData) {
    updateCartWithNewData(store, newData);
}

function updateCartWithNewData(store, newData) {
    store.writeQuery({
        query: MyCart,
        data: {
            cart: newData,
        },
    });
}

/**
 * Clear cart on submit
 */
export function onSubmitCart(store) {
    store.writeQuery({
        query: MyCart,
        data: {
            cart: [],
        },
    });
}


/**
 * Update Apollo store on new licence request
 */
export function onAddLicenceRequest(cache, data) {
    // Purge MyLicenceRequests query (easier than adding the item due to pagination)
    ApolloCacheUtils.apolloPurgeQuery('licenceRequests')(cache, data);
}
