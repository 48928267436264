import PropTypes from 'prop-types';

export default PropTypes.shape({
    reference: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    coverAsLargeThumbnail: PropTypes.string.isRequired,
    nbTracks: PropTypes.number.isRequired,
    label: PropTypes.shape({
        reference: PropTypes.string,
        name: PropTypes.string
    }).isRequired
});
