/**
 * Format an integer to a FR format.
 * Cannot use toLocaleString or Intl.NumberFormat, since it has to work the same way
 */
export function formatInteger(number) {
    return String(number).replace(/(.)(?=(\d{3})+$)/g, '$1 ');
}


export function formatCurrency(cents, currency = 'EUR') {
    return Intl.NumberFormat(undefined, {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
    }).format(cents / 100);
}


export function formatEur(cents) {
    return formatCurrency(cents, 'EUR');
}

/**
 * @param {Number} percent As percent (i.e: 20.0 => 20%)
 * @param {Number} asFraction or pass true to directly pass as fraction (i.e: 20% => 0.2)
 */
export function formatPercent(percent, asFraction= false) {
    return Intl.NumberFormat(undefined, {
        style: 'percent',
        maximumFractionDigits: 2,
    }).format(asFraction ? percent : percent / 100);
}
