import PropTypes from 'prop-types';
import { HUBSPOT_PAGES, SUBSCRIPTION } from '@App/routes';
import I18n from 'i18n-js';
import HubspotLink from '@App/components/navigation/HubspotLink';
import Anchor from '@App/components/navigation/Anchor';
import { connect } from 'react-redux';

SubscriptionInfo.propTypes = {
    active: PropTypes.bool.isRequired,
    profile: PropTypes.shape({
        subscription: PropTypes.shape({
            name: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            startsAt: PropTypes.string.isRequired,
            finishesAt: PropTypes.string,
            nextOccurrenceAt: PropTypes.string,
            manageUrl: PropTypes.string,
            cancelSubscriptionUrl: PropTypes.string,
        }),
    }),
};

function SubscriptionInfo({ active, profile }) {
    const subscription = profile.subscription;

    if (subscription === null) {
        return <NoSubscription />;
    }

    return <Anchor active={active} id="abonnement">
        <h2 className="h1">{I18n.t('page.my_account.subscription.title')}</h2>
        <hr/>

        <h3>Abonnement</h3>
        <p>{ subscription.name }</p>
        <p>{ subscription.status } (
            {subscription.finishesAt && <>Fin le { I18n.l('date.formats.short', subscription.finishesAt) } </>}
            {!subscription.finishesAt && subscription.nextOccurrenceAt && <>Renouvellement le { I18n.l('date.formats.short', subscription.nextOccurrenceAt) }</>}
        )</p>
        <p>{ subscription.manageUrl && <a href={subscription.manageUrl} className="btn btn--primary btn--small">Voir mes factures</a> }</p>
        <p>Une question sur votre abonnement ? <HubspotLink route={HUBSPOT_PAGES.CONTACT}>Contactez-nous !</HubspotLink></p>
        { subscription.manageUrl && !subscription.finishesAt &&
            <a href={subscription.cancelSubscriptionUrl} className="link link--danger">Résilier mon contrat</a>
        }
    </Anchor>;
}

function NoSubscription() {
    return <div>
        <h2 className="h1">{I18n.t('page.my_account.subscription.title')}</h2>
        <hr/>
        <div className="row">
            <div className="col-lg-3 offset-lg-4">
                <a href={SUBSCRIPTION.getUrl()} className="btn btn--primary btn--full">Choisir mon abonnement</a>
            </div>
        </div>
    </div>;
}

export default connect(
    state => ({
        active: state.navigation.anchor === 'abonnement'
    })
)(SubscriptionInfo);
