import types from '@App/types';
import RemoveTrackFromCartButton from '@App/components/page/cart/RemoveTrackFromCartButton';
import PropTypes from 'prop-types';

CartList.propTypes = {
    tracks: PropTypes.arrayOf(types.Cart.CartItem.isRequired).isRequired,
    PriceComponent: PropTypes.node,
};
CartList.defaultProps = {
    priceComponent: null,
};

function CartList({ tracks, PriceComponent }) {
    return <div className="card">
        {tracks.map(track => <CartRow track={track} key={track.reference} PriceComponent={PriceComponent}/>)}
    </div>;
}

CartRow.propTypes = {
    track: types.Cart.CartItem.isRequired,
    PriceComponent: PropTypes.node,
};

CartRow.defaultProps = {
    PriceComponent: null,
};

function CartRow({ track, PriceComponent }) {
    const { reference, title, cover } = track;

    return <div className="track track--cart">
        <div className="track__main">
            <div className="track__details">
                <div className="cover" style={{ backgroundImage: `url(' ${cover}')` }} title={title}></div>
                <div className="details">
                    <span className="title">{title}</span>
                    <span className="info">{reference}</span>
                </div>
            </div>
            <div className="track__actions">
                {PriceComponent && <div className="price">
                    {PriceComponent}
                </div>}
                <RemoveTrackFromCartButton className="link link--light" reference={reference} />
            </div>
        </div>
    </div>;
}

export default CartList;
