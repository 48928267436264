import { Fragment } from 'react';
import PropTypes from 'prop-types';

export default function Nl2br({
    content
}) {
    if (null === content) {
        return;
    }

    return (
        <Fragment>
            {content.split('\n').map((paragraph, key) => {
                return <span key={key}>{paragraph}<br/></span>;
            })}
        </Fragment>
    );
}

Nl2br.propTypes = {
    content: PropTypes.string.isRequired
};
