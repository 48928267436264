import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import { showVersionsPanel } from '@App/store/actions/track';
import Button from '@App/components/ui/Button';

class TrackVersionsButton extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        count: PropTypes.number.isRequired,
    };

    render() {
        const { count, onClick } = this.props;

        if (!count) {
            return null;
        }

        if (1 === count) {
            return null;
        }

        const label = I18n.t('model.track.versions', { count });

        return <Button onClick={onClick} label={label} className="link link--primary" />;
    }
}

const TrackVersionsButtonWrapper = connect(
    null,
    (dispatch, props) => ({
        onClick: () => dispatch(showVersionsPanel(props.trackReference))
    })
)(TrackVersionsButton);

TrackVersionsButtonWrapper.propTypes = {
    trackReference: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
};

export default TrackVersionsButtonWrapper;
