import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import SidePanel, { SidePanelSection } from '@App/components/layout/SidePanel';
import { LOGIN } from '@App/routes';

ForbiddenPanel.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    translationKeyPrefix: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    authenticated: PropTypes.bool.isRequired,
    versionReference: PropTypes.string,
};

/**
 * Guard access to a feature panel about a version depending on if the user is authenticated or not.
 */
export default function ForbiddenPanel({
    id,
    title,
    translationKeyPrefix,
    onClose,
    authenticated,
    versionReference,
}) {
    function renderLogin() {
        return <p>
            <span>{I18n.t(`${translationKeyPrefix}.sign-in.title`)}</span> :&nbsp;
            <a href={LOGIN.getUrl()}>{I18n.t(`${translationKeyPrefix}.sign-in.action.login`)}</a>.
        </p>;
    }

    function renderMissingPermission() {
        return <div>
            <div className="alert alert--brand">
                <strong>{I18n.t(`${translationKeyPrefix}.missing-permission.title`)}</strong>
            </div>
        </div>;
    }

    return <SidePanel
        id={`${id}-panel-forbidden`}
        direction="right"
        title={title}
        onClose={onClose}
        active={versionReference !== null}
    >
        <SidePanelSection>
            {authenticated ? renderMissingPermission() : renderLogin()}
        </SidePanelSection>
    </SidePanel>;
}
