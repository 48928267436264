import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import classnames from 'classnames';
import Button from '@App/components/ui/Button';
import DurationRangeSlider from '@App/components/search/advanced/DurationRangeSlider';
import LevelTwoTagFilter from './LevelTwoTagFilter';
import LabelsFilter from './LabelsFilter';

export default class Filters extends Component {
    static propTypes = {
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            }).isRequired
        ),
        loading: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            tab: null,
        };

        this.onTab = this.onTab.bind(this);
        this.renderTagButton = this.renderTagButton.bind(this);
    }

    onTab(event) {
        const { tab } = this.state;
        const id = event.currentTarget.value;

        this.setState({ tab: tab === id ? null : id });
    }

    renderAdvancedFiltersButton() {
        const active = this.state.tab === 'advanced';

        return (
            <li key="advanced" className={classnames('filters__item', { active })}>
                <Button label={I18n.t('search.advanced_filters.label')} value="advanced" onClick={this.onTab} className="" />
            </li>
        );
    }

    renderLabelsButton() {
        const active = this.state.tab === 'labels';

        return (
            <li key="labels" className={classnames('filters__item', { active })}>
                <Button label={I18n.t('search.labels.label')} value="labels" onClick={this.onTab} className="" />
            </li>
        );
    }

    renderTagButton(tag) {
        const { id, name } = tag;
        const active = this.state.tab === id;

        return (
            <li key={`${id}-item`} className={classnames('filters__item', { active })}>
                <Button key={`${id}-button`} label={name} onClick={this.onTab} value={id} className="" />
            </li>
        );
    }

    renderContent() {
        const { tab } = this.state;

        if (!tab) {
            return null;
        }

        switch (tab) {
            case 'advanced':
                return (
                    <ul className="filters__column filters__column--large">
                        <li className="filters__item">
                            <DurationRangeSlider />
                        </li>
                    </ul>
                );

            case 'labels':
                return <LabelsFilter className="filters__column filters__column--large" />;

            default:
                return <LevelTwoTagFilter id={tab} className="filters__column filters__column--large" />;
        }
    }

    render() {
        const { tags, loading } = this.props;

        if (loading) {
            return <ul key="level-1" className="filters__column loading" />;
        }

        return (
            <Fragment>
                <ul className="filters__column">
                    {tags.map(this.renderTagButton)}
                    {this.renderLabelsButton()}
                    {this.renderAdvancedFiltersButton()}
                </ul>
                {this.renderContent()}
            </Fragment>
        );
    }
}
