import PropTypes from 'prop-types';

export default {
    /**
     * A license to select on a cart for ordering.
     */
    License: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        /** Price per track in cents */
        pricePerTrack: PropTypes.number.isRequired,
        /** Tax rate as percent */
        taxRate: PropTypes.number.isRequired,
    }),
};
