import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';

export default class SimilaritySearchButton extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
    };

    render() {
        const { onClick } = this.props;

        return (
            <button type="button" className="searchbar__button similarity-button" onClick={onClick}>
                <div className="pulse">
                    <span className="pulse__bar pulse__bar--small" />
                    <span className="pulse__bar pulse__bar--medium" />
                    <span className="pulse__bar pulse__bar--large" />
                    <span className="pulse__bar pulse__bar--medium" />
                    <span className="pulse__bar pulse__bar--small" />
                </div>
                {I18n.t('similarity.label')}
            </button>
        );
    }
}
