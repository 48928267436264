import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import GetTrackTags from '@App/api/query/GetTrackTags.graphql';
import Query from '@App/components/api/Query';
import Button from '@App/components/ui/Button';
import IncludeTagButton from '@App/components/search/tags/IncludeTagButton';
import IncludeTagOptionItem from '@App/components/search/tags/IncludeTagOptionItem';
import ExcludeTagOptionItem from '@App/components/search/tags/ExcludeTagOptionItem';
import Loader from '@App/components/ui/Loader';

class TrackTags extends Component {
    static propTypes = {
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            })
        ),
    };

    static defaultProps = {
        tags: null,
    };

    constructor(props) {
        super(props);

        this.renderTag = this.renderTag.bind(this);
    }

    renderTag(tag) {
        const { id, name } = tag;

        return (
            <li key={id} className="track-tag">
                <div className="track-tag-button">
                    <IncludeTagButton id={id} component={Button} label={name} className="tag-button" />
                    <div className="options">
                        <IncludeTagOptionItem id={id} />
                        <ExcludeTagOptionItem id={id} />
                    </div>
                </div>
            </li>
        );
    }

    render() {
        const { tags } = this.props;

        return (
            <div className="track-tag-list--container">
                <span className="icon icon--tag" />
                <ul className="track-tag-list">
                    {tags ? tags.map(this.renderTag) : <li><Loader /></li>}
                </ul>
            </div>
        );
    }
}

function errorComponent() {
    return <p className="error">{I18n.t('common.errors.loading_failed')}</p>;
}

function mapResult(data) {
    const { trackTags } = data;

    if (typeof trackTags === 'undefined') {
        return { tags: null };
    }

    return { tags: trackTags };
}

export default function TrackTagsWrapper(props) {
    const { trackReference } = props;

    return <Query
        component={TrackTags}
        loadingComponent={TrackTags}
        errorComponent={errorComponent}
        query={GetTrackTags}
        mapResult={mapResult}
        variables={{ reference: trackReference }}
    />;
}


TrackTagsWrapper.propTypes = {
    trackReference: PropTypes.string.isRequired,
};
