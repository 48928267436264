import I18n from 'i18n-js';
import TrackMoreButton from '@App/components/model/track/TrackMoreButton';
import RemoveFromProjectButton from '@App/components/model/track/RemoveFromProjectButton';
import ReportAProblemOnAdminButton from '@App/components/model/track/ReportAProblemOnAdminButton';
import TrackDownloadButton from '@App/components/model/track/TrackDownloadButton';
import ShareTrackButton from '@App/components/model/share/ShareTrackButton';
import ShowAlbumButton from '@App/components/model/track/ShowAlbumButton';
import SearchSimilarTrackButton from '@App/components/model/track/SearchSimilarTrackButton';
import TrackInformationsButton from '@App/components/model/track/TrackInformationsButton';
import Button from '@App/components/ui/Button';
import Dropover from '@App/components/ui/Dropover';
import SeeTrackOnAdminButton from '@App/components/model/track/SeeTrackOnAdminButton';
import AddTrackToCartButton from '@App/components/cart/AddTrackToCartButton';
import AddToBriefButton from '@App/components/model/track/AddToBriefButton';
import AddToProjectButton from '@App/components/model/track/AddToProjectButton';
import { useAppSelector } from '@App/store';

interface Props {
  versionReference: string
  trackReference: string
  albumReference: string
  mobile: boolean
  projectId?: string
  up?: boolean
  searchResultsPosition?: boolean
  availableForSimilarSearch: boolean
  onShowTags: () => void
  showTags?: boolean
  adminEditUrl?: string
}

interface InnerProps extends Props {
  authenticated: boolean
  canDownload: boolean
  canDownloadWithWatermark: boolean
  canManageBrief: boolean
}

export default function TrackButtonBar ({
  versionReference,
  trackReference,
  albumReference,
  mobile,
  projectId,
  up,
  searchResultsPosition,
  availableForSimilarSearch,
  onShowTags,
  showTags,
  adminEditUrl,
}: Props) {
  const authenticated = useAppSelector(state => state.authentication.id !== null);
  const canDownload = useAppSelector(state => state.authentication?.permissions?.canDownload === true);
  const canDownloadWithWatermark = useAppSelector(state => state.authentication?.permissions?.canDownloadWithWatermark === true);
  const canManageBrief = useAppSelector(state =>  state.authentication?.permissions?.canManageBrief === true);

  if (mobile) {
    return <TrackButtonBarMobile
      authenticated={authenticated}
      canDownload={canDownload}
      canDownloadWithWatermark={canDownloadWithWatermark} 
      canManageBrief={canManageBrief}
      versionReference={versionReference}
      trackReference={trackReference}
      albumReference={albumReference}
      mobile={mobile}
      projectId={projectId}
      up={up}
      searchResultsPosition={searchResultsPosition}
      availableForSimilarSearch={availableForSimilarSearch}
      onShowTags={onShowTags}
      showTags={showTags}
      adminEditUrl={adminEditUrl}
    />;
  }

  return <TrackButtonBarDesktop
    authenticated={authenticated}
    canDownload={canDownload}
    canDownloadWithWatermark={canDownloadWithWatermark}
    canManageBrief={canManageBrief}
    versionReference={versionReference}
    trackReference={trackReference}
    albumReference={albumReference}
    mobile={mobile}
    projectId={projectId}
    up={up}
    searchResultsPosition={searchResultsPosition}
    availableForSimilarSearch={availableForSimilarSearch}
    onShowTags={onShowTags}
    showTags={showTags}
    adminEditUrl={adminEditUrl}
  />;
}

function TrackButtonBarDesktop({
  albumReference,
  trackReference,
  versionReference,
  searchResultsPosition,
  projectId,
  canDownload,
  canDownloadWithWatermark,
  up,
  availableForSimilarSearch,
  authenticated,
  adminEditUrl,
  canManageBrief,
  onShowTags,
  showTags,
}: InnerProps) {
  const renderProjectButton = () => {
    return (
      <>
        <AddToProjectButton
          trackReference={trackReference}
          versionReference={versionReference}
          searchResultsPosition={searchResultsPosition}
          label={ I18n.t('model.track.actions.addToProjects')}
          tooltip
          icon="add"
          className="action-button"
        />
        { projectId && <RemoveFromProjectButton
          projectId={projectId}
          versionReference={versionReference}
          icon="trash"
          className="action-button action-button--danger"
          tooltip
        /> }
      </>
    );
  };

  const renderDownloadButton = (asDropDownItem: boolean) => {
    // Si le user n'est pas connecté
    if (!canDownload && !canDownloadWithWatermark) {
      return null;
    }
    // Pour cacher le bouton 'Télécharger' si le user est abonné
    if (!asDropDownItem && canDownloadWithWatermark){
      return null;
    }
    // Pour afficher le bouton 'Télécharger' sur version desktop et droits admin
    if (asDropDownItem && canDownload) {
      return null;
    }
    // Afficher le lien 'Télécharger aperçu' dans la liste dropdown si le user est abonné
    if (asDropDownItem) {
      return <TrackDownloadButton
        trackReference={trackReference}
        versionReference={versionReference}
        className="link link--primary"
        searchResultsPosition={searchResultsPosition}
        label={canDownloadWithWatermark ? I18n.t('model.track.actions.downloadWithWatermark') : null}
      />;
    }

    return <TrackDownloadButton
      trackReference={trackReference}
      versionReference={versionReference}
      tooltip
      icon="download"
      className="action-button action-button--primary"
      searchResultsPosition={searchResultsPosition}
    />;
  };

  return <>
    {availableForSimilarSearch && authenticated
      ? <SearchSimilarTrackButton trackReference={trackReference} asDropDownItem={false} />
      : null
    }
    <Dropover button={TrackMoreButton} up={up}>
      <ShareTrackButton albumReference={albumReference} trackReference={trackReference} className="link link--light" />
      <ShowAlbumButton albumReference={albumReference} className="link link--light" />
      <ShowTagsButton onShowTags={onShowTags} showTags={showTags} />
      <TrackInformationsButton trackReference={trackReference} versionReference={versionReference} className="link link--light" />
      {renderDownloadButton(true)}
      <AddTrackToCartButton
        reference={trackReference}
        asDropDownItem={true}
        className="link link--primary"
      />
      {canManageBrief && <AddToBriefButton
        label={ I18n.t('model.track.actions.addToBrief')}
        trackReference={trackReference}
        versionReference={versionReference}
        className="link link--light"
      />}
      {adminEditUrl && <SeeTrackOnAdminButton adminEditUrl={adminEditUrl} className="link" />}
      {adminEditUrl && <ReportAProblemOnAdminButton adminUrl={adminEditUrl + '?action=report'} className="link" />}
    </Dropover>
    {authenticated ? renderProjectButton() : null }
    {renderDownloadButton(false)}
    {!canDownload ? <AddTrackToCartButton
      reference={trackReference}
      tooltip
      icon="cart"
      className="action-button action-button--primary action-button--cart"
    /> : null}
  </>;
}

function TrackButtonBarMobile({
  albumReference,
  trackReference,
  versionReference,
  searchResultsPosition,
  projectId,
  canDownload,
  canDownloadWithWatermark,
  up,
  availableForSimilarSearch,
  authenticated,
  adminEditUrl,
  canManageBrief,
  onShowTags,
  showTags,
}: InnerProps) {
  const renderProjectButton = () => {
    return (
      <>
        <AddToProjectButton
          trackReference={trackReference}
          versionReference={versionReference}
          searchResultsPosition={searchResultsPosition}
          label={I18n.t('model.track.actions.addToProjects')}
          className="link link--light"
        />
        { projectId && <RemoveFromProjectButton
          projectId={projectId}
          versionReference={versionReference}
          label={I18n.t('model.track.actions.removeFromProject')}
          className="link link--light"
        /> }
      </>
    );
  };

  const renderDownloadButton = () => {
    // Si le user n'est pas connecté
    if (!canDownload && !canDownloadWithWatermark) {
      return null;
    }

    return <TrackDownloadButton
      trackReference={trackReference}
      versionReference={versionReference}
      className="link link--primary"
      searchResultsPosition={searchResultsPosition}
      label={canDownloadWithWatermark ? I18n.t('model.track.actions.downloadWithWatermark') : null}
    />;
  };

  return <>
    <Dropover button={TrackMoreButton} up={up}>
      {availableForSimilarSearch && authenticated
        ? <SearchSimilarTrackButton trackReference={trackReference} asDropDownItem={true} />
        : null
      }
      <ShareTrackButton albumReference={albumReference} trackReference={trackReference} className="link link--light" />
      <ShowAlbumButton albumReference={albumReference} className="link link--light" />
      <ShowTagsButton onShowTags={onShowTags} showTags={showTags} />
      <TrackInformationsButton trackReference={trackReference} versionReference={versionReference} className="link link--light" />
      {renderDownloadButton()}
      <AddTrackToCartButton
        reference={trackReference}
        asDropDownItem={true}
        className="link link--primary"
      />
      {canManageBrief && <AddToBriefButton
        label={ I18n.t('model.track.actions.addToBrief')}
        trackReference={trackReference}
        versionReference={versionReference}
        className="link link--light"
      />}
      {authenticated ? renderProjectButton() : null }
      {adminEditUrl && <SeeTrackOnAdminButton adminEditUrl={adminEditUrl} className="link" />}
      {adminEditUrl && <ReportAProblemOnAdminButton adminUrl={adminEditUrl + '?action=report'} className="link" />}
    </Dropover>
  </>;
}

interface ShowTagsButtonProps {
  onShowTags?: () => void
  showTags?: boolean
}

function ShowTagsButton({onShowTags, showTags}: ShowTagsButtonProps) {
  if (typeof onShowTags !== 'function') {
    return null;
  }

  return <Button
    onClick={onShowTags}
    label={I18n.t('model.track.actions.' + (showTags ? 'hide_tags' : 'show_tags'))}
    className="link link--light"
  />;
}
