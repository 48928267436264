import { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '@App/components/layout/PageHeader';
import Mutation from '@App/components/api/Mutation';
import EditProject from '@App/api/mutation/EditProject.graphql';
import { GraphQLError } from '@App/utils/proptypes';

class EditableProjectTitle extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        mutate: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        success: PropTypes.bool,
        error: GraphQLError,
    };

    static defaultProps = {
        loading: false,
        success: null,
        error: null,
    };

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        const { mutate, id } = this.props;
        const { innerText: name } = event.currentTarget;

        mutate({ id, payload: { name } });
    }

    render() {
        const { Title } = PageHeader;
        const { name, loading } = this.props;

        return (
            <Title contentEditable={!loading} suppressContentEditableWarning onBlur={this.onChange}>
                {name}
            </Title>
        );
    }
}

export default function EditableProjectTitleWrapper(props) {
    const { id, name, ...childProps } = props;

    return <Mutation
        mutation={EditProject}
        component={EditableProjectTitle}
        loadingComponent={EditableProjectTitle}
        errorComponent={EditableProjectTitle}
        mapResult={() => ({ success: true })}
        mapLoading={() => ({ loading: true, success: null })}
        childProps={{ id, name, ...childProps }}
    />;
}

EditableProjectTitleWrapper.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};
