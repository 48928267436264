import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Button from '@App/components/ui/Button';

export default class TrackMoreButton extends Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: null,
    };

    render() {
        const { onClick, className } = this.props;
        const label = I18n.t('model.track.actions.other');

        return <Button icon="more" label={label} onClick={onClick} tooltip className={[className, 'action-button'].join(' ').trim()} />;
    }
}

