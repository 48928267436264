import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { playTrack } from '@App/store/actions/player';
import { closeVersionsPanel } from '@App/store/actions/track';
import { PANEL_VERSIONS } from '@App/store/reducers/track';
import GetTrackVersionsAndDetails from '@App/api/query/GetTrackVersionsAndDetails.graphql';
import Query from '@App/components/api/Query';
import VersionsPanel from '@App/components/page/VersionsPanel';
import { isPlayerActive } from '@App/store/reducers/player';
import { trackPlayed } from '@App/store/actions/stats';

/**
 * GraphQL wrapped VersionsPanel
 *
 * @see @App/components/container/BackdropContainer to close panel when clicking outside
 * @param {Object} props
 */
function VersionsPanelWrapper({
    trackReference,
    onClose,
    onPlayVersion,
    withPlayer,
}) {
    if (!trackReference) {
        return null;
    }

    return <Query
        component={VersionsPanel}
        query={GetTrackVersionsAndDetails}
        variables={{ reference: trackReference }}
        mapResult={data => data.track}
        childProps={{ onClose, onPlayVersion, withPlayer, trackReference }}
    />;
}

VersionsPanelWrapper.propTypes = {
    onPlayVersion: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    withPlayer: PropTypes.bool.isRequired,
    trackReference: PropTypes.string,
};

VersionsPanelWrapper.defaultProps = {
    trackReference: null,
};

/**
 * Redux drived VersionsPanel
 */
const VersionsPanelWrapperContainer = connect(
    state => ({
        trackReference: state.track.panel === PANEL_VERSIONS ? state.track.trackReference : null,
        withPlayer: isPlayerActive(state),
    }),
    (dispatch, props) => ({
        onClose: () => dispatch(closeVersionsPanel()),
        onPlayVersion: (trackReference, versionReference) => {
            if (props.dispatchTrackPlayedStat) {
                dispatch(trackPlayed(versionReference, false));
            }

            dispatch(playTrack(trackReference, versionReference));
        }
    })
)(VersionsPanelWrapper);

VersionsPanelWrapperContainer.propTypes = {
    dispatchTrackPlayedStat: PropTypes.bool,
};

export default connect((state) => ({
    dispatchTrackPlayedStat: !state.authentication.permissions.isAdmin && !state.authentication.permissions.isStaff,
}))(VersionsPanelWrapperContainer);
