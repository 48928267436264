import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import SidePanel, { SidePanelSection } from '@App/components/layout/SidePanel';
import {REGISTER, LOGIN, HUBSPOT_PAGES} from '@App/routes';
import HubspotLink from '@App/components/navigation/HubspotLink';

export default function DownloadForbiddenPanel ({
    onClose,
    withPlayer,
    authenticated,
}) {

    function renderRegister() {
        return (
            <p>
                <span>{I18n.t('panel.download-forbidden.register.label.0')} </span>
                <a href={REGISTER.getUrl()}>{I18n.t('panel.download-forbidden.register.action.register')}</a>
                <span> {I18n.t('panel.download-forbidden.register.label.1')} </span>
                <a href={LOGIN.getUrl()}>{I18n.t('panel.download-forbidden.register.action.login')}</a>.
            </p>
        );
    }

    function renderMissingPermission() {
        return (
            <div>
                <div className="alert alert--brand">
                    <strong>
                        {I18n.t('panel.download-forbidden.missing-permission.title')}
                    </strong>
                </div>

                <p dangerouslySetInnerHTML={{__html: I18n.t('panel.download-forbidden.missing-permission.subtitle', {url: HubspotLink.getUrl(HUBSPOT_PAGES.RATES) })}} />

                <hr />

                <p>
                    <strong>
                        {I18n.t('panel.download-forbidden.missing-permission.pro.title')}
                    </strong>
                </p>
                <p dangerouslySetInnerHTML={{__html: I18n.t('panel.download-forbidden.missing-permission.pro.description')}}/>

                <hr />

                <p>
                    <strong>
                        {I18n.t('panel.download-forbidden.missing-permission.perso.title')}
                    </strong>
                </p>
                <p dangerouslySetInnerHTML={{__html: I18n.t('panel.download-forbidden.missing-permission.perso.description')}}/>
            </div>
        );
    }

    return <SidePanel
        id="download-panel"
        direction="right"
        title={I18n.t('panel.download-track.title')}
        onClose={onClose}
        withPlayer={withPlayer}
        active
    >
        <SidePanelSection>
            {authenticated ? renderMissingPermission() : renderRegister()}
        </SidePanelSection>
    </SidePanel>;
}

DownloadForbiddenPanel.propTypes = {
    onClose: PropTypes.func.isRequired,
    withPlayer: PropTypes.bool.isRequired,
    authenticated: PropTypes.bool.isRequired,
};
