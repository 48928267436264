import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { get } from '@App/container';
import ShowPlaylist from '@App/api/query/ShowPlaylist.graphql';
import Query from '@App/components/api/Query';
import PageHeader from '@App/components/layout/PageHeader';
import SharePlaylistButton from '@App/components/model/share/SharePlaylistButton';
import Loader from '@App/components/ui/Loader';
import Card from '@App/components/layout/Card';
import TrackVersionList from '@App/components/model/TrackVersionList';
import ShowAllTagsButton from '@App/components/model/track/ShowAllTagsButton';
import RedirectError from '@App/error/RedirectError';
import { PLAYLIST } from '@App/routes';
import DownloadPlaylistButton from '@App/components/model/playlist/DownloadPlaylistButton';
import { connect } from 'react-redux';

class Playlist extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        seoTitle: PropTypes.string,
        seoDescription: PropTypes.string,
        description: PropTypes.string,
        coverAsHero: PropTypes.string.isRequired,
        category: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        trackVersions: PropTypes.array.isRequired,
        updatedAt: PropTypes.string.isRequired,
        canDownloadPlaylist: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        description: '',
        seoTitle: '',
        seoDescription: '',
    };

    render() {
        const { Image, Title, Description } = PageHeader;
        const { id, slug, title, description, coverAsHero, trackVersions, updatedAt, canDownloadPlaylist } = this.props;
        const trackNumber = I18n.t('model.track.countTracks', { count: trackVersions.length });
        const actions = [
            <ShowAllTagsButton key="show-tags" />,
            <SharePlaylistButton key="share" slug={slug} id={id} />,
            canDownloadPlaylist ? <DownloadPlaylistButton key="download" playlistId={id} className="btn btn--primary"/> : null
        ];

        return (
            <div className="content">
                <PageHeader>
                    <Card>
                        <div className="header__content">
                            <Image src={coverAsHero} alt={title} />
                            <div className="info">
                                <Title>{title}</Title>
                                <p>{trackNumber} | {I18n.t('model.playlist.updatedAt', { date: I18n.l('date.formats.full', updatedAt) })}</p>
                                <Description>{description}</Description>
                            </div>
                        </div>
                        <div className="header__actions">
                            {actions}
                        </div>
                    </Card>
                </PageHeader>
                <div className="container-fluid">
                    <Card title={trackNumber} actions={actions}>
                        <TrackVersionList versions={trackVersions} />
                    </Card>
                </div>
            </div>
        );
    }
}

export function LoadingPlaylist() {
    const { Title, Description } = PageHeader;

    return (
        <div className="content">
            <PageHeader>
                <Card>
                    <Title><Loader /></Title>
                    <Description />
                </Card>
            </PageHeader>
            <div className="container-fluid">
                <Card title="" loading />
            </div>
        </div>
    );
}

const ConnectedPlaylist = connect((state) => ({
    canDownloadPlaylist: state.authentication.permissions.isAdmin || state.authentication.permissions.isStaff,
}))(Playlist);

function MetaWrapper(props) {
    const { id, slug, requestedSlug, seoTitle, seoDescription, title, trackVersions, coverAsHero } = props;

    if (slug !== requestedSlug) {
        throw new RedirectError('Slug mismatch', { url: PLAYLIST.getUrl({ id, slug }) });
    }

    get('meta').set(
        seoTitle || I18n.t('meta.content.playlist.title', { title }),
        seoDescription || I18n.t('meta.content.playlist.description', { title, count: trackVersions.length }),
        coverAsHero
    );

    return <ConnectedPlaylist {...props} />;
}

MetaWrapper.propTypes = {
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    requestedSlug: PropTypes.string.isRequired,
    seoTitle: PropTypes.string,
    seoDescription: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    trackVersions: PropTypes.array.isRequired,
    coverAsHero: PropTypes.string.isRequired,
};

MetaWrapper.defaultProps = {
    description: null,
    seoTitle: null,
    seoDescription: null,
};

export default function PlaylistWrapper(props) {
    const { id, slug } = props;

    return (
        <Query
            component={MetaWrapper}
            loadingComponent={LoadingPlaylist}
            query={ShowPlaylist}
            variables={{ id }}
            mapResult={data => data.playlist}
            childProps={{ requestedSlug: slug }}
        />
    );
}

PlaylistWrapper.propTypes = {
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
};
