import PropTypes from 'prop-types';
import BreadCrumbItem from '@App/components/navigation/BreadCrumbItem';
import LoadingBreadCrumbItem from '@App/components/navigation/LoadingBreadCrumbItem';
import GetProjectName from '@App/api/query/GetProjectName.graphql';
import Query from '@App/components/api/Query';
import { PROJECT } from '@App/routes';

export default function ProjectBreadCrumbItem(props) {
    const { id } = props;

    return <Query
        component={BreadCrumbItem}
        loadingComponent={LoadingBreadCrumbItem}
        query={GetProjectName}
        variables={{ id }}
        mapResult={data => ({ label: data.project.name })}
        childProps={{
            route: PROJECT.name,
            parameters: { id }
        }}
    />;
}

ProjectBreadCrumbItem.propTypes = {
    id: PropTypes.string.isRequired,
};
