import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import MyProjects from '@App/api/query/MyProjects.graphql';
import Query from '@App/components/api/Query';
import Thumblist from '@App/components/layout/Thumblist';
import TabsBlockTitle from '@App/components/layout/TabsBlockTitle';
import ProjectTile from '@App/components/model/ProjectTile';
import CreateNewProjectButton from '@App/components/model/project/CreateNewProjectButton';

class ProjectList extends Component {
    static propTypes = {
        activeProjects: PropTypes.arrayOf(
            ProjectTile.propTypes.project
        ).isRequired,
        archivedProjects: PropTypes.arrayOf(
            ProjectTile.propTypes.project
        ).isRequired,
        followedProjects: PropTypes.arrayOf(
            ProjectTile.propTypes.project
        ).isRequired,
    };

    static TAB_ACTIVE = 'active';
    static TAB_ARCHIVED = 'archived';
    static TAB_FOLLOWED = 'followed';

    constructor(props) {
        super(props);

        this.renderLabel = this.renderLabel.bind(this);
        this.renderContent = this.renderContent.bind(this);
    }

    renderProject(project) {
        return <ProjectTile key={project.id} project={project} />;
    }

    renderLabel(tab) {
        const { TAB_ACTIVE, TAB_ARCHIVED, TAB_FOLLOWED } = this.constructor;
        const { activeProjects, archivedProjects, followedProjects } = this.props;

        switch (tab) {
            case TAB_ACTIVE:
                return <h1>{I18n.t('projects.active.label', { count: activeProjects.length })}</h1>;

            case TAB_ARCHIVED:
                return <h1>{I18n.t('projects.archived.label', { count: archivedProjects.length })}</h1>;

            case TAB_FOLLOWED:
                return <h1>{I18n.t('projects.followed.label', { count: followedProjects.length })}</h1>;

            default:
                return null;
        }
    }

    renderActiveProjects(projects) {
        if (projects.length < 1) {
            return (
                <h2 key="empty">{I18n.t('projects.active.empty')}</h2>
            );
        }

        return (
            <Thumblist key="active">
                {projects.map(this.renderProject)}
            </Thumblist>
        );
    }

    renderArchivedProjects(projects) {
        if (projects.length < 1) {
            return <h2 key="empty">{I18n.t('projects.archived.empty')}</h2>;
        }

        return (
            <Thumblist key="archived">
                {projects.map(this.renderProject)}
            </Thumblist>
        );
    }

    renderFollowedProjects(projects) {
        if (projects.length < 1) {
            return <h2 key="empty">{I18n.t('projects.followed.empty')}</h2>;
        }

        return (
            <Thumblist key="followed">
                {projects.map(this.renderProject)}
            </Thumblist>
        );
    }

    renderContent(tab) {
        const { TAB_ACTIVE, TAB_ARCHIVED, TAB_FOLLOWED } = this.constructor;
        const { activeProjects, archivedProjects, followedProjects } = this.props;

        switch (tab) {
            case TAB_ACTIVE:
                return this.renderActiveProjects(activeProjects);

            case TAB_ARCHIVED:
                return this.renderArchivedProjects(archivedProjects);

            case TAB_FOLLOWED:
                return this.renderFollowedProjects(followedProjects);

            default:
                return null;
        }
    }

    render() {
        const { TAB_ACTIVE, TAB_ARCHIVED, TAB_FOLLOWED } = this.constructor;
        const tabs = [TAB_ACTIVE, TAB_ARCHIVED, TAB_FOLLOWED];

        return (
            <div className="content">
                <div className="container-fluid">
                    <TabsBlockTitle tabs={tabs} renderLabel={this.renderLabel} renderContent={this.renderContent}>
                        <CreateNewProjectButton className="btn btn--primary" />
                    </TabsBlockTitle>
                </div>
            </div>
        );
    }
}

function mapAuthor(project) {
    const { user, ...properties } = project;

    return {
        ...properties,
        author: `${user.firstName} ${user.lastName}`
    };
}

function mapResults(data) {
    const projects = (data.myProjects || []).map(mapAuthor);
    const followedProjects = (data.followedProjects || []).map(mapAuthor);

    return {
        activeProjects: projects.filter(project => !project.archived),
        archivedProjects: projects.filter(project => project.archived),
        followedProjects,
    };
}

export default function ProjectListWrapper() {
    return <Query component={ProjectList} query={MyProjects} mapResult={mapResults} />;
}
