import { Component } from 'react';
import PropTypes from 'prop-types';
import SearchTrackVersions from '@App/api/query/SearchTrackVersions.graphql';
import Query from '@App/components/api/Query';
import { formatDuration } from '@App/utils';
import I18n from 'i18n-js';

export default class SearchTrackVersionInput extends Component {
    static propTypes = {
        onTrack: PropTypes.func.isRequired,
        limit: PropTypes.number,
        maxLength: PropTypes.number,
        minChars: PropTypes.number,
        placeholder: PropTypes.string,
        exclude: PropTypes.arrayOf(PropTypes.string),
        loading: PropTypes.bool,
    };

    static defaultProps = {
        minChars: 3,
        limit: 30,
        exclude: [],
        loading: false
    };

    constructor(props) {
        super(props);

        this.state = {
            search: null,
        };

        this.onSearch = this.onSearch.bind(this);
        this.onSelectTrack = this.onSelectTrack.bind(this);
        this.mapResults = this.mapResults.bind(this);
    }

    onSearch(event) {
        const { value } = event.target;
        const search = value.length > 0 ? value : null;

        this.setState({ search });
    }

    onSelectTrack(reference) {
        this.props.onTrack(reference);

        this.setState({ search: null });
    }

    static ResultItem(props) {
        const { reference, track, label, duration, onClick } = props;

        return (
            <li className="result-list__item" onClick={onClick}>
                <span>{reference} - {track.title} ({label} - {formatDuration(duration)})</span>
            </li>
        );
    }

    static ResultList(props) {
        const { ResultItem } = SearchTrackVersionInput;
        const { trackVersions, loading, onSelectTrack } = props;

        if (loading) {
            return (
                <ul className="result-list">
                    <li className="loader"></li>
                </ul>
            );
        }

        if (trackVersions.length === 0) {
            return (
                <ul className="result-list">
                    <li className="empty">{I18n.t('search.results.no-match')}</li>
                </ul>
            );
        }

        return (
            <ul className="result-list">
                {trackVersions.map(({ reference, track, label, duration }) => <ResultItem
                    key={reference}
                    reference={reference}
                    track={track}
                    label={label}
                    duration={duration}
                    onClick={() => onSelectTrack(reference)}
                />)}
            </ul>
        );
    }

    mapResults(data) {
        const { exclude } = this.props;
        const { searchTrackVersions } = data;

        return { trackVersions: searchTrackVersions.filter(trackVersion => !exclude.includes(trackVersion.reference)) };
    }

    render() {
        const { ResultList } = this.constructor;
        const { placeholder, maxLength, limit, minChars } = this.props;
        const { search } = this.state;

        return <div className="track-list__search">
            <div className="form">
                <input
                    type="text"
                    onChange={this.onSearch}
                    value={search || ''}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    autoComplete="off"
                />
            </div>
            {search === null || search.length < minChars ? null : <Query
                key={search}
                component={ResultList}
                loadingComponent={ResultList}
                query={SearchTrackVersions}
                variables={{ search, limit }}
                childProps={{ onSelectTrack: this.onSelectTrack }}
                mapResult={this.mapResults}
            />}
        </div>;
    }
}
