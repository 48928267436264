import { Component } from 'react';
import PropTypes from 'prop-types';
import Link from '@App/components/navigation/Link';

export default class Tile extends Component {
    static propTypes = {
        title: PropTypes.string,
        info: PropTypes.string,
        cover: PropTypes.string,
        link: PropTypes.shape(Link.propTypes).isRequired,
        children: PropTypes.node,
    };

    static defaultProps = {
        title: null,
        info: null,
        cover: null,
        children: null,
    };

    renderTitle(title) {
        if (!title) {
            return null;
        }

        return <span className="tile__title">{title}</span>;
    }

    renderInfo(info) {
        if (!info) {
            return null;
        }

        return <span className="tile__info">{info}</span>;
    }

    render() {
        const { title, info, cover, link, children } = this.props;

        return (
            <div className={'tile'}>
                <Link {...link}>
                    <span className="tile__image">
                        <span className="image" style={{ background: cover ? `url('${cover}')` : undefined }} />
                    </span>
                    {this.renderTitle(title)}
                    {this.renderInfo(info)}
                </Link>
                {children}
            </div>
        );
    }
}
