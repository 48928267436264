import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@App/components/ui/Button';
import IncludeTagOptionItem from '@App/components/search/tags/IncludeTagOptionItem';
import ExcludeTagOptionItem from '@App/components/search/tags/ExcludeTagOptionItem';
import IncludeTagButton from '@App/components/search/tags/IncludeTagButton';

export default class ResultBoxTag extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        onUse: PropTypes.func.isRequired,
    };

    render() {
        const { id, title, onUse } = this.props;

        return (
            <div className="collapsible">
                <div className="collapsible__actions">
                    <IncludeTagButton id={id} onUse={onUse} component={Button} label={title} className="tag-button" />
                    <div className="options">
                        <IncludeTagOptionItem id={id} onUse={onUse} />
                        <ExcludeTagOptionItem id={id} onUse={onUse} />
                    </div>
                </div>
            </div>
        );
    }
}
