import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Label from '@App/components/form/Label';
import Errors from '@App/components/form/Errors';

export default class TextInput extends Component {
    static propTypes = {
        value: PropTypes.string,
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['text', 'email', 'tel', 'password', 'url']),
        required: PropTypes.bool,
        errors: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        pattern: PropTypes.string,
        help: PropTypes.node,
        autoComplete: PropTypes.string,
    };

    static defaultProps = {
        value: null,
        type: 'text',
        required: false,
        help: null,
        errors: [],
        autoComplete: null,
        onChange: () => {},
        onBlur: () => {},
    };

    constructor(props) {
        super(props);

        this.hasErrors = this.hasErrors.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    /**
     * @returns {Boolean}
     */
    hasErrors() {
        return [].concat(this.props.errors).length > 0;
    }

    extractEventValue(event) {
        let { value } = event.target;

        return value.trim().length > 0 ? value : null;
    }

    onChange(event) {
        const { onChange } = this.props;
        let value = this.extractEventValue(event);

        if (value !== this.props.value) {
            onChange(value, event);
        }
    }

    onBlur(event) {
        const { onBlur } = this.props;
        let value = this.extractEventValue(event);

        onBlur(value, event);
    }

    render() {
        const { id, value, type, label, help, required, pattern, errors, ...otherProps } = this.props;
        const className = classNames('form__input', { 'form__input--error': this.hasErrors() });

        delete otherProps.onChange;
        delete otherProps.onBlur;

        return (
            <div className="form">
                <Label forId={id} required={required}>{label}</Label>
                <input
                    id={id}
                    type={type}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    className={className}
                    required={required}
                    pattern={pattern}
                    value={value || ''}
                    {...otherProps}
                />
                {help ? <small className="form__help">{help}</small> : null}
                <Errors errors={errors} />
            </div>
        );
    }
}
