import { useQuery } from '@apollo/client';
import ListLicenses from '@App/api/query/license/ListLicenses.graphql';
import { useMemo } from 'react';

export function useLicenses() {
    const { error, loading, data = []} = useQuery(ListLicenses);

    const licenses = useMemo(() => {
        if (error || loading) {
            return [];
        }

        return data.orderLicenses;
    }, [error, loading, data]);

    return {
        loading,
        error,
        /** The raw response data */
        data,
        licenses,
        getById(id) {
            return licenses.find(license => license.id === id);
        }
    };
}
