import I18n from 'i18n-js';
import BlockTitle from '@App/components/layout/BlockTitle';
import Table from '@App/components/layout/Table';
import Loader from '@App/components/ui/Loader';
import Link from '@App/components/navigation/Link';
import { ORDER } from '@App/routes';
import { formatEur } from '@App/utils/numbers';
import { useOrders } from '@App/api/hooks/useOrders';
import OrderStatus from '@App/model/order/OrderStatus';
import PropTypes from 'prop-types';
import types from '@App/types';

export default function OrdersList() {
    const { orders, loading } = useOrders();

    return <div className="content">
        <div className="container-fluid">
            <BlockTitle title={I18n.t('page.orders.title')}/>
            <Table
                values={orders}
                renderEmpty={() =>
                    <tr>
                        <td className="empty" colSpan="6">
                            {loading ? <Loader/> : I18n.t('page.orders.empty')}
                        </td>
                    </tr>
                }
                renderHead={() => <tr>
                    <th>{I18n.t('page.orders.table.headers.date')}</th>
                    <th>{I18n.t('page.orders.table.headers.reference')}</th> {/* Invoice number */}
                    <th>{I18n.t('page.orders.table.headers.project')}</th>
                    <th>{I18n.t('page.orders.table.headers.license')}</th>
                    <th className="text-right">{I18n.t('page.orders.table.headers.totalPrice')}</th>
                    <th>{I18n.t('page.orders.table.headers.status')}</th>
                    <th className="text-center" style={{ width: '1%' }}>
                        {I18n.t('page.orders.table.headers.actions')}
                    </th>
                </tr>}
                renderRow={Row}
            />
        </div>
    </div>;
}

Row.propTypes = {
    id: PropTypes.string.isRequired,
    invoiceNumber: PropTypes.string,
    projectName: PropTypes.string.isRequired,
    nbTracks: PropTypes.number.isRequired,
    license: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    price: PropTypes.string.isRequired,
    /** Tax rate as percent */
    taxRate: PropTypes.number.isRequired,
    status: types.Order.Statuses.isRequired,
    createdAt: PropTypes.string.isRequired,
};

Row.defaultProps = {
    invoiceNumber: null,
};

function Row({ id, invoiceNumber, createdAt, projectName, license, price, taxRate, status, nbTracks }) {
    const priceTTC = price * (1 + taxRate / 100);

    return (
        <tr key={id} id={`request-${id}`}>
            <td>{I18n.l('date.formats.short', createdAt)}</td>
            <td>{invoiceNumber || '-'}</td>
            <td>{I18n.t('page.orders.table.cells.project', { projectName, nbTracks })}</td>
            <td>{license.name}</td>
            <td className="text-right">{formatEur(priceTTC)}</td>
            <td className={getStatusClass(status)}>
                {I18n.t(`page.orders.table.cells.status.${status}`)}
            </td>
            <td className="text-center">
                <Link route={ORDER.name}
                    parameters={{ id }}
                    label={I18n.t('page.orders.table.cells.actions.show')}
                    tooltip
                    className="no-underline"
                    icon="eye"
                />
            </td>
        </tr>
    );
}

function getStatusClass(status) {
    switch (status) {
        case OrderStatus.PENDING:
            return 'status-pending';
        case OrderStatus.PAID:
            return 'status-success';
    }
}
