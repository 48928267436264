import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import GraphQLUtils from '@App/utils/graphql';
import Form from '@App/components/form/Form';
import TextInput from '@App/components/form/TextInput';
import DurationInput from '@App/components/form/DurationInput';
import ConditionInput from '@App/components/form/ConditionInput';
import TrackListInput from '@App/components/form/TrackListInput';
import Checkbox from '@App/components/form/Checkbox';
import Radio from '@App/components/form/Radio';
import Mutation from '@App/components/api/Mutation';
import LicenceRequestCreateWebReport from '@App/api/mutation/LicenceRequestCreateWebReport.graphql';
import { onAddLicenceRequest } from '@App/api/store';
import FormUtils from '@App/utils/form';
import UrlInput from '@App/components/form/UrlInput';

class WebReportLicenceRequestForm extends Component {
    static propTypes = {
        id: PropTypes.string,
        loading: PropTypes.bool,
        errors: PropTypes.shape(),
        mutate: PropTypes.func.isRequired,
        onSuccess: PropTypes.func,
        licenceRequest: PropTypes.shape(),
        readOnly: PropTypes.bool,
    };

    static defaultProps = {
        id: null,
        errors: {},
        loading: false,
        onSuccess: null,
        licenceRequest: null,
        readOnly: false,
    };

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.renderForm = this.renderForm.bind(this);

        this.fields = [
            // Limited Partner
            { type: TextInput, name: 'limitedPartnerType' },
            { type: TextInput, name: 'limitedPartnerName', required: true },
            { type: TextInput, name: 'limitedPartnerAddress', required: true },
            { type: TextInput, name: 'limitedPartnerPhone' },
            { type: TextInput, name: 'limitedPartnerEmail' },
            { type: UrlInput, name: 'limitedPartnerWebsite', required: true },
            { type: Radio, key: 'limitedPartnerToInvoice', required: true, name: 'invoiceTarget', choice: 'limitedPartner' },
            // Production Company
            { type: TextInput, name: 'productionCompanyName', required: true },
            { type: TextInput, name: 'productionCompanyAddress', required: true },
            { type: TextInput, name: 'productionCompanyPhone', required: true },
            { type: TextInput, name: 'productionCompanyEmail', required: true },
            { type: Radio, key: 'productionCompanyToInvoice', required: true, name: 'invoiceTarget', choice: 'productionCompany' },
            // SoundTrack Oor Illustration
            { type: TextInput, name: 'soundTrackOrIllustrationName' },
            { type: TextInput, name: 'soundTrackOrIllustrationAddress' },
            { type: TextInput, name: 'soundTrackOrIllustrationEmail' },
            { type: UrlInput, name: 'soundTrackOrIllustrationWebsite' },
            // Handle invoice
            { type: Checkbox, name: 'handleInvoice', label: I18n.t('form.handleInvoice.label') },
            // Web Report
            { type: TextInput, name: 'title', required: true },
            { type: UrlInput, name: 'url' },
            {
                type: DurationInput,
                name: 'duration',
                required: true,
                // validator: value => value > 0
            },
            // Tracks
            {
                type: TrackListInput,
                name: 'tracks',
                label: null,
                // validator: value => value.length > 0 && value.every(track => track.duration > 0)
            },
            // Conditions
            { type: ConditionInput, name: 'generalConditions', required: true, url: '/CGL.pdf' },
        ];
    }

    componentDidUpdate(prevProps) {
        const { id, onSuccess } = this.props;

        if (id && !prevProps.id && onSuccess) {
            return onSuccess(id);
        }
    }

    renderForm(form) {
        const fields = Object.fromEntries(form.map(field => [field.key, field]));

        return (
            <div>
                <h2>{I18n.t('form.web_report_licence_request.limitedPartner')}</h2>
                <div className="row">
                    <div className="col-sm-6">{fields.limitedPartnerType}</div>
                    <div className="col-sm-6">{fields.limitedPartnerName}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.limitedPartnerAddress}</div>
                </div>
                <div className="row">
                    <div className="col-sm-6">{fields.limitedPartnerPhone}</div>
                    <div className="col-sm-6">{fields.limitedPartnerEmail}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.limitedPartnerWebsite}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.limitedPartnerToInvoice}</div>
                </div>

                <h2>{I18n.t('form.web_report_licence_request.productionCompany')}</h2>
                <div className="row">
                    <div className="col-sm-12">{fields.productionCompanyName}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.productionCompanyAddress}</div>
                </div>
                <div className="row">
                    <div className="col-sm-6">{fields.productionCompanyPhone}</div>
                    <div className="col-sm-6">{fields.productionCompanyEmail}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.productionCompanyToInvoice}</div>
                </div>

                <h2>{I18n.t('form.web_report_licence_request.soundTrackOrIllustration')}</h2>
                <div className="row">
                    <div className="col-sm-12">{fields.soundTrackOrIllustrationName}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.soundTrackOrIllustrationAddress}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.soundTrackOrIllustrationEmail}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{fields.soundTrackOrIllustrationWebsite}</div>
                </div>

                <div className="hero">
                    <h3 className="hero__title">{I18n.t('form.handleInvoice.title')}</h3>
                    <div className="hero__content">
                        <p>{I18n.t('form.handleInvoice.content')}</p>
                        {fields.handleInvoice}
                    </div>
                </div>

                <h2>{I18n.t('form.web_report_licence_request.webReport')}</h2>
                <div className="row">
                    <div className="col-sm-12">{fields.title}</div>
                </div>
                <div className="row">
                    <div className="col-sm-6">{fields.url}</div>
                    <div className="col-sm-6">{fields.duration}</div>
                </div>

                <h2>{I18n.t('form.web_report_licence_request.tracks')}</h2>
                {fields.tracks}

                <h2>{I18n.t('form.web_report_licence_request.conditions')}</h2>
                {fields.generalConditions}
            </div>
        );
    }

    onSubmit(data) {
        const { mutate, loading, id } = this.props;

        if (loading || id !== null) {
            return;
        }

        mutate({ payload: {
            title: data.title,
            url: data.url,
            duration: data.duration,
            handleInvoice: data.handleInvoice,
            invoiceTarget: data.invoiceTarget,
            limitedPartner: {
                type: data.limitedPartnerType,
                name: data.limitedPartnerName,
                address: data.limitedPartnerAddress,
                phone: data.limitedPartnerPhone,
                email: data.limitedPartnerEmail,
                website: data.limitedPartnerWebsite,
            },
            productionCompany: {
                name: data.productionCompanyName,
                address: data.productionCompanyAddress,
                phone: data.productionCompanyPhone,
                email: data.productionCompanyEmail,
                website: null,
            },
            soundTrackOrIllustration: {
                name: data.soundTrackOrIllustrationName,
                address: data.soundTrackOrIllustrationAddress,
                email: data.soundTrackOrIllustrationEmail,
                website: data.soundTrackOrIllustrationWebsite,
            },
            tracks: data.tracks,
        } }, onAddLicenceRequest).catch(() => {
            FormUtils.scrollToFirstError();
        });
    }

    load(licenceRequest = null) {
        if (!licenceRequest) {
            return {
                invoiceTarget: 'limitedPartner',
                tracks: [],
            };
        }

        return {
            title: licenceRequest.title,
            url: licenceRequest.url,
            duration: licenceRequest.duration,
            handleInvoice: licenceRequest.handleInvoice,
            invoiceTarget: licenceRequest.invoiceTarget,
            limitedPartnerType: licenceRequest.limitedPartner.type,
            limitedPartnerName: licenceRequest.limitedPartner.name,
            limitedPartnerAddress: licenceRequest.limitedPartner.address,
            limitedPartnerPhone: licenceRequest.limitedPartner.phone,
            limitedPartnerEmail: licenceRequest.limitedPartner.email,
            limitedPartnerWebsite: licenceRequest.limitedPartner.website,
            productionCompanyName: licenceRequest.productionCompany.name,
            productionCompanyAddress: licenceRequest.productionCompany.address,
            productionCompanyPhone: licenceRequest.productionCompany.phone,
            productionCompanyEmail: licenceRequest.productionCompany.email,
            soundTrackOrIllustrationName: licenceRequest.soundTrackOrIllustration.name,
            soundTrackOrIllustrationAddress: licenceRequest.soundTrackOrIllustration.address,
            soundTrackOrIllustrationEmail: licenceRequest.soundTrackOrIllustration.email,
            soundTrackOrIllustrationWebsite: licenceRequest.soundTrackOrIllustration.website,
            tracks: licenceRequest.tracks.map(licenceTrack => ({
                reference: licenceTrack.version.reference,
                duration: licenceTrack.duration,
            })),
            generalConditions: true,
        };
    }

    render() {
        const { id, loading, errors, licenceRequest, readOnly } = this.props;

        return <Form
            name="web_report_licence_request"
            fields={this.fields}
            renderForm={this.renderForm}
            loading={loading}
            success={id !== null}
            onSubmit={this.onSubmit}
            data={this.load(licenceRequest)}
            readOnly={readOnly}
            errors={errors}
        />;
    }
}

function mapResult(data) {
    return { id: data.licenceRequestCreateWebReport.id };
}

function mapLoading() {
    return { loading: true };
}

function mapError(error) {
    if (!error) {
        return { errors: {} };
    }

    const graphQLErrors = GraphQLUtils.errorsByPath(error.graphQLErrors);

    const tracksErrors = [].concat(
        graphQLErrors['tracks'],
        Object.entries(graphQLErrors)
            .filter(([path]) => /^tracks\[\d+\]\..+/.test(path))
            .reduce((carry, [, errors]) => {
                errors.forEach((error) => carry.includes(error) || carry.push(error));

                return carry;
            }, [])
    ).filter(Boolean);

    return { errors: {
        title: graphQLErrors['title'],
        url: graphQLErrors['url'],
        duration: graphQLErrors['duration'],
        handleInvoice: graphQLErrors['handleInvoice'],
        invoiceTarget: graphQLErrors['invoiceTarget'],
        limitedPartnerType: graphQLErrors['limitedPartner.type'],
        limitedPartnerName: graphQLErrors['limitedPartner.name'],
        limitedPartnerAddress: graphQLErrors['limitedPartner.address'],
        limitedPartnerPhone: graphQLErrors['limitedPartner.phone'],
        limitedPartnerEmail: graphQLErrors['limitedPartner.email'],
        limitedPartnerWebsite: graphQLErrors['limitedPartner.website'],
        productionCompanyName: graphQLErrors['productionCompany.name'],
        productionCompanyAddress: graphQLErrors['productionCompany.address'],
        productionCompanyPhone: graphQLErrors['productionCompany.phone'],
        productionCompanyEmail: graphQLErrors['productionCompany.email'],
        soundTrackOrIllustrationName: graphQLErrors['soundTrackOrIllustration.name'],
        soundTrackOrIllustrationAddress: graphQLErrors['soundTrackOrIllustration.address'],
        soundTrackOrIllustrationEmail: graphQLErrors['soundTrackOrIllustration.email'],
        soundTrackOrIllustrationWebsite: graphQLErrors['soundTrackOrIllustration.website'],
        tracks: tracksErrors,
    } };
}

/**
 * GraphQL wrapped DownloadProjectPanel
 *
 * @param {Object} props
 */
export default function WebReportLicenceRequestFormWraper(props) {
    return <Mutation
        mutation={LicenceRequestCreateWebReport}
        component={WebReportLicenceRequestForm}
        loadingComponent={WebReportLicenceRequestForm}
        errorComponent={WebReportLicenceRequestForm}
        mapResult={mapResult}
        mapLoading={mapLoading}
        mapError={mapError}
        childProps={props}
    />;
}
