import PropTypes from 'prop-types';
import Label from '@App/components/form/Label';
import Loader from '@App/components/ui/Loader';

export default function LoadingInput(props) {
    const { id, label, required } = props;

    return (
        <div>
            <Label forId={id} required={required}>{label}</Label>
            <Loader id={id} />
        </div>
    );
}

LoadingInput.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
};

LoadingInput.defaultProps = {
    id: null,
    required: false,
};
