import { Component } from 'react';
import PropTypes from 'prop-types';

export default class Thumblist extends Component {
    static propTypes = {
        title: PropTypes.string,
        children: PropTypes.node.isRequired,
        link: PropTypes.node,
    };

    renderTitle(title, link) {
        if (!title) {
            return null;
        }

        return (
            <div className="thumblist__title">
                <h2 className="h1">{title}</h2>
                {link}
            </div>
        );
    }

    render() {
        const { title, link, children, ...props } = this.props;

        return (
            <section {...props} className="thumblist">
                {this.renderTitle(title, link)}
                <div className="thumblist__row">
                    {children}
                </div>
            </section>
        );
    }
}

