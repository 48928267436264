import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { DUPLICATE_PROJECT } from '@App/routes';

function DuplicateButton(props) {
    const { id, canManageUser, ...childProps } = props;

    if (!canManageUser) {
        return null;
    }

    delete childProps.dispatch;

    return (
        <a {...childProps} href={DUPLICATE_PROJECT.getUrl({project: id})} >{I18n.t('model.project.actions.duplicate')}</a>
    );
}

DuplicateButton.propTypes = {
    id: PropTypes.string.isRequired,
    canManageUser: PropTypes.bool.isRequired,
};

export default connect((state) => ({
    canManageUser: state.authentication.permissions.canManageUser
}))(DuplicateButton);
