import PropTypes from 'prop-types';
import TextInput from '@App/components/form/TextInput';

function hasUrlProtocol(value) {
    return /^.+:?\/\/.*/.test(value);
}

export function prependProtocol(value, protocol = 'https') {
    if (!hasUrlProtocol(value)) {
        value = `${protocol}://${value.replace(/^\/+/, '')}`;
    }

    return value;
}

UrlInput.propTypes = {
    defaultProtocol: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};
UrlInput.defaultProps = {
    defaultProtocol: 'https',
    onChange: () => {},
    onBlur: () => {},
};

/**
 * A TextInput wrapper with a onBlur behavior to automatically prefix a default protocol if not provided in input value.
 */
export default function UrlInput({ defaultProtocol, onBlur, ...props }) {
    const { onChange } = props;

    return <TextInput type="url" onBlur={(value, event) => {
        if (!value) {
            return;
        }

        value = prependProtocol(value, defaultProtocol);

        onChange && onChange(value, event);
        onBlur && onBlur(value, event);

    }} {...props} />;
}
