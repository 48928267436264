import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import I18n from 'i18n-js';
import Mutation from '@App/components/api/Mutation';
import DownloadTrack from '@App/api/mutation/DownloadTrack.graphql';
import DownloadButton, { DownloadButton as DownloadButtonInternal } from '@App/components/download/DownloadButton';

class DownloadTrackButton extends Component {
    static propTypes = {
        ...DownloadTrackButtonWrapper.propTypes,
        mutate: PropTypes.func.isRequired,
        id: PropTypes.string,
        status: PropTypes.string,
        url: PropTypes.string,
    };

    static defaultProps = {
        ...DownloadTrackButtonWrapper.defaultProps,
        id: null,
        status: 'ready',
        url: null,
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.getLabel = this.getLabel.bind(this);
    }

    onClick() {
        const { mutate, status, reference, format, versions } = this.props;

        if (status === 'init' || !this.isValid()) {
            return;
        }

        mutate({ reference, payload: { format, versions } });
    }

    isValid() {
        const { reference, format, versions } = this.props;

        return reference !== null && format !== null && versions.length > 0;
    }

    getLabel(status, detail) {
        if (status === 'success') {
            return <Fragment>
                <i key="icon" className="icon icon--download"></i>
                {I18n.t('panel.download-track.label.success')}
            </Fragment>;
        }

        if (status === 'pending' && detail && detail.maxStep) {
            const { current, maxStep } = detail;

            return <Fragment>
                <i key="icon" className="icon icon--more"></i>
                {I18n.t('panel.download-track.label.pending_with_progress', { current, maxStep })}
            </Fragment>;
        }

        return <Fragment>
            <i key="icon" className="icon icon--more"></i>
            {I18n.t(`panel.download-track.label.${status}`)}
        </Fragment>;
    }

    getClassName(status) {
        switch(status) {
            case 'success':
                return 'btn btn--success--full';

            default:
                return 'btn btn--primary disabled';
        }
    }

    render() {
        const { status, id } = this.props;
        const disabled = !this.isValid();

        if (id) {
            return <DownloadButton id={id} className={this.getClassName} label={this.getLabel} />;
        }

        if (status === 'init') {
            return <DownloadButtonInternal status={status} className={this.getClassName} label={this.getLabel} />;
        }

        return (
            <button type="button" className={classnames('btn btn--primary', { disabled })} onClick={this.onClick} disabled={disabled}>
                <i className="icon icon--download"></i>
                {I18n.t('panel.download-track.title')}
            </button>
        );
    }
}

function mapResult(data) {
    const { id, status, url } = data.downloadTrack;

    return { id, status, url };
}

function mapLoading() {
    return { status: 'init' };
}

function getUniqueId(props) {
    const { reference, versions, format } = props;

    return [reference, format, ...versions].join('-');
}

/**
 * GraphQL wrapped DownloadTrackPanel
 *
 * @param {Object} props
 */
export default function DownloadTrackButtonWrapper(props) {
    return <Mutation
        key={getUniqueId(props)}
        mutation={DownloadTrack}
        component={DownloadTrackButton}
        loadingComponent={DownloadTrackButton}
        mapResult={mapResult}
        mapLoading={mapLoading}
        childProps={props}
    />;
}

DownloadTrackButtonWrapper.propTypes = {
    reference: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(PropTypes.string.isRequired),
    format: PropTypes.string,
};

DownloadTrackButtonWrapper.defaultProps = {
    versions: [],
    format: null,
};
