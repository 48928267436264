import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Label from '@App/components/form/Label';
import Errors from '@App/components/form/Errors';

export default class Radio extends Component {

    static propTypes = {
        value: PropTypes.string,
        choice: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
        readOnly: PropTypes.bool,
        errors: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        onChange: PropTypes.func,
    };

    static defaultProps = {
        value: null,
        required: false,
        readOnly: false,
        errors: [],
        onChange: () => {},
    };

    constructor(props) {
        super(props);

        this.hasErrors = this.hasErrors.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    /**
     * @returns {Boolean}
     */
    hasErrors() {
        return [].concat(this.props.errors).length > 0;
    }

    onChange(event) {
        const { onChange, choice } = this.props;

        onChange(choice, event);
    }

    render() {
        const { id, name, value, choice, label, required, errors, readOnly, ...otherProps } = this.props;
        const className = classNames('radio', { 'form__input--error': this.hasErrors() });

        delete otherProps.onChange;

        if (readOnly) {
            otherProps.disabled = true;
        }

        return (
            <div className="radio">
                <input
                    id={id}
                    name={name}
                    type="radio"
                    onChange={this.onChange}
                    className={className}
                    required={required}
                    checked={value === choice}
                    {...otherProps}
                />
                <Label forId={id} required={required} className="">{label}</Label>
                <Errors errors={errors} />
            </div>
        );
    }
}
