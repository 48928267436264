import { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import BriefAddTrack from '@App/api/mutation/BriefAddTrack.graphql';

export default function BriefItem({
    versionReference,
    brief
}) {
    const [state, setState] = useState({
        loading: false,
        success: false,
        errors: false,
    });

    function markAsSuccessful() {
        setState({
            ...state,
            loading: false,
            success: true,
        });
    }

    function markAsFailed() {
        setState({
            ...state,
            loading: false,
            errors: true,
        });
    }

    function markAsLoading() {
        setState({
            ...state,
            loading: true,
        });
    }

    const [addTrackToBrief] = useMutation(BriefAddTrack, {
        variables: {
            id: brief.id,
            version: versionReference,
        },
        onCompleted: markAsSuccessful,
    });

    const onClick = async () => {
        markAsLoading();
        await addTrackToBrief().catch(() => markAsFailed());
    };

    return (
        <li className={classNames('brief-add', {
            'brief-add--added': state.success,
        })}>
            <button onClick={onClick} className="text-ellipsis">
                <i className={classNames('icon icon--add', {
                    'icon icon--check': state.success,
                })}></i>
                {brief.title}
            </button>
            <span className="text-danger" style={{display: state.errors ? '' : 'none'}}>
                {I18n.t('panel.brief.add-track.failed')}
            </span>
        </li>
    );
}

BriefItem.propTypes = {
    versionReference: PropTypes.string,
    brief: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
};
