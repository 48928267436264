import { Component } from 'react';
import PropTypes from 'prop-types';
import ListFilterLabels from '@App/api/query/ListFilterLabels.graphql';
import LabelButton from '@App/components/search/labels/LabelButton';
import Loader from '@App/components/ui/Loader';
import Query from '@App/components/api/Query';

class LabelsFilter extends Component {
    static propTypes = {
        // ...Wrapper.propTypes,
        className: PropTypes.string,
        labels: PropTypes.arrayOf(
            PropTypes.shape({
                reference: PropTypes.string,
                name: PropTypes.string,
            }).isRequired
        ).isRequired,
        loading: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        className: '',
    };

    renderLabel(label) {
        const { reference, name } = label;

        return (
            <li key={reference} className="filters__item">
                <LabelButton reference={reference} name={name} />
            </li>
        );
    }

    render() {
        const { loading, labels, className } = this.props;

        if (loading) {
            return (
                <ul className={className}>
                    <li className="collapsible"><Loader /></li>
                </ul>
            );
        }

        return (
            <ul className={className}>
                {labels.map(this.renderLabel)}
            </ul>
        );
    }
}

function mapResult(data) {
    const { labels } = data;

    return { labels, loading: false };
}

function mapLoading() {
    return { labels: [], loading: true };
}

export default function Wrapper(props) {
    return (
        <Query
            component={LabelsFilter}
            loadingComponent={LabelsFilter}
            query={ListFilterLabels}
            mapResult={mapResult}
            mapLoading={mapLoading}
            childProps={props}
        />
    );
}

Wrapper.propTypes = {
    className: PropTypes.string,
};

Wrapper.defaultProps = {
    className: ''
};
