import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    openSimultaneousLoginsSpottedPanel,
    closeSimultaneousLoginsSpottedPanel,
    setAnonymous
} from '@App/store/actions/authentication';
import SimultaneousLoginsSpottedPanel from '@App/components/authentication/SimultaneousLoginsSpottedPanel';
import { LOGOUT } from '@App/routes';

function SimultaneousLoginsSpottedPanelContainer({
    authenticated,
    isImpersonator,
    areSimultaneousLoginsSpotted,
    onSimultaneousLoginsSpotted,
    onClose,
}) {
    return <SimultaneousLoginsSpottedPanel
        authenticated={authenticated}
        isImpersonator={isImpersonator}
        onClose={onClose}
        onSimultaneousLoginsSpotted={onSimultaneousLoginsSpotted}
        areSimultaneousLoginsSpotted={areSimultaneousLoginsSpotted}
    />;
}

export default connect(
    state => ({
        areSimultaneousLoginsSpotted: state.authentication.simultaneousLoginsSpotted,
        authenticated: state.authentication.id !== null,
        isImpersonator: state.authentication.permissions?.isImpersonator === true,
    }),
    dispatch => ({
        onClose: () => dispatch(closeSimultaneousLoginsSpottedPanel()),
        onSimultaneousLoginsSpotted: () => {
            // Logout on server-side
            fetch(LOGOUT.getUrl())
                .then(function (response) {
                    // Then mark current user as logged out in Redux store, and display feedback panel
                    dispatch(setAnonymous());
                    dispatch(openSimultaneousLoginsSpottedPanel());
                });
        }
    })
)(SimultaneousLoginsSpottedPanelContainer);

SimultaneousLoginsSpottedPanelContainer.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    isImpersonator: PropTypes.bool.isRequired,
    onSimultaneousLoginsSpotted: PropTypes.func.isRequired,
    areSimultaneousLoginsSpotted: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
