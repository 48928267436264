import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import { navigate } from '@App/store/actions/navigation';
import { PROJECT } from '@App/routes';
import { onAddProject } from '@App/api/store';
import CreateProject from '@App/api/mutation/CreateProject.graphql';
import Mutation from '@App/components/api/Mutation';
import Button from '@App/components/ui/Button';

class CreateNewProjectButton extends Component {
    static propTypes = {
        mutate: PropTypes.func.isRequired,
        goToProject: PropTypes.func.isRequired,
        newProjectId: PropTypes.string,
        loading: PropTypes.bool,
        errors: PropTypes.object,
    };

    static defaultProps = {
        newProjectId: null,
        loading: false,
        errors: null,
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { newProjectId, goToProject } = this.props;

        // Project successfully created
        if (newProjectId && !prevProps.newProjectId) {
            goToProject(newProjectId);
        }
    }

    onClick() {
        const { mutate, loading } = this.props;

        if (loading) {
            return;
        }

        mutate(
            {
                payload: {
                    name: I18n.t('model.project.defaultName'),
                    cover: null,
                }
            },
            (store, { data }) => onAddProject(store, data.projectCreate)
        );
    }

    render() {
        const { loading, ...props } = this.props;
        const label = loading ? '' : I18n.t('model.project.actions.new');

        delete props.goToProject;
        delete props.newProjectId;
        delete props.errors;
        delete props.mutate;

        return <Button {...props} label={label} loading={loading} onClick={this.onClick} />;
    }
}

function Wrapper(props) {
    return <Mutation
        mutation={CreateProject}
        component={CreateNewProjectButton}
        loadingComponent={CreateNewProjectButton}
        mapResult={data => ({ loading: false, newProjectId: data.projectCreate.id })}
        mapLoading={() => ({ loading: true, newProjectId: null })}
        childProps={props}
    />;
}

export default connect(
    null,
    dispatch => ({
        goToProject: id => dispatch(navigate(PROJECT.name, { id }))
    })
)(Wrapper);
