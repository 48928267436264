import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import ProjectRemoveTrack from '@App/api/mutation/ProjectRemoveTrack.graphql';
import Mutation from '@App/components/api/Mutation';
import Button from '@App/components/ui/Button';

class RemoveFromProjectButton extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired,
        versionReference: PropTypes.string.isRequired,
        mutate: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        success: PropTypes.bool,
        errors: PropTypes.object,
    };

    static defaultProps = {
        loading: false,
        success: null,
        errors: null,
    };

    constructor(props) {
        super(props);

        this.onClick =this.onClick.bind(this);
    }

    onClick() {
        const { mutate, loading, projectId, versionReference } = this.props;

        if (loading) {
            return;
        }

        mutate({ id: projectId, version: versionReference });
    }

    render() {
        const { loading, ...props } = this.props;
        const label = loading ? '' : I18n.t('model.track.actions.removeFromProject');

        delete props.projectId;
        delete props.versionReference;
        delete props.success;
        delete props.errors;
        delete props.mutate;

        return <Button {...props} label={label} loading={loading} onClick={this.onClick} />;
    }
}

export default function AddToProjectButtonWrapper(props) {
    const { projectId, versionReference, ...childProps } = props;

    return <Mutation
        mutation={ProjectRemoveTrack}
        component={RemoveFromProjectButton}
        loadingComponent={RemoveFromProjectButton}
        errorComponent={RemoveFromProjectButton}
        mapResult={() => ({ success: true })}
        mapLoading={() => ({ loading: true, success: null })}
        childProps={{ projectId, versionReference, ...childProps }}
    />;
}

AddToProjectButtonWrapper.propTypes = {
    projectId: PropTypes.string.isRequired,
    versionReference: PropTypes.string.isRequired,
};
