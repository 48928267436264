import I18n from 'i18n-js';
import OptionItem from '@App/components/ui/OptionItem';
import ExcludeTagButton from '@App/components/search/tags/ExcludeTagButton';

export default function ExcludeTagOptionItem(props) {
    return <ExcludeTagButton
        {...props}
        component={OptionItem}
        icon="forbid"
        className="options__item--exclude"
        label={I18n.t('search.tag.exclude')}
    />;
}
