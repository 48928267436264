import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closeSelectBriefPanel } from '@App/store/actions/brief';
import GetBriefs from '@App/api/query/GetBriefs.graphql';
import Query from '@App/components/api/Query';
import SelectBriefPanel from '@App/components/page/brief/SelectBriefPanel';
import ForbiddenPanel from '@App/components/authentication/ForbiddenPanel';
import I18n from 'i18n-js';

/**
 * GraphQL-wrapped SelectBriefPanel
 *
 * @see @App/components/container/BackdropContainer to close panel when clicking outside
 */
function SelectBriefPanelContainer({
    onClose,
    authenticated,
    trackReference,
    versionReference,
    canManageBriefs,
}) {
    if(trackReference === null || versionReference === null) {
        return null;
    }

    if (!authenticated || !canManageBriefs) {
        return <ForbiddenPanel
            id="select-brief"
            title={I18n.t('panel.brief.add-track.select')}
            translationKeyPrefix="panel.brief-forbidden"
            versionReference={versionReference}
            authenticated={authenticated}
            onClose={onClose}
        />;
    }

    return <Query
        component={SelectBriefPanel}
        query={GetBriefs}
        variables={{ }}
        mapResult={data => ({ briefs: data.briefs })}
        childProps={{ trackReference, versionReference, onClose}}
    />;
}

SelectBriefPanelContainer.propTypes = {
    onClose: PropTypes.func.isRequired,
    trackReference: PropTypes.string,
    versionReference: PropTypes.string,
    authenticated: PropTypes.bool.isRequired,
    canManageBriefs: PropTypes.bool.isRequired,
};

SelectBriefPanelContainer.defaultProps = {
    trackReference: null,
    versionReference: null,
};

export default connect(
    state => ({
        trackReference: state.brief.trackReference,
        versionReference: state.brief.versionReference,
        authenticated: state.authentication.id !== null,
        canManageBriefs: state.authentication?.permissions?.canManageBrief === true,
    }),
    dispatch => ({
        onClose: () => dispatch(closeSelectBriefPanel()),
    })
)(SelectBriefPanelContainer);
