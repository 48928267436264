import { Component } from 'react';
import PropTypes from 'prop-types';
import SearchQuery from '@App/api/query/Search.graphql';
import Query from '@App/components/api/Query';
import ResultBox from '@App/components/search/ResultBox';
import ResultTabs from '@App/components/search/ResultTabs';
import AnalyticsPlatformTag from '@App/searchAnalytics/AnalyticsPlatformTag';
import AnalyticsTag from '@App/searchAnalytics/AnalyticsTag';

export default class Search extends Component {
    static propTypes = {
        onSearch: PropTypes.func.isRequired,
        onFind: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        mobile: PropTypes.bool.isRequired,
        limit: PropTypes.number.isRequired,
        minChars: PropTypes.number.isRequired,
        includedTags: PropTypes.arrayOf(
            PropTypes.string.isRequired
        ).isRequired,
        excludedTags: PropTypes.arrayOf(
            PropTypes.string.isRequired
        ).isRequired,
        search: PropTypes.string,
    };

    static defaultProps = {
        search: null,
    };

    mapResults(data) {
        const { searchTags, searchTracks, searchAlbums, searchPlaylists } = data;

        return {
            loading: false,
            tags: {
                count: searchTags.totalCount,
                list: searchTags.edges.map(edge => edge.node.tag),
            },
            tracks: {
                count: searchTracks.totalCount,
                list: searchTracks.edges.map(edge => {
                    const { track, highlightResult } = edge.node;
                    const matchedVersions = (highlightResult.versions || [])
                        .filter(version => version.fullyHighlighted)
                        .map(version => version.value);

                    return Object.assign({ matchedVersions }, track);
                }),
            },
            albums: {
                count: searchAlbums.totalCount,
                list: searchAlbums.edges.map(edge => edge.node.album),
            },
            playlists: {
                count: searchPlaylists.totalCount,
                list: searchPlaylists.edges.map(edge => edge.node.playlist),
            },
        };
    }

    render() {
        const { search, limit, minChars, mobile, includedTags, excludedTags, onSearch, onFind, onSubmit } = this.props;

        if (search === null || search.length < minChars) {
            return null;
        }

        const Result = mobile ? ResultTabs : ResultBox;
        const analyticsTags = [AnalyticsPlatformTag.from(mobile), AnalyticsTag.SEARCHBAR];

        return (
            <Query
                component={Result}
                loadingComponent={Result}
                query={SearchQuery}
                variables={{ search, includedTags, excludedTags, limit, analyticsTags }}
                childProps={{ mobile, onSearch, onFind, onSubmit }}
                mapResult={this.mapResults}
            />
        );
    }
}
