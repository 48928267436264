import PropTypes from 'prop-types';
import types from '@App/types';
import { useState } from 'react';
import Loader from '@App/components/ui/Loader';
import I18n from 'i18n-js';
import Nl2br from '@App/components/common/Nl2br';

ChooseLicenseStep.propTypes = {
    licenses: PropTypes.arrayOf(types.License.License.isRequired).isRequired,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    selectedLicense: PropTypes.string,
};

export default function ChooseLicenseStep({ loading, licenses, selectedLicense, onSubmit }) {
    const [value, setValue] = useState(selectedLicense);

    return <>
        {loading && <Loader/>}
        {!loading && licenses.length === 0 && <p>{I18n.t('page.cart.noLicenseAvailable')}</p>}
        {!loading && licenses.length > 0 &&
            <div className="card">
                {licenses.map(license =>
                    <label key={license.id} className="licence-type" htmlFor={`license-input-${license.id}`}>
                        <div className="licence-type__details">
                            <div className="radio">
                                <input
                                    id={`license-input-${license.id}`}
                                    type="radio"
                                    name="license"
                                    value={license.id}
                                    checked={value === license.id}
                                    onChange={e => setValue(e.target.value)}
                                />
                            </div>
                            <div className="details">
                                <span className="title">{license.name}</span>
                                <span className="info"><Nl2br content={license.description} /></span>

                            </div>
                        </div>
                    </label>
                )}
            </div>
        }
        <div className="licence-actions">
            <button type="button" className="btn btn--primary" onClick={() => onSubmit(value)}>
                {I18n.t('page.cart.actions.confirmLicense')}
            </button>
        </div>
    </>;
}
