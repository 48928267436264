import Select from '@App/components/form/Select';

const options = [
    {label: 'Particulier', value: 'individual'},
    {label: 'Professionnel ou Freelance', value: 'professional'},
];

/**
 * Keep file in sync with the Enum `\App\Domain\Entity\User\Type`
 */
export default function SelectUserType(props) {
    return <Select {...props} options={options} />;
}
