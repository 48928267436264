import { connect } from 'react-redux';
import PlayerControl from '@App/components/player/PlayerControl';
import {switchRandomMode, switchLoopMode} from '@App/store/actions/player';

const mapDispatchToProps = (dispatch) => {
    return {
        onSwitchRandomMode: () => dispatch(switchRandomMode()),
        onSwitchLoopMode: () => dispatch(switchLoopMode()),
    };
};
export default connect((state) => ({
    modeRandom: state.player.modeRandom,
    hasStreamingFeature: state.authentication?.permissions?.hasStreamingFeature === true
}), mapDispatchToProps)(PlayerControl);
