import {useDispatch} from 'react-redux';
import I18n from 'i18n-js';
import { downloadPlaylist } from '@App/store/actions/download';
import Button from '@App/components/ui/Button';

interface Props {
  playlistId: string
  className: string
}

export default function DownloadPlaylistButton({playlistId, className}: Props) {
  const dispatch = useDispatch();
  const onClick = () => dispatch(downloadPlaylist(playlistId));

  const label = I18n.t('model.playlist.actions.download');

  return <Button label={label} className={className} onClick={onClick} />;
}
