import I18n from 'i18n-js';
import cover from '@Image/cover.png';

export default class AbstractMetaHandler {
    constructor(origin) {
        this.origin = origin;
    }

    getAbsoluteUrl(path) {
        return `${this.origin}${path}`;
    }

    /**
     * Set title and description
     *
     * @param {String|null} title
     * @param {String|null} description
     * @param {String|null} image
     */
    set(title = null, description = null, image = null) {
        this.title = title || I18n.t('meta._default.title');
        this.description = description || I18n.t('meta._default.description');
        this.image = image || this.getAbsoluteUrl(cover);
    }
}
