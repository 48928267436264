import Label from '@App/components/form/Label';
import Waveform from '@App/components/model/Waveform';
import Button from '@App/components/ui/Button';
import { useAudioApi } from '@App/hooks/useAudioApi';
import { setTime, togglePrioritizeBpm, toggleSuppressVocals } from '@App/store/actions/similarity';
import classNames from 'classnames';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

SimilarResultPlayer.propTypes = {
    source: PropTypes.shape({
        label: PropTypes.string.isRequired,
        duration: PropTypes.number.isRequired,
        audioFileUrl: PropTypes.string.isRequired,
        waveform: PropTypes.array.isRequired,
    }).isRequired,
    setIsLoading: PropTypes.func.isRequired
};

SimilarResultPlayer.DEBOUNCE_DELAY = 900;

export function SimilarResultPlayer({ source, setIsLoading }) {
    const audio = useAudioApi();
    const dispatch = useDispatch();

    const onToggleVocals = () => {
        setIsLoading(true);
        dispatch(toggleSuppressVocals());
    };
    const onToggleBPM = () => {
        setIsLoading(true);
        dispatch(togglePrioritizeBpm());
    };
    const onSegmentUpdated = (offset, limit) => {
        setIsLoading(true);
        dispatch(setTime(offset, limit));
    };

    const [isPlaying, setIsPlaying] = useState(false);
    const [startSelection, setStartSelection] = useState(0);
    const [endSelection, setEndSelection] = useState(0);
    const [playTime, setPlayTime] = useState(0);

    const handlePlayTimeUpdated = useCallback(() => {
        const time = audio.getTime();
        setPlayTime(time);
    }, [audio]);

    /**
     * Loads the source file, if any, on mount.
     */
    useEffect(() => {
        audio.load(source?.audioFileUrl, false);
    }, [audio, source]);

    /**
     * Listen for time updates when playing.
     */
    useEffect(() => {
        if (isPlaying) {
            audio.addEventListener('timeupdate', handlePlayTimeUpdated);
        }

        return () => audio.removeEventListener('timeupdate', handlePlayTimeUpdated);
    }, [audio, isPlaying]);


    const handleSegmentChange = (start, end) => {
        setStartSelection(start);
        setEndSelection(end);
    };

    const handleSearchSegment = () => {
        setIsLoading(true);
        onSegmentUpdated(Math.floor(startSelection), Math.floor(endSelection - startSelection));
    };

    const handleTogglePlay = (audioUrl) => {
        audio.load(audioUrl);
        if (isPlaying) {
            audio.pause();
        } else {
            audio.seek(startSelection);
        }
        setIsPlaying(!isPlaying);
    };

    return <div className="card-similar">
        <div className="card-similar__infos">
            <div className="card-similar__title">{source.label}</div>
            <div>
                <div className="checkbox">
                    <div>
                        <input type="checkbox" id="vocals" onClick={onToggleVocals} />
                        <Label forId="vocals" className="">{I18n.t('similar_results.filters.vocals')}</Label>
                    </div>
                    <Button
                        type="button"
                        tooltip
                        className="tooltip card-similar__tooltip"
                        label={I18n.t('similar_results.tooltip.vocals')}
                    >?</Button>
                </div>
                <div className="checkbox">
                    <div>
                        <input type="checkbox" id="bpm" onClick={onToggleBPM} />
                        <Label forId="bpm" className="">{I18n.t('similar_results.filters.bpm')}</Label>
                    </div>
                    <Button
                        type="button"
                        tooltip
                        className="tooltip card-similar__tooltip"
                        label={I18n.t('similar_results.tooltip.bpm')}
                    >?</Button>
                </div>
            </div>
        </div>

        <div className="card-similar__version">
            <button
                type="button"
                className="card-similar__play"
                style={{}}
                onClick={() => handleTogglePlay(source.audioFileUrl)}
                aria-hidden="true"
            >
                <i key="icon" className={classNames('icon', {
                    'icon--control-pause': isPlaying,
                    'icon--control-play': !isPlaying,
                })}></i>
            </button>
            <div className="card-similar__wave">
                <Waveform
                    id={source.audioFileUrl}
                    audioFileUrl={source.audioFileUrl}
                    data={source.waveform}
                    duration={source.duration}
                    progress={playTime / source.duration}
                    cursor
                    light
                    onSegmentChange={handleSegmentChange}
                    setIsPlaying={setIsPlaying}
                />
                <Button className="card-similar__search" onClick={handleSearchSegment}>Rechercher</Button>
            </div>
        </div>
    </div>;
}
