
/**
 * Find errors at given path from GraphQL error formatted as API Problem violations.
 *
 * @param {Array|null} graphQLErrors
 * @param {String} path
 */
export function findErrorsAtPath(graphQLErrors, path) {
    const errorsAtPath = [];

    (graphQLErrors || []).forEach((graphQLError) => {
        ((graphQLError.api_problem && graphQLError.api_problem.violations) || []).forEach(error => {
            if (error.path === path) {
                errorsAtPath.push(error.reason);
            }
        });
    });

    return errorsAtPath;
}

/**
 * Gather errors by their path.
 *
 * @param {Array|null} graphQLErrors
 * @param {Function} transform Transforms the error. By default to its reason.
 */
export function errorsByPath(graphQLErrors, transform = e => e.reason) {
    const errorsByPath = {};

    (graphQLErrors || []).forEach((graphQLError) => {
        ((graphQLError.api_problem && graphQLError.api_problem.violations) || []).forEach(error => {
            if (!errorsByPath[error.path]) {
                errorsByPath[error.path] = [];
            }

            errorsByPath[error.path].push(transform(error));
        });
    });

    return errorsByPath;
}

const GraphQLUtils = {
    findErrorsAtPath,
    errorsByPath,
};

export default GraphQLUtils;
