import { Component } from 'react';
import PropTypes from 'prop-types';
import ListPlaylistByCategory from '@App/api/query/ListPlaylistByCategory.graphql';
import BreadCrumb from '@App/components/navigation/BreadCrumb';
import Query from '@App/components/api/Query';
import Thumblist from '@App/components/layout/Thumblist';
import PlaylistTile from '@App/components/model/PlaylistTile';
import FeaturedPlaylists from '@App/components/model/FeaturedPlaylists';
import Loader from '@App/components/ui/Loader';

class Playlists extends Component {
    static propTypes = {
        categories: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                playlists: PropTypes.array.isRequired,
            }),
        ),
        loading: PropTypes.bool,
    };

    static defaultProps = {
        categories: [],
        loading: false,
    };

    constructor(props) {
        super(props);

        this.renderCategory = this.renderCategory.bind(this);
        this.renderPlaylist = this.renderPlaylist.bind(this);
    }

    renderCategory(category) {
        const { id, name, playlists } = category;

        return (
            <Thumblist key={id} title={name} >
                {playlists.map(this.renderPlaylist)}
            </Thumblist>
        );
    }

    renderPlaylist(playlist) {
        return <PlaylistTile key={playlist.id} {...playlist} />;
    }

    render() {
        const { categories, loading } = this.props;

        return (
            <div className="content">
                <div className="container-fluid">
                    <BreadCrumb dark />
                    <FeaturedPlaylists />
                    {loading ? <Loader /> : categories.map(this.renderCategory)}
                </div>
            </div>
        );
    }
}

export default function PlaylistsWrapper() {
    return <Query
        component={Playlists}
        loadingComponent={Playlists}
        query={ListPlaylistByCategory}
        mapResult={data => ({ categories: data.playlistCategories })}
        mapLoading={() => ({ loading: true })}
    />;
}
