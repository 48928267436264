import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import classnames from 'classnames';
import GetTagTree from '@App/api/query/GetTagTree.graphql';
import Button from '@App/components/ui/Button';
import OptionItem from '@App/components/ui/OptionItem';
import IncludeTagButton from '@App/components/search/tags/IncludeTagButton';
import IncludeTagOptionItem from '@App/components/search/tags/IncludeTagOptionItem';
import ExcludeTagOptionItem from '@App/components/search/tags/ExcludeTagOptionItem';
import Loader from '@App/components/ui/Loader';
import Query from '@App/components/api/Query';

class LevelTwoTagFilter extends Component {
    static propTypes = {
        ...Wrapper.propTypes,
        children: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                children: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.string.isRequired,
                        name: PropTypes.string.isRequired,
                    }),
                ).isRequired,
            }),
        ).isRequired,
        loading: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            openTag: null,
        };

        this.onOpenTag = this.onOpenTag.bind(this);
        this.renderLevelTwoTag = this.renderLevelTwoTag.bind(this);
    }

    onOpenTag(event) {
        const { openTag } = this.state;
        const id = event.currentTarget.value;

        this.setState({ openTag: openTag === id ? null : id });
    }

    renderLevelThreeTag(tag) {
        const { id, name } = tag;

        return (
            <li key={id}>
                <IncludeTagButton id={id} component={Button} label={name} className="tag-button" includeClassname="include" excludeClassname="exclude" />
                <div className="options">
                    <IncludeTagOptionItem id={id} />
                    <ExcludeTagOptionItem id={id} />
                </div>
            </li>
        );
    }

    renderLevelTwoTag(tag) {
        const { openTag } = this.state;
        const { id, name, children } = tag;
        const active = openTag === id;

        return (
            <li key={id} className={classnames('collapsible', { 'collapsible--show': active })}>
                <div className="collapsible__actions">
                    <IncludeTagButton id={id} component={Button} label={name} className="tag-button" includeClassname="include" excludeClassname="exclude" />
                    <div className="options">
                        <IncludeTagOptionItem id={id} />
                        <ExcludeTagOptionItem id={id} />
                        <OptionItem
                            icon={active ? 'chevron-top' : 'chevron-bottom'}
                            label={I18n.t('search.tag.' + (active ? 'close' : 'expand'))}
                            onClick={this.onOpenTag}
                            value={id}
                            active={active}
                        />
                    </div>
                </div>
                {active ? (
                    <ul className="collapsible__menu">
                        {children.map(this.renderLevelThreeTag)}
                    </ul>
                ) : null}
            </li>
        );
    }

    render() {
        const { loading, children, className } = this.props;

        if (loading) {
            return (
                <ul className={className}>
                    <li className="collapsible"><Loader /></li>
                </ul>
            );
        }

        return (
            <ul className={className}>
                {children.map(this.renderLevelTwoTag)}
            </ul>
        );

    }
}

function mapResult(data) {
    return { children: data.tag.children , loading: false };
}

function mapLoading() {
    return { children: [], loading: true };
}

export default function Wrapper(props) {
    return (
        <Query
            component={LevelTwoTagFilter}
            loadingComponent={LevelTwoTagFilter}
            variables={{ id: props.id }}
            query={GetTagTree}
            mapResult={mapResult}
            mapLoading={mapLoading}
            childProps={props}
        />
    );
}

Wrapper.propTypes = {
    id: PropTypes.string.isRequired,
};
