import { Component } from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { get } from '@App/container';
import Router from '@App/components/Router';
import { redirectToLogin } from '@App/routes';
import RedirectError from '@App/error/RedirectError';
import AccessDeniedError from '@App/error/AccessDeniedError';
import '@App/translations';
import SimilarSearchContextProvider from '@App/components/context/SimilarSearchContext';

type Props = typeof App.defaultProps & {
  handleErrors: boolean
};

interface State {
  error: Error | null
}

export default class App extends Component<Props, State> {
  static defaultProps = {
    handleErrors: true,
  };

  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    if (!this.props.handleErrors) {
      throw error;
    }

    if (error instanceof RedirectError) {
      window.location = error.getUrl();
      return;
    }

    if (error instanceof AccessDeniedError) {
      return redirectToLogin();
    }
  }

  render() {
    return (
      <ApolloProvider client={get('client')}>
        {/* @ts-ignore */}
        <Provider store={get('store')}>
          <SimilarSearchContextProvider>
            <Router globalError={this.state.error} />
          </SimilarSearchContextProvider>
        </Provider>
      </ApolloProvider>
    );
  }
}
