import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@App/components/ui/Button';
import { isLabelActive } from '@App/store/reducers/search';
import { addToLabels, removeFromLabels } from '@App/store/actions/search';

class LabelButton extends Component {
    static propTypes = {
        reference: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        add: PropTypes.func,
        remove: PropTypes.func,
        onUse: PropTypes.func,
    };

    static defaultProps = {
        onUse: null,
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const { active, add, remove, onUse } = this.props;

        active ? remove() : add();

        if (onUse !== null) {
            onUse();
        }
    }

    render() {
        const { reference, name, active } = this.props;

        return <Button label={`${reference} (${name})`} onClick={this.onClick} className={active ? 'include' : ''} />;
    }
}

const Wrapper = connect(
    (state, props) => ({
        active: isLabelActive(state, props.reference),
    }),
    (dispatch, props) => ({
        add: () => dispatch(addToLabels(props.reference)),
        remove: () => dispatch(removeFromLabels(props.reference)),
    })
)(LabelButton);

Wrapper.propTypes = {
    reference: PropTypes.string.isRequired,
};

export default Wrapper;
