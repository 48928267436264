import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import Link from '@App/components/navigation/Link';
import { LICENCE_REQUESTS } from '@App/routes';
import WebReportLicenceRequestForm from '@App/components/model/licenceRequest/WebReportLicenceRequestForm';
import CorporateMovieLicenceRequestForm from '@App/components/model/licenceRequest/CorporateMovieLicenceRequestForm';
import { navigate } from '@App/store/actions/navigation';

class LicenceRequestForm extends Component {
    static propTypes = {
        onSuccess: PropTypes.func.isRequired,
        type: PropTypes.oneOf([
            'WebReportLicenceRequest',
            'CorporateMovieLicenceRequest',
        ]).isRequired,
    };

    getForm() {
        const { type, onSuccess } = this.props;

        switch (type) {
            case 'WebReportLicenceRequest':
                return <WebReportLicenceRequestForm onSuccess={onSuccess} />;

            case 'CorporateMovieLicenceRequest':
                return <CorporateMovieLicenceRequestForm onSuccess={onSuccess} />;

            default:
                throw new Error(`Unsupported type "${type}".`);
        }
    }

    render() {
        return (
            <div className="content form-page">
                <div className="container-fluid">
                    <ul className="breadcrumb breadcrumb--dark">
                        <li className="breadcrumb__item">
                            <Link route={LICENCE_REQUESTS.name} confirm={I18n.t('page.licence_request_form.confirm')} className="link">
                                <i className="icon icon--chevron-left"></i>
                                {I18n.t('page.licence_request_form.back')}
                            </Link>
                        </li>
                    </ul>
                    <h1>{I18n.t('page.licence_request_form.title')}</h1>
                    <hr/>
                    {this.getForm()}
                </div>
            </div>
        );
    }
}

export const CorporateMovieLicenceRequestFormPage = connect(
    () => ({ type: 'CorporateMovieLicenceRequest' }),
    dispatch => ({
        onSuccess: id => dispatch(navigate(LICENCE_REQUESTS.name, undefined, `request-${id}`)),
    })
)(LicenceRequestForm);


export const WebReportLicenceRequestFormPage = connect(
    () => ({ type: 'WebReportLicenceRequest' }),
    dispatch => ({
        onSuccess: id => dispatch(navigate(LICENCE_REQUESTS.name, undefined, `request-${id}`)),
    })
)(LicenceRequestForm);
