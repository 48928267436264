export const RESULT_LIST = 'result_list';
export const SEARCHBAR = 'searchbar';
export const LATEST_TRACKS = 'latest_tracks';

/**
 * @see https://www.algolia.com/doc/api-reference/api-parameters/analyticsTags/
 */
const AnalyticsTag = {
    RESULT_LIST,
    SEARCHBAR,
    LATEST_TRACKS,
};

export default AnalyticsTag;
