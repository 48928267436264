import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { get } from '@App/container';
import ContentPage from '@App/components/page/ContentPage';
import NotFound from '@App/components/page/error/NotFound';
import Album from '@App/components/page/Album';
import Albums from '@App/components/page/Albums';
import Label from '@App/components/page/Label';
import Playlist from '@App/components/page/Playlist';
import PlaylistLegacy from '@App/components/page/PlaylistLegacy';
import Playlists from '@App/components/page/Playlists';
import Library from '@App/components/page/Library';
import SearchFilters from '@App/components/search-filters/SearchFilters';
import ProjectList from '@App/components/page/ProjectList';
import Project from '@App/components/page/Project';
import LicenceRequestList from '@App/components/page/LicenceRequestList';
import LicenceRequestTypeChoice from '@App/components/page/LicenceRequestTypeChoice';
import {
    CorporateMovieLicenceRequestFormPage,
    WebReportLicenceRequestFormPage,
} from '@App/components/page/LicenceRequestForm';
import LicenceRequest from '@App/components/page/LicenceRequest';
import LegalNotices from '@App/components/page/legal/LegalNotices';
import MyAccount from '@App/components/page/MyAccount';
import DenyUnlessAuthenticated from '@App/components/authentication/DenyUnlessAuthenticated';
import {
    ALBUM,
    ALBUMS,
    CART,
    LABEL,
    LABELS,
    LEGAL_NOTICES,
    LIBRARY,
    LICENCE_REQUEST,
    LICENCE_REQUEST_CORPORATE_MOVIE_FORM,
    LICENCE_REQUEST_NEW,
    LICENCE_REQUEST_WEB_REPORT_FORM,
    LICENCE_REQUESTS, ORDER,
    ORDERS,
    PLAYLIST,
    PLAYLIST_LEGACY,
    PLAYLISTS,
    PROFILE,
    PROJECT,
    PROJECTS,
    SUBSCRIPTION,
} from '@App/routes';
import MyCart from '@App/components/page/cart/MyCart';
import OrdersList from '@App/components/page/order/OrdersList';
import Internal from '@App/components/page/error/Internal';
import OrderDetails from '@App/components/page/order/OrderDetails';
import SubscriptionTable from '@App/components/page/account/SubscriptionTable';
import Labels from '@App/components/page/Labels';

class Router extends Component {
    static propTypes = {
        route: PropTypes.string.isRequired,
        parameters: PropTypes.object,
        hasAnchor: PropTypes.bool,
        globalError: PropTypes.any,
    };

    static defaultProps = {
        parameters: {},
        globalError: null,
    };

    constructor(props) {
        super(props);

        this.meta = get('meta');
    }

    componentDidUpdate() {
        const { hasAnchor } = this.props;

        if (!hasAnchor && typeof window !== 'undefined') {
            window.scroll(0, 0);
        }
    }

    setMeta(key = this.props.route) {
        this.meta.set(I18n.t(`meta.${key}.title`),  I18n.t(`meta.${key}.description`));
    }

    render() {
        const { route, parameters, globalError } = this.props;

        if (globalError) {
            return <ContentPage>
                <Internal />
            </ContentPage>;
        }

        switch (route) {
            case ALBUM.name:
                // Meta handled in component
                return <ContentPage><Album {...parameters} /></ContentPage>;

            case ALBUMS.name:
                this.setMeta();
                return <ContentPage><Albums {...parameters} /></ContentPage>;

            case LABELS.name:
                this.setMeta();
                return <ContentPage key={route}><Labels {...parameters} /></ContentPage>;

            case LABEL.name:
                // Meta handled in component
                return <ContentPage><Label {...parameters} /></ContentPage>;

            case PLAYLIST.name:
                // Meta handled in component
                return <ContentPage><Playlist {...parameters} /></ContentPage>;

            case PLAYLIST_LEGACY.name:
                // Meta handled in component
                return <ContentPage><PlaylistLegacy {...parameters} /></ContentPage>;

            case PROFILE.name:
                this.setMeta();

                return <ContentPage><MyAccount {...parameters} /></ContentPage>;

            case PLAYLISTS.name:
                this.setMeta();

                return <ContentPage><Playlists {...parameters} /></ContentPage>;

            case LIBRARY.name:
                this.setMeta();

                return (
                    <ContentPage>
                        <SearchFilters />
                        <Library />
                    </ContentPage>
                );

            case PROJECTS.name:
                this.setMeta();

                return <ContentPage><ProjectList {...parameters} /></ContentPage>;

            case PROJECT.name:
                // Meta handled in component
                return <ContentPage><Project {...parameters} /></ContentPage>;

            case LICENCE_REQUESTS.name:
                this.setMeta();

                return <ContentPage><LicenceRequestList {...parameters} /></ContentPage>;

            case LICENCE_REQUEST_NEW.name:
                this.setMeta();

                return <ContentPage><DenyUnlessAuthenticated><LicenceRequestTypeChoice {...parameters} /></DenyUnlessAuthenticated></ContentPage>;

            case LICENCE_REQUEST_WEB_REPORT_FORM.name:
                this.setMeta('licence_request_form');

                return <ContentPage><DenyUnlessAuthenticated><WebReportLicenceRequestFormPage {...parameters} /></DenyUnlessAuthenticated></ContentPage>;

            case LICENCE_REQUEST_CORPORATE_MOVIE_FORM.name:
                this.setMeta('licence_request_form');

                return <ContentPage><DenyUnlessAuthenticated><CorporateMovieLicenceRequestFormPage {...parameters} /></DenyUnlessAuthenticated></ContentPage>;

            case LICENCE_REQUEST.name:
                return <ContentPage><LicenceRequest {...parameters} /></ContentPage>;

            case ORDERS.name:
                this.setMeta();

                return <ContentPage>
                    <DenyUnlessAuthenticated>
                        <OrdersList {...parameters} />
                    </DenyUnlessAuthenticated>
                </ContentPage>;

            case ORDER.name:
                this.setMeta();

                return <ContentPage allowedToDownload>
                    <DenyUnlessAuthenticated>
                        <OrderDetails {...parameters} />
                    </DenyUnlessAuthenticated>
                </ContentPage>;

            case CART.name:
                this.setMeta();

                return <ContentPage>
                    <DenyUnlessAuthenticated>
                        <MyCart {...parameters} />
                    </DenyUnlessAuthenticated>
                </ContentPage>;

            case LEGAL_NOTICES.name:
                this.setMeta('_default');

                return <ContentPage><LegalNotices {...parameters} /></ContentPage>;

            case SUBSCRIPTION.name:
                this.setMeta();

                return <ContentPage key={route}>
                    <DenyUnlessAuthenticated>
                        <SubscriptionTable />
                    </DenyUnlessAuthenticated>
                </ContentPage>;

            default:
                this.setMeta('_default');

                return <ContentPage><NotFound /></ContentPage>;
        }
    }
}

export default connect(
    state => ({
        route: state.navigation.route,
        parameters: state.navigation.parameters,
        hasAnchor: state.navigation.anchor !== null,
    })
)(Router);
