import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import { downloadTrack } from '@App/store/actions/download';
import Button from '@App/components/ui/Button';
import { click } from '@App/store/actions/searchAnalytics';
import InsightsEvent from '@App/searchAnalytics/insightsEvent';

TrackDownloadButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string,
};

TrackDownloadButton.defaultProps = {
    label: null,
};

function TrackDownloadButton({ onClick, label, ...props }) {
    return <Button label={label ?? I18n.t('model.track.actions.download')} {...props} onClick={onClick}/>;
}

const TrackDownloadButtonWrapper = connect(
    null,
    (dispatch, props) => ({
        onClick: () => {
            dispatch(downloadTrack(props.trackReference, props.versionReference));

            // Generate click analytics event:
            dispatch(click(
                InsightsEvent.TRACK_DOWNLOADED,
                props.trackReference,
                props.searchResultsPosition && props.trackedQueryID,
                props.searchResultsPosition,
            ));
        }
    }),
    (stateProps, dispatchProps, ownProps) => {
        // Do not forward unexpected props to the wrapped component:
        const { ...scopedOwnProps } = ownProps;

        delete scopedOwnProps.dispatch;
        // Algolia insights props:
        delete scopedOwnProps.trackedQueryID;
        delete scopedOwnProps.searchResultsPosition;

        // References props:
        delete scopedOwnProps.trackReference;
        delete scopedOwnProps.versionReference;

        return Object.assign({}, scopedOwnProps, stateProps, dispatchProps);
    }
)(TrackDownloadButton);

TrackDownloadButtonWrapper.propTypes = {
    trackReference: PropTypes.string.isRequired,
    versionReference: PropTypes.string,
    trackedQueryID: PropTypes.string,
    searchResultsPosition: PropTypes.number,
};

TrackDownloadButtonWrapper.defaultProps = {
    versionReference: null,
    trackedQueryID: null,
    searchResultsPosition: null,
};

export default connect((state) => ({
    trackedQueryID: state.search.searchTracksQueryID,
}))(TrackDownloadButtonWrapper);
