import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closeInformationsPanel } from '@App/store/actions/track';
import { PANEL_INFORMATIONS } from '@App/store/reducers/track';
import GetTrackInformations from '@App/api/query/GetTrackInformations.graphql';
import Query from '@App/components/api/Query';
import InformationsPanel from '@App/components/page/InformationsPanel';
import { isPlayerActive } from '@App/store/reducers/player';

function getVersion(versions, versionReference = null) {
    if (!versionReference) {
        return versions.find(version => version.main);
    }

    return versions.find(version => version.reference === versionReference);
}

function mapResult(data, versionReference = null) {
    const { versions, ...properties } = data.track;

    return {
        track: {
            ...properties,
            version: getVersion(versions, versionReference),
        }
    };
}

/**
 * GraphQL wrapped InformationsPanel
 *
 * @see @App/components/container/BackdropContainer to close panel when clicking outside
 * @param {Object} props
 */
function InformationsPanelWrapper(props) {
    const { trackReference, versionReference, onClose, withPlayer } = props;

    if (!trackReference) {
        return null;
    }

    return <Query
        component={InformationsPanel}
        query={GetTrackInformations}
        variables={{ reference: trackReference }}
        mapResult={data => mapResult(data, versionReference)}
        childProps={{ onClose, withPlayer }}
    />;
}

InformationsPanelWrapper.propTypes = {
    onClose: PropTypes.func.isRequired,
    withPlayer: PropTypes.bool.isRequired,
    trackReference: PropTypes.string,
    versionReference: PropTypes.string,
};

InformationsPanelWrapper.defaultProps = {
    trackReference: null,
    versionReference: null,
};

/**
 * Redux drived InformationsPanel
 */
export default connect(
    state => ({
        trackReference: state.track.panel === PANEL_INFORMATIONS ? state.track.trackReference : null,
        versionReference: state.track.panel === PANEL_INFORMATIONS ? state.track.versionReference : null,
        withPlayer: isPlayerActive(state),
    }),
    dispatch => ({
        onClose: () => dispatch(closeInformationsPanel()),
    })
)(InformationsPanelWrapper);
