import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { openSelectBriefPanel } from '@App/store/actions/brief';
import Button from '@App/components/ui/Button';
import I18n from 'i18n-js';

AddToBriefButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string,
};

AddToBriefButton.defaultProps = {
    label: null,
};

function AddToBriefButton({onClick, label, ...props }) {

    return <Button
        label={label ?? I18n.t('model.track.actions.addToBrief')}
        {...props}
        onClick={onClick}
    />;
}

const AddToBriefButtonWrapper = connect(
    null,
    (dispatch, props) => ({
        onClick: () => {
            dispatch(openSelectBriefPanel(props.trackReference, props.versionReference));
        }
    }),
    (stateProps, dispatchProps, ownProps) => {
        // Do not forward unexpected props to the wrapped component:
        const { ...scopedOwnProps } = ownProps;

        delete scopedOwnProps.dispatch;
        delete scopedOwnProps.trackReference;
        delete scopedOwnProps.versionReference;

        return Object.assign({}, scopedOwnProps, stateProps, dispatchProps);
    }
)(AddToBriefButton);

AddToBriefButtonWrapper.propTypes = {
    trackReference: PropTypes.string.isRequired,
    versionReference: PropTypes.string,
};

AddToBriefButtonWrapper.defaultProps = {
    versionReference: null,
    trackReference: null,
};

export default AddToBriefButtonWrapper;
