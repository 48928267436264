import { Component } from 'react';
import PropTypes from 'prop-types';
import Label from '@App/components/form/Label';
import Errors from '@App/components/form/Errors';
import PDFObject from 'pdfobject';

export default class ConditionInput extends Component {

    static propTypes = {
        value: PropTypes.bool,
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        required: PropTypes.bool,
        readOnly: PropTypes.bool,
        fallbackLabel: PropTypes.string,
        errors: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        onChange: PropTypes.func,
    };

    static defaultProps = {
        value: false,
        required: true,
        readOnly: false,
        fallbackLabel: 'Votre navigateur ne peut pas afficher le fichier PDF dans cette page. Cliquez pour accéder au document.',
        errors: [],
        onChange: () => {},
    };

    constructor(props) {
        super(props);

        this.container = null;

        this.setContainer = this.setContainer.bind(this);
        this.hasErrors = this.hasErrors.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    setContainer(container) {
        if (this.container) {
            return;
        }

        this.container = container;

        const { url, fallbackLabel } = this.props;

        try {
            PDFObject.embed(url, this.container, {
                fallbackLink: `<a href="[url]" class="conditions__fallback_link" target="_blank" rel="noopener noreferrer">
                    ${fallbackLabel}
                </a>`,
                pdfOpenParams: { view: 'FitH', pagemode: 'none' },
            });
        } catch {
            return;
        }
    }

    /**
     * @returns {Boolean}
     */
    hasErrors() {
        return [].concat(this.props.errors).length > 0;
    }

    onChange(event) {
        const { onChange } = this.props;
        const { checked } = event.target;

        onChange(checked, event);
    }

    render() {
        const { id, value, label, required, readOnly, errors, ...otherProps } = this.props;

        delete otherProps.onChange;
        delete otherProps.fallbackLabel;
        delete otherProps.url;

        if (readOnly) {
            otherProps.disabled = true;
        }

        return (
            <div className="conditions">
                <div className="conditions__content" ref={this.setContainer} />
                <input
                    id={id}
                    type="checkbox"
                    onChange={this.onChange}
                    className="conditions__input"
                    required={required}
                    checked={value}
                    {...otherProps}
                />
                <Label forId={id} required={required} className="conditions__button">
                    <span className="check"></span>
                    {label}
                </Label>
                <Errors errors={errors} />
            </div>
        );
    }
}
