import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { formatDuration } from '@App/utils';
import SidePanel, { SidePanelSection, SidePanelSubSection} from '@App/components/layout/SidePanel';
export default function VersionsPanel({
    onPlayVersion,
    onClose,
    withPlayer,
    trackReference,
    versions
}) {
    function renderVersion(version) {
        const { reference, label, duration } = version;

        return (
            <li key={reference}>
                <button type="button" className="version" onClick={() => onPlayVersion(trackReference, reference)}>
                    <span className="version__title">
                        <i className="icon icon--control-play"></i>
                        {label}
                    </span>
                    <span className="version__ref">{reference}</span>
                    <span className="version__length">{formatDuration(duration)}</span>
                </button>
            </li>
        );
    }

    return <SidePanel
        id="version-panel"
        direction="left"
        title={I18n.t('panel.versions.title', { count: versions.length })}
        onClose={onClose}
        withPlayer={withPlayer}
        active
    >
        <SidePanelSection>
            <ul className="list">{versions.map(renderVersion)}</ul>
        </SidePanelSection>
    </SidePanel>;
}

VersionsPanel.propTypes = {
    onPlayVersion: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    withPlayer: PropTypes.bool.isRequired,
    trackReference: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(
        PropTypes.shape({
            reference: PropTypes.string.isRequired,
            main: PropTypes.bool.isRequired,
            label: PropTypes.string.isRequired,
            duration: PropTypes.number.isRequired,
            iswc: PropTypes.string,
            isrc: PropTypes.string,
        }).isRequired
    ).isRequired,
    writers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    publishers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
};
