import { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import SearchTracks from '@App/api/query/SearchTracks.graphql';
import Query from '@App/components/api/Query';
import TrackVersionList, { mapTrackToVersion } from '@App/components/model/TrackVersionList';
import BlockTitle from '@App/components/layout/BlockTitle';
import Button from '@App/components/ui/Button';
import Loader from '@App/components/ui/Loader';
import { saveSearchTracksQueryID } from '@App/store/actions/search';
import AnalyticsPlatformTag from '@App/searchAnalytics/AnalyticsPlatformTag';
import AnalyticsTag from '@App/searchAnalytics/AnalyticsTag';
import ShowAllTagsButton from '@App/components/model/track/ShowAllTagsButton';
import { SIZE_LG } from '@App/service/MobileWatcher';

ResultList.propTypes = {
    count: PropTypes.number.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    versions: TrackVersionList.propTypes.versions,
    onLoadMore: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

function ResultList ({ loading, versions, count, hasNextPage, onLoadMore }) {
    const title = loading ? I18n.t('results.loading') : I18n.t('results.title', { count });
    const [loadingMore, setLoadingMore] = useState(false);
    const onClick = () => {
        setLoadingMore(true);
        onLoadMore().finally(() => setLoadingMore(false));
    };

    return <Fragment>
        <BlockTitle title={title}>
            <ShowAllTagsButton />
        </BlockTitle>
        {loading ? <div><Loader /></div> : <TrackVersionList versions={versions} />}
        {hasNextPage ? <Button loading={loadingMore} onClick={onClick} label={I18n.t('results.more')} /> : null}
    </Fragment>;
}

function mapResult(data) {
    return {
        hasNextPage: data.searchTracks.pageInfo.hasNextPage,
        count: data.searchTracks.totalCount,
        versions: data.searchTracks.edges.map(edge => mapTrackToVersion(edge.node.track)),
        loading: false,
    };
}

function mapLoading() {
    return {
        hasNextPage: false,
        count: 0,
        versions: [],
        loading: true,
    };
}

function ResultListWrapper({
    terms,
    includedTags,
    excludedTags,
    labels,
    minDuration,
    maxDuration,
    limit,
    onCompleted,
    mobile,
}) {
    return (
        <Query
            component={ResultList}
            loadingComponent={ResultList}
            query={SearchTracks}
            variables={{
                search: terms.join(' '),
                includedTags,
                excludedTags,
                labels,
                minDuration,
                maxDuration,
                limit,
                analyticsTags: [AnalyticsPlatformTag.from(mobile), AnalyticsTag.RESULT_LIST],
            }}
            mapResult={mapResult}
            mapLoading={mapLoading}
            loadMore="searchTracks"
            onCompleted={onCompleted}
        />
    );
}

ResultListWrapper.propTypes = {
    terms: PropTypes.arrayOf(
        PropTypes.string.isRequired
    ).isRequired,
    includedTags: PropTypes.arrayOf(
        PropTypes.string.isRequired
    ).isRequired,
    excludedTags: PropTypes.arrayOf(
        PropTypes.string.isRequired
    ).isRequired,
    labels: PropTypes.arrayOf(
        PropTypes.string.isRequired
    ).isRequired,
    minDuration: PropTypes.number,
    maxDuration: PropTypes.number,
    limit: PropTypes.number,
    mobile: PropTypes.bool.isRequired,
    onCompleted: PropTypes.func.isRequired,
};

ResultListWrapper.defaultProps = {
    limit: 30,
};

export default connect(
    state => ({
        terms: state.search.terms,
        includedTags: state.search.includedTags,
        excludedTags: state.search.excludedTags,
        labels: state.search.labels,
        minDuration: state.search.minDuration,
        maxDuration: state.search.maxDuration,
        mobile: state.navigation.size < SIZE_LG,
    }),
    dispatch => ({
        onCompleted: (data) => dispatch(saveSearchTracksQueryID(data.searchTracks.queryID)),
    })
)(ResultListWrapper);
