import { Component } from 'react';
import PropTypes from 'prop-types';

export default class CardHeader extends Component {
    static propTypes = {
        title: PropTypes.node.isRequired,
        subtitle: PropTypes.node,
        actions: PropTypes.arrayOf(
            PropTypes.node
        ),
    };

    static defaultProps = {
        subtitle: null,
        actions: [],
    };

    render() {
        const { title, subtitle, actions } = this.props;

        return (
            <div className="card__header">
                <div className="info">
                    <span className="title">{title}</span>
                    {subtitle !== null && <span className="subtitle lg-on xxl-on">{subtitle}</span>}
                </div>
                <div className="action">
                    {actions}
                </div>
            </div>
        );
    }
}
