import { useMutation, useQuery } from '@apollo/client';
import MyCart from '@App/api/query/cart/MyCart.graphql';
import SubmitCart from '@App/api/mutation/cart/SubmitCart.graphql';
import AddToCart from '@App/api/mutation/cart/AddToCart.graphql';
import RemoveFromCart from '@App/api/mutation/cart/RemoveFromCart.graphql';
import { useCallback, useMemo } from 'react';
import { onAddTrackToCart, onRemoveTrackFromCart, onSubmitCart } from '@App/api/store';

export function useCart() {
    const { error, loading, data = []} = useQuery(MyCart);

    const tracks = useMemo(() => {
        if (loading || error) {
            return [];
        }

        return (data.cart ?? []).map(track => {
            return {
                reference: track.reference,
                title: track.title,
                // Alias cover field
                cover: track.album.coverAsThumbnail,
            };
        });
    }, [data, error, loading]);

    const hasReference = useCallback(reference => {
        return tracks.find(item => item.reference === reference);
    }, [tracks]);

    return {
        loading,
        error,
        /** Normalized tracks in cart */
        tracks,
        /** Nb of items in cart */
        count: tracks.length,
        /** Check if a track reference is in cart */
        hasReference,
    };
}

export function useAddTrackToCart() {
    const [mutate, { loading, error }] = useMutation(AddToCart);

    const addToCart = useCallback(async (reference) => {
        await mutate({
            variables: {
                reference
            },
            update: (store, { data }) => onAddTrackToCart(store, data.cartAddTrack),
        });
    }, [mutate]);

    return {
        /** @returns {Promise} */
        addToCart,
        loading,
        error,
    };
}

export function useRemoveTrackFromCart() {
    const [mutate, { loading, error }] = useMutation(RemoveFromCart);

    const removeFromCart = useCallback(async (reference) => {
        await mutate({
            variables: {
                reference
            },
            update: (store, { data }) => onRemoveTrackFromCart(store, data.cartRemoveTrack),
        });
    }, [mutate]);

    return {
        /** @returns {Promise} */
        removeFromCart,
        loading,
        error,
    };
}

export function useSubmitCart(licenseId, projectName) {
    const [mutate, { loading, error, data }] = useMutation(SubmitCart);

    const submit = useCallback(async () => {
        const response = await mutate({
            variables: {
                licenseId,
                projectName,
            },
            update: onSubmitCart,
        });

        const { data } = response;

        return data.cartTransformToOrder;
    }, [licenseId, mutate, projectName]);

    return {
        /** @returns {Promise<{ order: Object, paymentLink: String! }>} */
        submit,
        loading,
        error,
        data,
    };
}
