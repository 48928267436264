import PropTypes from 'prop-types';
import types from '@App/types';
import { formatEur, formatPercent } from '@App/utils/numbers';
import I18n from 'i18n-js';
import TextInput from '@App/components/form/TextInput';
import HubspotLink from '@App/components/navigation/HubspotLink';
import { HUBSPOT_PAGES } from '@App/routes';
import Button from '@App/components/ui/Button';
import { useState } from 'react';
import CartList from '@App/components/page/cart/CartList';
import Loader from '@App/components/ui/Loader';

ConfigureProjectStep.propTypes = {
    tracks: PropTypes.arrayOf(types.Cart.CartItem.isRequired).isRequired,
    license: types.License.License.isRequired,
    projectName: PropTypes.string,
    setProjectName: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onModifyLicense: PropTypes.func.isRequired,
    submitLoading: PropTypes.bool.isRequired,
    submitError: PropTypes.bool.isRequired,
};

ConfigureProjectStep.defaultProps = {
    projectName: null,
};

export default function ConfigureProjectStep({
    tracks,
    license,
    projectName,
    setProjectName,
    onSubmit,
    onModifyLicense,
    submitLoading,
    submitError,
}) {
    const totalPriceHT = license.pricePerTrack * tracks.length;
    const totalPriceTax = totalPriceHT * license.taxRate / 100;
    const totalPriceTTC = totalPriceHT + totalPriceTax;

    const buttonLabel = submitLoading ? <Loader /> : I18n.t('page.cart.steps.project.actions.purchase');

    const [cgvAccepted, setCgvAccepted] = useState(false);
    const [termAccepted, setTermAccepted] = useState(false);

    return <div className="cart-step-project">
        <CartList tracks={tracks} PriceComponent={
            <span>{formatEur(license.pricePerTrack)}</span>
        }/>
        <form id="form-configure-project" onSubmit={(e) => {
            e.preventDefault();

            if (!cgvAccepted || !termAccepted) {
                return;
            }

            onSubmit();
        }}>
            <div className="card card--licence">
                <div className="licence-type licence-type--form">
                    <div className="licence-type__main">
                        <div className="licence-type__details">
                            <div className="details">
                                <span className="title">{I18n.t('page.cart.steps.project.selectedLicense', {
                                    licenseName: license.name,
                                })}</span>
                                <span className="info">
                                    {license.description}
                                </span>
                            </div>
                        </div>
                        <div className="licence-type__actions">
                            <button type="button" className="link link--light" onClick={() => onModifyLicense()}>
                                {I18n.t('page.cart.steps.project.actions.modifyLicense')}
                            </button>
                        </div>
                    </div>
                    <div className="licence-type__input">

                        <TextInput
                            label={I18n.t('page.cart.steps.project.form.projectName')}
                            id="project-name"
                            onChange={value => setProjectName(value)}
                            value={projectName}
                            required
                        />
                    </div>
                </div>
                <div className="licence-type licence-type--form">
                    <div className="licence-type__total">
                        <div className="licence-type__taxes">
                            <span>TVA ({formatPercent(license.taxRate)})</span>
                            <span className="price">{formatEur(totalPriceTax)}</span>
                        </div>
                        <div className="licence-type__price">
                            <span>Total TTC </span>
                            <span className="price">{formatEur(totalPriceTTC)}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <label className="licence-type" htmlFor="accept-cgv">
                    <div className="licence-type__details">
                        <div className="radio">
                            <input
                                id="accept-cgv"
                                type="checkbox"
                                name="cgv"
                                checked={cgvAccepted}
                                required
                                onChange={e => setCgvAccepted(e.target.checked)}
                            />
                        </div>
                        <div className="details">
                            <span className="info" dangerouslySetInnerHTML={{
                                __html: I18n.t('page.cart.steps.project.cgv', {
                                    url: HubspotLink.getUrl(HUBSPOT_PAGES.CGL),
                                })
                            }}></span>
                        </div>
                    </div>
                </label>
                <label className="licence-type" htmlFor="accept-terms">
                    <div className="licence-type__details">
                        <div className="radio">
                            <input
                                id="accept-terms"
                                type="checkbox"
                                name="terms"
                                checked={termAccepted}
                                required
                                onChange={e => setTermAccepted(e.target.checked)}
                            />
                        </div>
                        <div className="details">
                            <span className="info">{ I18n.t('page.cart.steps.project.terms') }</span>
                        </div>
                    </div>
                </label>
            </div>
            <div className="licence-actions">
                <Button
                    type="submit"
                    className="btn btn--primary"
                    label={buttonLabel}
                    disabled={submitLoading}
                > </Button>
                <HubspotLink route={HUBSPOT_PAGES.DEVIS} className="btn" target="_blank">
                    {I18n.t('page.cart.steps.project.actions.askQuotation')}
                </HubspotLink>
                {submitError && <div className="alert alert--error mt-3">
                    {I18n.t('page.cart.steps.project.submitError')}
                </div>}
            </div>

        </form>
    </div>;
}
