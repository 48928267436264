import { createElement, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Dropover extends Component {
    static propTypes = {
        button: PropTypes.func.isRequired,
        up: PropTypes.bool,
        children: PropTypes.node,
        className: PropTypes.string,
    };

    static deaultProps = {
        up: false,
        children: null,
        className: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };

        this.onClick = this.onClick.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onBlur);
    }

    componentDidUpdate(prevProps, prevState) {
        const { open } = this.state;

        if (open !== prevState.open) {
            if (open) {
                setTimeout(() => window.addEventListener('click', this.onBlur), 0);
            } else {
                window.removeEventListener('click', this.onBlur);
            }
        }
    }

    onClick() {
        this.setOpen(!this.state.open);
    }

    onBlur() {
        this.setOpen(false);
    }

    setOpen(open) {
        this.setState({ open });
    }

    render() {
        const { button, up, children, className } = this.props;
        const { open } = this.state;

        if (!children) {
            return null;
        }

        return (
            <div className={classNames('dropover', className, {
                'dropover--show': open,
                'dropover--up': up,
            })}>
                {createElement(button, { onClick: this.onClick, className: 'dropover__button' })}
                <div className="dropover__menu">
                    {children}
                </div>
            </div>
        );
    }
}

