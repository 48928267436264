import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import SidePanel, { SidePanelSection } from '@App/components/layout/SidePanel';
import HasSimultaneousLogins from '@App/api/query/HasSimultaneousLogins.graphql';
import { useQuery } from '@apollo/client';
import { LOGIN, REGISTER } from '@App/routes';

const POLL_DELAY = 60 * 1000; // One minute
// const POLL_DELAY = 10 * 1000; // Use this interval value when WIP (10s)

SimultaneousLoginsSpottedPanel.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    isImpersonator: PropTypes.bool.isRequired,
    onSimultaneousLoginsSpotted: PropTypes.func.isRequired,
    areSimultaneousLoginsSpotted: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

/**
 * Side panel displayed when simultaneous logins with the same username have been spotted.
 * On simultaneous logins spotted, current user is disconnected, then this panel is displayed.
 *
 * @see @App/api/query/HasSimultaneousLogins.graphql the GraphQL query that checks if simultaneous logins exist
 * @see @App/components/container/BackdropContainer to close panel when clicking outside
 */
export default function SimultaneousLoginsSpottedPanel({
    authenticated,
    isImpersonator,
    onSimultaneousLoginsSpotted,
    areSimultaneousLoginsSpotted,
    onClose,
}) {
    const { data } = useQuery(HasSimultaneousLogins, {
        skip: !authenticated || isImpersonator,
        pollInterval: POLL_DELAY,
    });

    useEffect(() => {
        if (data?.hasSimultaneousLogins === true) {
            onSimultaneousLoginsSpotted();
        }
    }, [onSimultaneousLoginsSpotted, data]);

    return (
        <SidePanel
            id="simultaneous-logins-spotted-panel"
            direction="right"
            title={I18n.t('panel.simultaneous-logins.title')}
            onClose={onClose}
            active={areSimultaneousLoginsSpotted}
        >
            <SidePanelSection title={I18n.t('panel.simultaneous-logins.subtitle')}>
                <br/>
                <p>
                    <i className="icon icon--chevron-right" />&nbsp;
                    {I18n.t('panel.simultaneous-logins.paragraph-1.question')}&nbsp;
                    <a href={LOGIN.getUrl()}>
                        {I18n.t('panel.simultaneous-logins.paragraph-1.action')}
                    </a>
                </p>
                <p>
                    <i className="icon icon--chevron-right" />&nbsp;
                    {I18n.t('panel.simultaneous-logins.paragraph-2.question')}&nbsp;
                    <a href={REGISTER.getUrl()}>
                        {I18n.t('panel.simultaneous-logins.paragraph-2.action')}
                    </a>
                </p>
                <p>
                    <i className="icon icon--chevron-right" />&nbsp;
                    {I18n.t('panel.simultaneous-logins.paragraph-3.question')}&nbsp;
                    <a href="https://www.musique-music.com/nous-contacter">
                        {I18n.t('panel.simultaneous-logins.paragraph-3.action')}
                    </a>
                </p>
            </SidePanelSection>
        </SidePanel>
    );
}
