import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import SidePanel, { SidePanelSection } from '@App/components/layout/SidePanel';
import BriefItem from '@App/components/page/brief/BriefItem';

class SelectBriefPanel extends Component {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        trackReference: PropTypes.string,
        versionReference: PropTypes.string,
        briefs: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            }).isRequired
        ).isRequired,
    };

    static defaultProps = {
        versionReference: null,
        trackReference: null,
        briefs: [],
    };

    render() {
        const { onClose, briefs, versionReference } = this.props;

        return (
            <SidePanel
                id="select-brief-panel"
                direction="right"
                title={I18n.t('panel.brief.add-track.title')}
                onClose={onClose}
                active={versionReference !== null}
            >
                <SidePanelSection title={I18n.t('panel.brief.add-track.select')}>
                    <ul className="brief-list">
                        {briefs.map((brief, index) => {
                            return <BriefItem key={`${index}-${versionReference}`} brief={brief} versionReference={versionReference} />;
                        })}
                    </ul>
                </SidePanelSection>
            </SidePanel>
        );
    }
}

const SelectBriefPanelWrapper = connect(
    null,
    null,
    (stateProps, dispatchProps, ownProps) => {
        // Do not forward unexpected props to the wrapped component:
        const { ...scopedOwnProps } = ownProps;

        delete scopedOwnProps.dispatch;

        return Object.assign({}, scopedOwnProps, stateProps, dispatchProps);
    }
)(SelectBriefPanel);

SelectBriefPanelWrapper.propTypes = {
    trackReference: PropTypes.string,
    versionReference: PropTypes.string,
};

export default SelectBriefPanelWrapper;
