import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isSearching } from '@App/store/reducers/search';
import { isSearching as isSimilaritySearching } from '@App/store/reducers/similarity';
import ResultList from '@App/components/page/library/ResultList';
import SimilarResultList from '@App/components/page/library/SimilarResultList';
import FeaturedPlaylists from '@App/components/model/FeaturedPlaylists';
import LatestReleases from '@App/components/model/LatestReleases';

class Library extends Component {
    static propTypes = {
        searching: PropTypes.bool.isRequired,
        similaritySearching: PropTypes.bool.isRequired,
    };

    renderContent() {
        const { searching, similaritySearching } = this.props;

        if (searching) {
            return <ResultList />;
        }

        if (similaritySearching) {
            return <SimilarResultList />;
        }

        return (
            <Fragment>
                <FeaturedPlaylists key="featured-playlist" seeAll />
                <LatestReleases key="latest-releases" />
            </Fragment>
        );
    }

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    {this.renderContent()}
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        searching: isSearching(state),
        similaritySearching: isSimilaritySearching(state),
    }),
)(Library);
