import { Component } from 'react';
import PropTypes from 'prop-types';
import Link from '@App/components/navigation/Link';

export default class BreadCrumbItem extends Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        route: PropTypes.string.isRequired,
        parameters: PropTypes.object,
    };

    static defaultProps = {
        parameters: {},
    };

    render() {
        const { label, route, parameters } = this.props;

        return (
            <li className="breadcrumb__item">
                <Link route={route} parameters={parameters}>
                    {label}
                </Link>
            </li>
        );
    }
}
