import I18n from 'i18n-js';
import TrackDownloadButton from '@App/components/model/track/TrackDownloadButton';
import PropTypes from 'prop-types';
import types from '@App/types';

OrderDetailsList.propTypes = {
    tracks: PropTypes.arrayOf(types.Order.Details.OrderItem.isRequired).isRequired,
    canDownload: PropTypes.bool.isRequired,
};

export default function OrderDetailsList({ tracks, canDownload }) {
    return <div className="card card--licence">
        {tracks.map(track => <Row orderItem={track} key={track.reference} canDownload={canDownload}/>)}
    </div>;
}

Row.propTypes = {
    orderItem: types.Order.Details.OrderItem.isRequired,
    canDownload: PropTypes.bool.isRequired,
};

function Row({ orderItem, canDownload }) {
    const { reference, title, cover, licenseFile } = orderItem;

    return <div className="track track--cart">
        <div className="track__main">
            <div className="track__details">
                <div className="cover" style={{ backgroundImage: `url(' ${cover}')` }} title={title}></div>
                <div className="details">
                    <span className="title">{title}</span>
                    <span className="info">{reference}</span>
                </div>
            </div>
            <div className="track__actions">
                {canDownload && <>
                    <a className="btn" href={licenseFile} target="_blank" rel="noreferrer">
                        {I18n.t('page.order.actions.downloadLicense')}
                    </a>

                    <TrackDownloadButton
                        label={I18n.t('page.order.actions.downloadTrack')}
                        trackReference={reference}
                        className="btn"
                    />
                </>}
            </div>
        </div>
    </div>;
}
