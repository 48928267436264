import { Component } from 'react';
import PropTypes from 'prop-types';

export default class HubspotLink extends Component {

    static propTypes = {
        route: PropTypes.string.isRequired,
        children: PropTypes.node,
    };

    render() {
        const { route, children, ...props } = this.props;
        const href = HubspotLink.getUrl(route);

        delete props.dispatch;

        return (
            <a href={href} {...props}>
                {children}
            </a>
        );
    }

    static getUrl(route) {
        return new URL(route, 'https://www.musique-music.com');
    }
}
