import PropTypes from 'prop-types';

export const GraphQLError = PropTypes.shape({
    message: PropTypes.string.isRequired,
    networkError: PropTypes.object,
    extraInfo: PropTypes.object,
    graphQLErrors: PropTypes.arrayOf(
        PropTypes.object
    ),
});

export const FieldErrors = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
]);
