import PropTypes from 'prop-types';
import BreadCrumbItem from '@App/components/navigation/BreadCrumbItem';
import LoadingBreadCrumbItem from '@App/components/navigation/LoadingBreadCrumbItem';
import GetAlbumBreadCrumb from '@App/api/query/GetAlbumBreadCrumb.graphql';
import Query from '@App/components/api/Query';
import { ALBUM, LABEL } from '@App/routes';

export default function AlbumBreadCrumbItem(props) {
    const { reference } = props;

    return <>
        <Query
            component={BreadCrumbItem}
            loadingComponent={LoadingBreadCrumbItem}
            query={GetAlbumBreadCrumb}
            variables={{ reference }}
            mapResult={data => ({
                label: data.album.label.name,
                parameters: {
                    reference: data.album.label.reference,
                    slug: data.album.label.slug,
                }
            })}
            childProps={{
                route: LABEL.name,
            }}
        />
        <Query
            component={BreadCrumbItem}
            loadingComponent={LoadingBreadCrumbItem}
            query={GetAlbumBreadCrumb}
            variables={{ reference }}
            mapResult={data => ({ label: data.album.title })}
            childProps={{
                route: ALBUM.name,
                parameters: { reference }
            }}
        />
    </>;
}

AlbumBreadCrumbItem.propTypes = {
    reference: PropTypes.string.isRequired,
};
