import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import EditMyUserInfo from '@App/components/page/account/EditMyUserInfo';
import EditMyUserPreferences from '@App/components/page/account/EditMyUserPreferences';
import ChangePassword from '@App/components/page/account/ChangePassword';
import Query from '@App/components/api/Query';
import GetMe from '@App/api/query/GetMe.graphql';
import AccessDeniedError from '@App/error/AccessDeniedError';
import SubscriptionInfo from '@App/components/page/account/SubscriptionInfo';

class MyAccount extends Component {
    static propTypes = {
        profile: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            country: PropTypes.string,
            type: PropTypes.string.isRequired,
            companyName: PropTypes.string,
            companySiret: PropTypes.string,
            sector: PropTypes.string,
            job: PropTypes.string,
        }).isRequired,
    };

    static defaultProps = {};

    render() {
        const { profile } = this.props;

        return (
            <div className="content">
                <div className="container-fluid form-page">
                    <h1>{I18n.t('page.my_account.title')}</h1>
                    <hr/>

                    <EditMyUserInfo childProps={{ profile }}/>
                    <ChangePassword />
                    <EditMyUserPreferences childProps={{ profile }}/>
                    <SubscriptionInfo profile={profile} />
                </div>
            </div>
        );
    }
}

function Wrapper(props) {
    if (!props.profile) {
        throw new AccessDeniedError('You must login to access your profile');
    }

    return <MyAccount {...props} />;
}

Wrapper.propTypes = {
    profile: PropTypes.object,
};

Wrapper.defaultProps = {
    profile: null,
};

export default function MyAccountWithProfileQuery() {
    return <Query component={Wrapper} query={GetMe} mapResult={data => ({ profile: data.me })}/>;
}
