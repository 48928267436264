import { createElement, Component } from 'react';
import PropTypes from 'prop-types';

export default class AbstractTagButton extends Component {
    static propTypes = {
        component: PropTypes.func.isRequired,
        active: PropTypes.bool.isRequired,
        add: PropTypes.func,
        remove: PropTypes.func,
        onUse: PropTypes.func,
    };

    static defaultProps = {
        onUse: null,
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const { active, add, remove, onUse } = this.props;

        active ? remove() : add();

        if (onUse !== null) {
            onUse();
        }
    }

    render() {
        const { component, ...props } = this.props;

        delete props.add;
        delete props.remove;
        delete props.onUse;
        delete props.includeClassname;
        delete props.excludeClassname;

        if (typeof component.propTypes?.active === 'undefined') {
            delete props.active;
        }

        return createElement(component, { ...props, onClick: this.onClick });
    }
}
