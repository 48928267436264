import PropTypes from 'prop-types';
import Select from '@App/components/form/Select';
import Query from '@App/components/api/Query';
import GetCountries from '@App/api/query/GetCountries.graphql';
import LoadingInput from '@App/components/form/LoadingInput';

function SelectCountryISO(props) {
    const { countries, ...childProps } = props;

    return <Select {...childProps} options={countries.map(country => ({
        label: country.label,
        value: country.code,
    }))} />;
}

SelectCountryISO.propTypes = {
    countries: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }.isRequired).isRequired),
};

SelectCountryISO.defaultProps = {
    countries: null,
};

export default function SelectCountryISOWithQuery(props) {
    return <Query component={SelectCountryISO}
        query={GetCountries}
        childProps={props}
        loadingComponent={LoadingInput}
        mapResult={result => result}
    />;
}
