import { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import {onAddProject} from '@App/api/store';
import ProjectCreateAndAddTrack from '@App/api/mutation/ProjectCreateAndAddTrack.graphql';
import Mutation from '@App/components/api/Mutation';
import Button from '@App/components/ui/Button';
import classNames from 'classnames';
import { withAnalyticsOnClickHandler, withTrackedQueryID } from '@App/searchAnalytics/withAnalytics';
import {useDispatch} from 'react-redux';
import {closeSelectProjectPanel} from '@App/store/actions/project';

AddToNewProjectButton.propTypes = {
    versionReference: PropTypes.string,
    mutate: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    errors: PropTypes.object,
    onClick: PropTypes.func,
};

AddToNewProjectButton.defaultProps = {
    loading: false,
    success: null,
    errors: null,
    onClick: () => {},
};

function AddToNewProjectButton({
    loading,
    versionReference,
    mutate,
    onClick,
}) {
    const dispatch = useDispatch();
    const mutationCallback = useCallback(async () => {
        await mutate({version: versionReference},
            (store, { data }) => {
                onAddProject(store, data.projectCreateAndAddTrack);
            },
        );
    }, [mutate, versionReference]);

    function handleClick() {
        if (loading) {
            return;
        }

        mutationCallback().then(() => {
            setTimeout(() => dispatch(closeSelectProjectPanel()), 1000);
        });

        onClick();
    }

    function renderLabel(loading) {
        if (loading) {
            return '';
        }

        return <Fragment>
            <i key="icon" className="icon icon--add"></i>
            {I18n.t('model.track.actions.addToNewProject')}
        </Fragment>;
    }

    return <li className={classNames('project-add')}>
        <Button
            label={renderLabel(loading)}
            loading={loading}
            onClick={handleClick}
            className="link--primary text-ellipsis"
        />
    </li>;
}

const WithAnalyticsWrapper = withAnalyticsOnClickHandler(AddToNewProjectButton);
const WithTrackedQuery = withTrackedQueryID(WithAnalyticsWrapper);

export default function AddToNewProjectButtonWrapper(props) {
    return <Mutation
        mutation={ProjectCreateAndAddTrack}
        component={WithTrackedQuery}
        loadingComponent={WithTrackedQuery}
        mapResult={() => ({ success: true })}
        mapLoading={() => ({ loading: true, success: null })}
        childProps={props}
    />;
}

AddToNewProjectButtonWrapper.propTypes = {
    versionReference: PropTypes.string,
    searchResultsPosition: PropTypes.number,
    trackReference: PropTypes.string,
};
