import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { get } from '@App/container';
import Link from '@App/components/navigation/Link';
import { LABEL } from '@App/routes';
import ShowAlbum from '@App/api/query/ShowAlbum.graphql';
import Query from '@App/components/api/Query';
import PageHeader from '@App/components/layout/PageHeader';
import WriterList from '@App/components/page/album/WriterList';
import ShareAlbumButton from '@App/components/model/share/ShareAlbumButton';
import ShowAllTagsButton from '@App/components/model/track/ShowAllTagsButton';
import Loader from '@App/components/ui/Loader';
import Card from '@App/components/layout/Card';
import TrackVersionList, { mapTrackToVersion } from '@App/components/model/TrackVersionList';

class Album extends Component {
    static propTypes = {
        reference: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        coverAsHero: PropTypes.string.isRequired,
        trackVersions: PropTypes.array.isRequired,
        label: PropTypes.shape({
            reference: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
        releaseDate: PropTypes.string.isRequired,
    };

    render() {
        const { Image, Title, Description } = PageHeader;
        const { reference, title, description, coverAsHero, trackVersions, writers, label, releaseDate } = this.props;
        const trackNumber = I18n.t('model.track.countTracks', { count: trackVersions.length });
        const actions = [
            <ShowAllTagsButton key="show-tags" />,
            <ShareAlbumButton key="share" reference={reference} />,
        ];
        const linkToLabel = {
            route: LABEL.name,
            parameters: { reference: label.reference, slug: label.slug },
        };

        return (
            <div className="content">
                <PageHeader>
                    <Card>
                        <div className="header__content">
                            <Image src={coverAsHero} alt={title} />
                            <div className="info">
                                <Title>{title}</Title>
                                <p>{reference} | {trackNumber} | {I18n.t('model.album.releasedAt', { date: I18n.l('date.formats.full', releaseDate) })}</p>
                                <Description>{description}</Description>
                                <Description>
                                    <p>{I18n.t('model.album.writtenBy')} <WriterList writers={writers} /></p>
                                    <p>{I18n.t('model.album.label')} <Link {...linkToLabel} className="link link--primary">{label.name}</Link></p>
                                </Description>
                            </div>
                        </div>
                        <div className="header__actions">
                            {actions}
                        </div>
                    </Card>
                </PageHeader>
                <div className="container-fluid">
                    <Card>
                        <TrackVersionList versions={trackVersions} />
                    </Card>
                </div>
            </div>
        );
    }
}

function LoadingAlbum() {
    const { Title, Description } = PageHeader;

    return (
        <div className="content">
            <PageHeader>
                <Card>
                    <Title><Loader /></Title>
                    <Description />
                </Card>
            </PageHeader>
            <div className="container-fluid">
                <Card title="" loading />
            </div>
        </div>
    );
}

function MetaWrapper(props) {
    const { title, description, label, coverAsHero } = props;

    get('meta').set(
        I18n.t('meta.content.album.title', { album: title, label: label.name }),
        description,
        coverAsHero
    );

    return <Album {...props} />;
}

MetaWrapper.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    label: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
    coverAsHero: PropTypes.string.isRequired,
};

function mapResult(data) {
    const { album } = data;

    return {
        ...album,
        tracks: undefined,
        trackVersions: album.tracks.map(track => mapTrackToVersion(track)),
    };
}

export default function AlbumWrapper(props) {
    const { reference } = props;

    return (
        <Query
            component={MetaWrapper}
            loadingComponent={LoadingAlbum}
            query={ShowAlbum}
            variables={{ reference }}
            mapResult={mapResult}
            childProps={{ reference }}
        />
    );
}

AlbumWrapper.propTypes = {
    reference: PropTypes.string.isRequired,
};
