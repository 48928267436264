import Backdrop from '@App/components/layout/Backdrop';
import { closeSimultaneousLoginsSpottedPanel } from '@App/store/actions/authentication';
import { closeSelectBriefPanel } from '@App/store/actions/brief';
import { closeDownloadPanel } from '@App/store/actions/download';
import { closeSelectProjectPanel } from '@App/store/actions/project';
import { closeInformationsPanel, closeVersionsPanel } from '@App/store/actions/track';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Backdrop container for the whole app.
 * It closes all the opened panels when clicked.
 */
function BackdropContainer() {
    const dispatch = useDispatch();

    const trackPanelOpened = useSelector(Selectors.isTrackPanelOpen);
    const downloadPanelOpened = useSelector(Selectors.isDownloadPanelOpen);
    const projectPanelOpened = useSelector(Selectors.isProjectPanelOpen);
    const briefPanelOpen = useSelector(Selectors.isBriefPanelOpen);
    const simultaneousLoginsPanelOpened = useSelector(Selectors.isSimultaneousLoginsPanelOpen);

    const active = (
        trackPanelOpened ||
        downloadPanelOpened ||
        projectPanelOpened ||
        briefPanelOpen ||
        simultaneousLoginsPanelOpened
    );

    /**
     * Closes opened panels when the backdrop is clicked by dispatching the related actions.
     */
    const handleClick = useCallback(() => {
        if (trackPanelOpened) {
            dispatch(closeVersionsPanel());
            dispatch(closeInformationsPanel());
        }
        if (downloadPanelOpened) {
            dispatch(closeDownloadPanel());
        }
        if (projectPanelOpened) {
            dispatch(closeSelectProjectPanel());
        }
        if (briefPanelOpen) {
            dispatch(closeSelectBriefPanel());
        }
        if (simultaneousLoginsPanelOpened) {
            dispatch(closeSimultaneousLoginsSpottedPanel());
        }
    }, [
        dispatch,
        trackPanelOpened,
        downloadPanelOpened,
        projectPanelOpened,
        briefPanelOpen,
        simultaneousLoginsPanelOpened,
    ]);

    return <Backdrop active={active} full onClick={handleClick} />;
}

const Selectors = {
    isTrackPanelOpen: (state) => state.track.panel !== null,
    isDownloadPanelOpen: (state) => state.download.trackReference !== null,
    isProjectPanelOpen: (state) => state.project.trackReference !== null,
    isBriefPanelOpen: (state) => state.brief.trackReference !== null,
    isSimultaneousLoginsPanelOpen: (state) => state.authentication.simultaneousLoginsSpotted === true,
};

export default BackdropContainer;
