import I18n from 'i18n-js';
import Button from '@App/components/ui/Button';
import PropTypes from 'prop-types';
import { useRemoveTrackFromCart } from '@App/api/hooks/useCart';

RemoveTrackFromCartButton.propTypes = {
    reference: PropTypes.string.isRequired,
};

export default function RemoveTrackFromCartButton({ reference, ...props }) {
    const { removeFromCart, loading } = useRemoveTrackFromCart();

    async function onClick() {
        if (loading) {
            return;
        }

        await removeFromCart(reference);
    }

    return <Button label={I18n.t('page.cart.actions.removeTrack')} onClick={onClick} loading={loading} {...props} />;
}
