import I18n from 'i18n-js';
import { LOGOUT } from '@App/routes';

export default function LogoutButton() {
    return (
        <a href={LOGOUT.getUrl()} className="link link--danger">
            {I18n.t('menu.authenticated.logout')}
        </a>
    );
}
