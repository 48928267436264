import { createElement, Component } from 'react';
import PropTypes from 'prop-types';

export default class Table extends Component {
    static propTypes = {
        renderHead: PropTypes.func.isRequired,
        renderRow: PropTypes.func.isRequired,
        renderEmpty: PropTypes.func.isRequired,
        values: PropTypes.array.isRequired,
    };

    static defaultProps = {
        values: [],
    };

    render() {
        const { values } = this.props;

        return (
            <table className="table">
                <thead>
                    {this.props.renderHead()}
                </thead>
                <tbody>
                    {values.length ? values.map(this.props.renderRow) : this.props.renderEmpty()}
                </tbody>
            </table>
        );
    }
}

export function TableRow(props) {
    const { columns, head, ...rowProps } = props;

    return createElement('tr', rowProps, columns.map(column => {
        const { key, value } = column;
        const element = head || column.head ? 'th' : 'td';

        return createElement(element, { key }, value);
    }));
}

TableRow.propTypes = {
    head: PropTypes.bool,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            value: PropTypes.node,
            head: PropTypes.bool,
        }).isRequired,
    ).isRequired,
};
