import { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import ProjectAddTrack from '@App/api/mutation/ProjectAddTrack.graphql';
import { withAnalyticsOnClickHandler, withTrackedQueryID } from '@App/searchAnalytics/withAnalytics';
import {useDispatch} from 'react-redux';
import { closeSelectProjectPanel } from '@App/store/actions/project';

function ProjectItem({ versionReference, project, onClick }) {
    const [added, setAdded] = useState(false);
    const [addTrackToProject, { loading, error }] = useMutation(ProjectAddTrack, {
        variables: {
            id: project.id,
            version: versionReference,
        },
        onCompleted: () => setAddedActions(),
    });
    const dispatch = useDispatch();

    const setAddedActions = () => {
        setAdded(true);
        setTimeout(() => dispatch(closeSelectProjectPanel()), 1000);
    };

    const handleClick = () => {
        addTrackToProject().catch(console.error);

        onClick();
    };

    return <li className={classNames('project-add', {
        'brief-add--added': added,
    })}>
        <button onClick={handleClick} className="text-ellipsis" disabled={loading}>
            <i className={classNames('icon icon--add', {
                'icon icon--check': added,
            })}></i>
            {project.name}
        </button>
        <span className="text-danger" style={{ display: error ? '' : 'none' }}>
            {I18n.t('panel.project.add-track.failed')}
        </span>
    </li>;
}

ProjectItem.propTypes = {
    versionReference: PropTypes.string,
    project: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
};

const WithAnalyticsWrapper = withAnalyticsOnClickHandler(ProjectItem);

const WithTrackedQuery = withTrackedQueryID(WithAnalyticsWrapper);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { onClick, ...InheritedProps } = ProjectItem.propTypes;

WithTrackedQuery.propTypes = {
    ...InheritedProps,
    searchResultsPosition: PropTypes.number,
    trackReference: PropTypes.string,
};

export default WithTrackedQuery;
