import PropTypes from 'prop-types';
import GetPlaylistId from '@App/api/query/GetPlaylistId.graphql';
import Query from '@App/components/api/Query';
import RedirectError from '@App/error/RedirectError';
import { PLAYLIST } from '@App/routes';
import { LoadingPlaylist } from '@App/components/page/Playlist';

function RedirectWrapper(props) {
    const { id, slug } = props;

    throw new RedirectError('Redirect legacy playlist url', { url: PLAYLIST.getUrl({ id, slug }) });
}

RedirectWrapper.propTypes = {
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
};

export default function PlaylistLegacy(props) {
    const { slug } = props;

    return (
        <Query
            component={RedirectWrapper}
            loadingComponent={LoadingPlaylist}
            query={GetPlaylistId}
            variables={{ slug }}
            mapResult={data => data.playlistBySlug}
        />
    );
}

PlaylistLegacy.propTypes = {
    slug: PropTypes.string.isRequired,
};
