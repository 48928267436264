/**
 * padStart
 */
if (typeof String.prototype.padStart !== 'function') {
    String.prototype.padStart = function padStart (targetLength = 0, padString = ' ') {
        return padString.repeat(Math.max(targetLength - this.length, 0)) + this;
    };
}

/**
 * Object.values
 */
if (typeof Object.values !== 'function') {
    Object.values = function objectValues (value) {
        return Object.keys(value).map(key => value[key]);
    };
}
