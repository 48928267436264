import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { CREATE_PLAYLIST_FROM_PROJECT } from '@App/routes';

function ConvertToPlaylistButton(props) {
    const { id, canManagePlaylist, ...childProps } = props;

    if (!canManagePlaylist) {
        return null;
    }

    delete childProps.dispatch;

    return (
        <a {...childProps} href={CREATE_PLAYLIST_FROM_PROJECT.getUrl({project: id})} >{I18n.t('model.project.actions.convert_to_playlist')}</a>
    );
}

ConvertToPlaylistButton.propTypes = {
    id: PropTypes.string.isRequired,
    canManagePlaylist: PropTypes.bool.isRequired,
};

export default connect((state) => ({
    canManagePlaylist: state.authentication.permissions.canManagePlaylist
}))(ConvertToPlaylistButton);
