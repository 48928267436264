import { Component } from 'react';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import { onFollowProject } from '@App/api/store';
import ProjectFollow from '@App/api/mutation/ProjectFollow.graphql';
import Mutation from '@App/components/api/Mutation';
import Button from '@App/components/ui/Button';
import Loader from '@App/components/ui/Loader';

class FollowProjectButton extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        mutate: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        success: PropTypes.bool,
        errors: PropTypes.object,
    };

    static defaultProps = {
        loading: false,
        success: null,
        errors: null,
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const { mutate, loading, id } = this.props;

        if (loading) {
            return;
        }

        mutate({ id }, (store, { data }) => onFollowProject(store, data.projectFollow));
    }

    render() {
        const { loading, ...props } = this.props;
        const buttonLabel = loading ? <Loader /> : I18n.t('model.project.actions.follow');

        delete props.id;
        delete props.success;
        delete props.errors;
        delete props.mutate;

        return <Button {...props} label={buttonLabel} onClick={this.onClick} />;
    }
}

export default function Wrapper(props) {
    return <Mutation
        mutation={ProjectFollow}
        component={FollowProjectButton}
        loadingComponent={FollowProjectButton}
        errorComponent={FollowProjectButton}
        mapResult={() => ({ success: true })}
        mapLoading={() => ({ loading: true, success: null })}
        childProps={props}
    />;
}

Wrapper.propTypes = {
    id: PropTypes.string.isRequired,
};
