import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Label from '@App/components/form/Label';
import Errors from '@App/components/form/Errors';
import MultipleChoice from '@App/components/form/MultipleChoice';
import classnames from 'classnames';
import { FieldErrors } from '@App/utils/proptypes';
import { prependProtocol } from '@App/components/form/UrlInput';

export default class SupportsChoice extends Component {
    static propTypes = {
        value: PropTypes.shape({
            types: PropTypes.array.isRequired,
            otherValue: PropTypes.string,
            webValue: PropTypes.string,
        }),
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        required: PropTypes.bool,
        readOnly: PropTypes.bool,
        errors: PropTypes.shape({
            self: FieldErrors,
            webSupportUrl: FieldErrors,
            otherSupport: FieldErrors,
        }),
        onChange: PropTypes.func,
    };

    static defaultProps = {
        value: {
            types: [],
            otherValue: null,
            webValue: null,
        },
        required: false,
        readOnly: false,
        label: null,
        errors: {
            self: [],
            webSupportUrl: [],
            otherSupport: [],
        },
        onChange: () => {},
    };

    constructor(props) {
        super(props);

        this.options = [
            'web',
            'physical',
            'other',
        ];

        this.onTypeChange = this.onTypeChange.bind(this);
        this.onOtherValueChange = this.onOtherValueChange.bind(this);
        this.onWebValueChange = this.onWebValueChange.bind(this);
        this.onWebValueBlur = this.onWebValueBlur.bind(this);
        this.renderLabel = this.renderLabel.bind(this);
        this.renderExtra = this.renderExtra.bind(this);
    }

    onTypeChange(types, event) {
        const { onChange, value } = this.props;

        onChange({ ...value, types }, event);

        // Focus extra input if any:
        if (event.target && event.target.parentElement && event.target.parentElement.children) {
            for (const node of event.target.parentElement.children) {
                if (node.matches('input.support-extra')) {
                    // setTimeout to ensure the input is focusable at this point
                    setTimeout(() => node.focus());
                }
            }
        }
    }

    onOtherValueChange(event) {
        const { onChange, value } = this.props;
        const otherValue = event.target.value.trim().length > 0 ? event.target.value : null;

        onChange({ ...value, otherValue }, event);
    }

    onWebValueChange(event) {
        const { onChange, value } = this.props;
        const webValue = event.target.value.trim().length > 0 ? event.target.value : null;

        onChange({ ...value, webValue }, event);
    }

    onWebValueBlur(event) {
        const { onChange, value } = this.props;
        let webValue = event.target.value.trim().length > 0 ? event.target.value : null;

        if (!value) {
            return;
        }

        webValue = prependProtocol(webValue);

        onChange({ ...value, webValue }, event);
    }

    renderExtra(type) {
        const { readOnly, errors } = this.props;
        const { types, otherValue, webValue } = this.props.value;

        switch (type) {
            case 'other':
                return <>
                    <input
                        key="other-value"
                        type="text"
                        onChange={this.onOtherValueChange}
                        value={otherValue || ''}
                        className={classnames('support-extra', { 'd-none': !types.includes('other') })}
                        disabled={!types.includes('other')}
                        readOnly={readOnly}
                        required
                    />
                    <div className="break" />
                    <Errors errors={errors.otherSupport} />
                </>;

            case 'web':
                return <>
                    <input
                        key="web-value"
                        type="url"
                        onChange={this.onWebValueChange}
                        onBlur={this.onWebValueBlur}
                        value={webValue || ''}
                        className={classnames('support-extra', { 'd-none': !types.includes('web') })}
                        disabled={!types.includes('web')}
                        readOnly={readOnly}
                        required
                    />
                    <div className="break" />
                    <Errors errors={errors.webSupportUrl} />
                </>;

            default:
                return null;
        }
    }

    renderLabel(type) {
        return I18n.t(`form.supports_choice.type.${type}`);
    }

    render() {
        const { id, value, name, label, required, errors, ...otherProps } = this.props;

        delete otherProps.onChange;

        return (
            <div className="form supports-choice">
                {label ? <Label required={required}>{label}</Label> : null}
                <MultipleChoice
                    id={id}
                    value={value.types}
                    name={name}
                    options={this.options}
                    onChange={this.onTypeChange}
                    renderLabel={this.renderLabel}
                    renderExtra={this.renderExtra}
                    {...otherProps}
                />
                <Errors errors={errors.self} />
            </div>
        );
    }
}
