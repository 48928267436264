import PropTypes from 'prop-types';
import { PLAYLIST } from '@App/routes';
import Tile from '@App/components/layout/Tile';

export default function PlaylistTile(props) {
    const { id, slug, title, coverAsLargeThumbnail } = props;
    const link = {
        route: PLAYLIST.name,
        parameters: { id, slug },
    };

    return <Tile key={id} title={title} cover={coverAsLargeThumbnail} link={link} />;
}

PlaylistTile.propTypes = {
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    coverAsLargeThumbnail: PropTypes.string.isRequired,
};
