import classnames from 'classnames';
import Button from '@App/components/ui/Button';

interface Props {
    icon: string
    label: string
    onClick: () => void
    active?: boolean
    className?: string
    value?: string | null
}

export default function OptionItem({
  icon,
  label,
  onClick,
  active = false,
  className = '',
  value = null,
}: Props) {
  return <Button
    icon={icon}
    label={label}
    className={classnames('options__item', className, { active })}
    onClick={onClick}
    value={value}
    tooltip
  />;
}
