import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import MultipleChoice from '@App/components/form/MultipleChoice';
import { formatDuration } from '@App/utils';

export default class VersionsSelector extends Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(PropTypes.object).isRequired,
        value: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultsProps = {
        value: [],
    };

    constructor(props) {
        super(props);

        this.getValue = this.getValue.bind(this);
        this.renderLabel = this.renderLabel.bind(this);
    }

    getValue(version) {
        return version.reference;
    }

    renderLabel(version) {
        const { reference, label, duration } = version;

        return (
            <Fragment>
                <span className="version__title">{label}</span>
                <span className="version__ref">{reference}</span>
                <span className="version__length">{formatDuration(duration)}</span>
            </Fragment>
        );
    }

    render() {
        const { value, options, onChange } = this.props;

        return <MultipleChoice
            name="version"
            value={value}
            options={options}
            onChange={onChange}
            getValue={this.getValue}
            renderLabel={this.renderLabel}
            labelProps={{ className: 'version' }}
        />;
    }
}
