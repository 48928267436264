import { useState } from 'react';
import I18n from 'i18n-js';
import BlockTitle from '@App/components/layout/BlockTitle';
import { useCart, useSubmitCart } from '@App/api/hooks/useCart';
import { useLicenses } from '@App/api/hooks/useLicenses';
import useSteps from '@App/common/hooks/useSteps';
import { ORDER } from '@App/routes';
import ShowCartStep from '@App/components/page/cart/steps/ShowCartStep';
import ChooseLicenseStep from '@App/components/page/cart/steps/ChooseLicenseStep';
import ConfigureProjectStep from '@App/components/page/cart/steps/ConfigureProjectStep';
import useAuth from '@App/common/hooks/useAuth';
import CountryNotAllowed from '@App/components/page/error/CountryNotAllowed';

MyCart.STEPS = {
    CART: 'cart',
    LICENSE: 'license',
    PROJECT: 'project',
};

MyCart.ORDERED_STEPS = [
    MyCart.STEPS.CART,
    MyCart.STEPS.LICENSE,
    MyCart.STEPS.PROJECT,
];

export default function MyCart() {
    const { step, nextStep, previousStep } = useSteps(MyCart.STEPS, MyCart.ORDERED_STEPS, MyCart.STEPS.CART);
    const [selectedLicense, setSelectedLicense] = useState(null);
    const [projectName, setProjectName] = useState(null);

    const { loading: cartLoading, tracks } = useCart();
    const { loading: licensesLoading, licenses, getById } = useLicenses();

    const {
        loading: submitCartLoading,
        submit: submitCart,
        error: submitCartError,
    } = useSubmitCart(selectedLicense, projectName);

    async function submit() {
        const { paymentLink, order } = await submitCart();

        // Redirect to payment page (or order page on empty for now):
        window.location = paymentLink || ORDER.getUrl({ id: order.id });
    }

    let hasCancelParams = new URLSearchParams(window.location.search).has('cancel');

    function renderStep() {
        if (step === MyCart.STEPS.CART || tracks.length === 0) {
            return <ShowCartStep
                loading={cartLoading}
                tracks={tracks}
                onSubmit={nextStep}
            />;
        }

        if (step === MyCart.STEPS.LICENSE) {
            return <ChooseLicenseStep
                loading={licensesLoading}
                licenses={licenses}
                selectedLicense={selectedLicense}
                onSubmit={(licenseId) => {
                    if (licenseId === null) {
                        return;
                    }
                    setSelectedLicense(licenseId);
                    nextStep();
                }}
            />;
        }

        if (step === MyCart.STEPS.PROJECT) {
            return <ConfigureProjectStep
                license={getById(selectedLicense)}
                tracks={tracks}
                projectName={projectName}
                setProjectName={setProjectName}
                onModifyLicense={previousStep}
                submitLoading={submitCartLoading}
                submitError={Boolean(submitCartError)}
                onSubmit={submit}
            />;
        }
    }

    const { permissions } = useAuth();

    if (!permissions.isInAllowedCountry) {
        return <CountryNotAllowed />;
    }

    return (
        <div className="content page-cart">
            <div className="container-fluid">
                <BlockTitle title={I18n.t(`page.cart.steps.${step}.title`)}/>
                {hasCancelParams && <div className="alert alert--error">{I18n.t('page.cart.paymentCanceled')}</div>}
                {renderStep()}
            </div>
        </div>
    );
}
