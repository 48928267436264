import { Component } from 'react';
import PropTypes from 'prop-types';
import FormUtils from '@App/utils/form';
import I18n from 'i18n-js';
import TextInput from '@App/components/form/TextInput';
import classNames from 'classnames';
import GraphQLUtils from '@App/utils/graphql';
import Mutation from '@App/components/api/Mutation';
import ChangePasswordMutation from '@App/api/mutation/ChangePasswordMutation.graphql';

class ChangePassword extends Component {
    static propTypes = {
        mutate: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        success: PropTypes.bool,
        errors: PropTypes.object,
    };

    static defaultProps = {
        loading: false,
        errors: {},
        success: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            form: {
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
            },
        };

        this.handleFieldChange = fieldName => value => FormUtils.handleFieldChange.bind(this)('form', fieldName, value);
        this.onSubmit = this.onSubmit.bind(this);
        this.getErrors = this.getErrors.bind(this);
        this.linkState = this.linkState.bind(this);
    }

    /**
     * @param {String} path
     */
    getErrors(path) {
        return this.props.errors[path] || [];
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.props.loading) {
            return;
        }

        const { form } = this.state;
        const { mutate } = this.props;

        mutate({ payload: form });
    }

    /**
     * @param {String} field field name to link
     */
    linkState(field) {
        return {
            value: this.state.form[field],
            onChange: this.handleFieldChange(field),
            errors: this.getErrors(field),
        };
    }

    render() {
        const { loading, success } = this.props;

        return (
            <div>
                <h2 className="h1">{I18n.t('page.my_account.change_password.title')}</h2>
                <hr/>

                <form onSubmit={this.onSubmit}>
                    <div className="row">
                        <div className="col-sm-6 col-lg-3 offset-lg-1">
                            <TextInput {...this.linkState('currentPassword')}
                                label={I18n.t('page.my_account.change_password.form.currentPassword')}
                                id="currentPassword"
                                type="password"
                                autoComplete="current-password"
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-lg-3 offset-lg-1">
                            <TextInput {...this.linkState('newPassword')}
                                label={I18n.t('page.my_account.change_password.form.newPassword')}
                                id="newPassword"
                                type="password"
                                autoComplete="new-password"
                                required
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <TextInput {...this.linkState('confirmPassword')}
                                label={I18n.t('page.my_account.change_password.form.confirmPassword')}
                                id="confirmPassword"
                                type="password"
                                autoComplete="new-password"
                                required
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-lg-6 offset-lg-1">
                            {success === true && <div className="alert">
                                <span>{I18n.t('page.my_account.change_password.success')}</span>
                            </div>}

                            {success === false && <div className="alert alert--error">
                                <span>{I18n.t('page.my_account.change_password.error')}</span>
                            </div>}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 offset-lg-4">
                            <button
                                type="submit"
                                className={classNames('btn btn--primary btn--full', { disabled: loading })}
                                disabled={loading}
                            >{I18n.t('page.my_account.change_password.form.submit')}</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default function ChangePasswordWithMutation(props) {
    return <Mutation
        {...props}
        component={ChangePassword}
        mutation={ChangePasswordMutation}
        loadingComponent={ChangePassword}
        errorComponent={ChangePassword}
        mapResult={data => ({ success: !!data.changePassword })}
        mapLoading={() => ({ loading: true, success: null })}
        mapError={(error) => {
            const errorsByPath = GraphQLUtils.errorsByPath((error && error.graphQLErrors) || []);

            return {
                success: false,
                errors: {
                    currentPassword: errorsByPath['currentPassword'] || [],
                    newPassword: errorsByPath['newPassword'] || [],
                    confirmPassword: errorsByPath['confirmPassword'] || [],
                }
            };
        }}
    />;
}
