import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import ResultBoxSection from '@App/components/search/ResultBoxSection';
import ResultBoxTag from '@App/components/search/ResultBoxTag';
import ResultBoxLink from '@App/components/search/ResultBoxLink';
import { ALBUM, PLAYLIST } from '@App/routes';

export default class ResultBox extends Component {
    static propTypes = {
        onSearch: PropTypes.func.isRequired,
        onFind: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        tags: PropTypes.object,
        tracks: PropTypes.object,
        albums: PropTypes.object,
        playlists: PropTypes.object,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        tags: null,
        tracks: null,
        albums: null,
        playlists: null,
        loading: true,
    };

    static renderTag(tag, onUse) {
        const { id, name } = tag;

        return <ResultBoxTag key={id} id={id} title={name} onUse={onUse} />;
    }

    static renderTrack(track, onUse) {
        const { reference, title, album, nbVersions, matchedVersions } = track;

        const subtitle = (
            <div className="media__info__subtitle">
                <span>{matchedVersions.length ? matchedVersions.join(', ') : reference}</span>
                <span>{I18n.t('model.track.versions', { count: nbVersions })}</span>
            </div>
        );

        return <ResultBoxLink
            key={reference}
            route={ALBUM.name}
            parameters={{ reference: album.reference }}
            anchor={reference}
            title={title}
            cover={album.coverAsThumbnail}
            subtitle={subtitle}
            onUse={onUse}
        />;
    }

    static renderAlbum(album, onUse) {
        const { reference, title, coverAsThumbnail, nbTracks } = album;

        return <ResultBoxLink
            key={reference}
            route={ALBUM.name}
            parameters={{ reference }}
            title={title}
            cover={coverAsThumbnail}
            subtitle={`${reference} - ${I18n.t('model.track.countTracks', { count: nbTracks })}`}
            onUse={onUse}
        />;
    }

    static renderPlaylist(playlist, onUse) {
        const { slug, id, title, coverAsThumbnail, nbTracks } = playlist;

        return <ResultBoxLink
            key={slug}
            route={PLAYLIST.name}
            parameters={{ slug, id }}
            title={title}
            cover={coverAsThumbnail}
            subtitle={I18n.t('model.track.countTracks', { count: nbTracks })}
            onUse={onUse}
        />;
    }

    renderSection(search, label, renderChild, onUse, onSeeAll = null) {
        const { count, list } = search;

        if (count < 1) {
            return null;
        }

        return (
            <ResultBoxSection label={label} count={count} onSeeAll={onSeeAll}>
                {list.map(item => renderChild(item, onUse))}
            </ResultBoxSection>
        );
    }

    render() {
        const { loading, tags, tracks, albums, playlists, onSearch, onFind, onSubmit } = this.props;
        const { renderTag, renderTrack, renderAlbum, renderPlaylist } = this.constructor;

        if (loading) {
            return <div className="result-box loading"></div>;
        }

        const tagsSection = this.renderSection(tags, I18n.t('search.results.tags'), renderTag, onSearch);
        const tracksSection = this.renderSection(tracks, I18n.t('search.results.tracks'), renderTrack, onFind, onSubmit);
        const albumsSection = this.renderSection(albums, I18n.t('search.results.albums'), renderAlbum, onFind);
        const playlistsSection = this.renderSection(playlists, I18n.t('search.results.playlists'), renderPlaylist, onFind);

        if (!tagsSection && !tracksSection && !albumsSection && !playlistsSection) {
            return (
                <div className="result-box result-box--empty">
                    <h3 className="message">{I18n.t('search.results.no-match')}</h3>
                </div>
            );
        }

        return (
            <div className="result-box">
                {tagsSection}
                {tracksSection}
                {albumsSection}
                {playlistsSection}
            </div>
        );
    }
}
