import { Component } from 'react';
import PropTypes from 'prop-types';
import Link from '@App/components/navigation/Link';

export default class ResultBoxLink extends Component {
    static propTypes = {
        ...Link.propTypes,
        title: PropTypes.string.isRequired,
        cover: PropTypes.string.isRequired,
        subtitle: PropTypes.node,
    };

    static defaultProps = {
        ...Link.defaultProps,
        subtitle: null,
    };

    render() {
        const { title, subtitle, cover, route, parameters, anchor, onUse } = this.props;

        return (
            <Link className="media" route={route} parameters={parameters} anchor={anchor} onUse={onUse}>
                <span className="media__cover" style={{ backgroundImage: `url(${cover})` }} />
                <span className="media__info">
                    <strong>{title}</strong>
                    {subtitle}
                </span>
            </Link>
        );
    }
}
