import PropTypes from 'prop-types';
import BreadCrumbItem from '@App/components/navigation/BreadCrumbItem';
import LoadingBreadCrumbItem from '@App/components/navigation/LoadingBreadCrumbItem';
import GetPlaylistTitle from '@App/api/query/GetPlaylistTitle.graphql';
import Query from '@App/components/api/Query';
import { PLAYLIST } from '@App/routes';

export default function PlaylistBreadCrumbItem(props) {
    return <Query
        component={BreadCrumbItem}
        loadingComponent={LoadingBreadCrumbItem}
        query={GetPlaylistTitle}
        variables={{ id: props.id }}
        mapResult={data => {
            const { id, slug, title } = data.playlist;

            return {
                label: title,
                route: PLAYLIST.name,
                parameters: { id, slug }
            };
        }}
    />;
}

PlaylistBreadCrumbItem.propTypes = {
    id: PropTypes.string.isRequired,
};
