import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Label from '@App/components/form/Label';
import Errors from '@App/components/form/Errors';

export default class NumberInput extends Component {
    static propTypes = {
        value: PropTypes.number,
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        required: PropTypes.bool,
        errors: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        onChange: PropTypes.func,
    };

    static defaultProps = {
        value: null,
        required: false,
        errors: [],
        onChange: () => {},
    };

    constructor(props) {
        super(props);

        this.hasErrors = this.hasErrors.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    /**
     * @returns {Boolean}
     */
    hasErrors() {
        return [].concat(this.props.errors).length > 0;
    }

    onChange(event) {
        const { onChange } = this.props;
        const value = parseInt(event.target.value, 10);

        if (value !== this.props.value) {
            onChange(value, event);
        }
    }

    render() {
        const { id, value, label, required, errors, ...otherProps } = this.props;
        const className = classNames('form__input', { 'form__input--error': this.hasErrors() });

        delete otherProps.onChange;

        return (
            <div className="form">
                <Label forId={id} required={required}>{label}</Label>
                <input
                    id={id}
                    type="number"
                    onChange={this.onChange}
                    className={className}
                    required={required}
                    value={(value || 0).toString()}
                    {...otherProps}
                />
                <Errors errors={errors} />
            </div>
        );
    }
}
