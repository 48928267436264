import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { PROJECT } from '@App/routes';
import Tile from '@App/components/layout/Tile';
import Button from '@App/components/ui/Button';
import ArchiveProjectButton from '@App/components/model/project/ArchiveProjectButton';
import RestoreProjectButton from '@App/components/model/project/RestoreProjectButton';
import Dropover from '@App/components/ui/Dropover';
import ConvertToPlaylistButton from '@App/components/model/project/ConvertToPlaylistButton';
import DuplicateButton from '@App/components/model/project/DuplicateButton';

function MoreButton(props) {
    const label = I18n.t('model.project.actions.other');

    return <Button icon="more" label={label} tooltip {...props} />;
}

function renderInfo(project) {
    const { nbTracks, createdAt, author } = project;

    return [
        I18n.t('model.track.countTracks', { count: nbTracks }),
        I18n.t('model.project.createdAtBy', {
            author,
            date: I18n.l('date.formats.full', createdAt),
        })
    ].join(' - ');
}

function renderDropover(id, archived) {
    return (
        <Dropover key="more" button={MoreButton}>
            {archived ? <RestoreProjectButton id={id} className="link" /> : <ArchiveProjectButton id={id} className="link link--danger" />}
            <ConvertToPlaylistButton id={id} className="link" />
            <DuplicateButton id={id} className="link" />
        </Dropover>
    );
}

export default function ProjectTile(props) {
    const { project } = props;
    const { id, name, coverAsLargeThumbnail, archived, owned } = project;
    const link = {
        route: PROJECT.name,
        parameters: { id },
    };

    return (
        <Tile title={name} info={renderInfo(project)} cover={coverAsLargeThumbnail} link={link}>
            {owned ? renderDropover(id, archived) : null}
        </Tile>
    );
}

ProjectTile.propTypes = {
    project: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        nbTracks: PropTypes.number.isRequired,
        archived: PropTypes.bool.isRequired,
        owned: PropTypes.bool.isRequired,
        createdAt: PropTypes.string.isRequired,
        coverAsLargeThumbnail: PropTypes.string,
        author: PropTypes.string.isRequired,
    }).isRequired,
};

ProjectTile.defaultProps = {
    coverAsLargeThumbnail: null,
};
