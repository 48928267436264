import { Component } from 'react';
import I18n from 'i18n-js';
import Link from '@App/components/navigation/Link';
import expertise from '@Image/homepage/expertise.svg';
import licences from '@Image/homepage/licences.svg';
import { LICENCE_REQUESTS, LICENCE_REQUEST_WEB_REPORT_FORM, LICENCE_REQUEST_CORPORATE_MOVIE_FORM } from '@App/routes';

export default class LicenceRequestTypeChoice extends Component {
    constructor(props) {
        super(props);

        this.types = [
            'WebReportLicenceRequest',
            'CorporateMovieLicenceRequest',
        ];

        this.renderType = this.renderType.bind(this);
    }

    getIllustration(type) {
        switch (type) {
            case 'WebReportLicenceRequest':
                return expertise;
            case 'CorporateMovieLicenceRequest':
                return licences;
            default:
                return null;
        }
    }

    getRoute(type) {
        switch (type) {
            case 'WebReportLicenceRequest':
                return LICENCE_REQUEST_WEB_REPORT_FORM.name;
            case 'CorporateMovieLicenceRequest':
                return LICENCE_REQUEST_CORPORATE_MOVIE_FORM.name;
            default:
                return null;
        }
    }

    renderType(type) {
        return (
            <div key={type} className="item">
                <h3>{I18n.t(`form.licence_request.types.${type}.title`)}</h3>
                <div
                    className="item__content"
                    dangerouslySetInnerHTML={{ __html: I18n.t(`form.licence_request.types.${type}.description`) }}
                />
                <Link route={this.getRoute(type)} className="btn btn--primary">
                    {I18n.t(`form.licence_request.types.${type}.label`)}
                </Link>
            </div>
        );
    }

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <ul className="breadcrumb breadcrumb--dark">
                        <li className="breadcrumb__item">
                            <Link route={LICENCE_REQUESTS.name} className="link">
                                <i className="icon icon--chevron-left"></i>
                                {I18n.t('page.licence_request_new.back')}
                            </Link>
                        </li>
                    </ul>
                    <h1 className="licence-offer__title">{I18n.t('page.licence_request_new.title')}</h1>
                    <hr/>
                    <div className="licence-offer">
                        <p className="licence-offer__help">{I18n.t('page.licence_request_new.help')}</p>
                        <div className="licence-offer__content">
                            {this.types.map(this.renderType)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
