import { Component } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Query from '@App/components/api/Query';
import DownloadStatus from '@App/api/query/DownloadStatus.graphql';
import DownloadNotify from '@App/api/mutation/DownloadNotify.graphql';

export class DownloadButton extends Component {
    static propTypes = {
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
        ]).isRequired,
        url: PropTypes.string,
        status: PropTypes.string,
        detail: PropTypes.shape({
            current: PropTypes.number.isRequired,
            maxStep: PropTypes.number.isRequired,
        }),
        className: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
        ]),
        notify: PropTypes.func,
    };

    static defaultProps = {
        url: null,
        status: 'pending',
        detail: null,
        className: '',
        notify: null,
    };

    constructor(props) {
        super(props);

        this.downloaded = false;

        this.onDownload = this.onDownload.bind(this);
        this.onExit = this.onExit.bind(this);
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.onExit);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onExit);
        this.onExit();
    }

    onExit() {
        const { notify } = this.props;

        if (notify && !this.downloaded) {
            notify();
        }
    }

    onDownload() {
        this.downloaded = true;
    }

    getLabel() {
        const { label, status, detail } = this.props;

        return typeof label === 'function' ? label(status, detail) : label;
    }

    getClassName() {
        const { className, status, detail } = this.props;

        return typeof className === 'function' ? className(status, detail) : className;
    }

    render() {
        const { url } = this.props;

        if (url === null) {
            <button type="button" disabled className={this.getClassName()}>
                {this.getLabel()}
            </button>;
        }

        return (
            <a href={url} download className={this.getClassName()} onClick={this.onDownload}>
                {this.getLabel()}
            </a>
        );
    }
}

function mapResult(data) {
    const { status, detail, url } = data.downloadStatus;

    return { status, detail, url };
}

function isDone(props) {
    return props.status === 'success';
}

/**
 * GraphQL wrapped DownloadTrackPanel
 *
 * @param {Object} props
 */
export default function DownloadButtonWrapper(props) {
    const { id } = props;
    const [ notify ] = useMutation(DownloadNotify);

    return <Query
        query={DownloadStatus}
        component={DownloadButton}
        loadingComponent={DownloadButton}
        variables={{ id }}
        mapResult={mapResult}
        childProps={{ ...props, notify: () => notify({ variables: { id }, ignoreResults: true }) }}
        pollInterval={1000}
        shouldStopPolling={isDone}
    />;
}

DownloadButtonWrapper.propTypes = {
    id: PropTypes.string.isRequired,
};

DownloadButtonWrapper.defaultProps = {
};
