import { Component, createElement, createRef } from 'react';
import PropTypes from 'prop-types';

export default class Anchor extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        children: PropTypes.node,
        type: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.string,
        ]),
    };

    static defaultProps = {
        children: null,
        type: 'div',
    };

    constructor(props) {
        super(props);

        this.element = createRef();

        this.scrollTo = this.scrollTo.bind(this);
    }

    componentDidMount() {
        if (this.props.active) {
            this.scrollTo();
        }
    }

    componentDidUpdate(prevProps) {
        const { active } = this.props;

        if (active && active !== prevProps.active) {
            this.scrollTo();
        }
    }

    scrollTo() {
        if (this.element) {
            this.element.current.scrollIntoView();
        }
    }

    render() {
        const { type, id, children, ...props } = this.props;

        delete props.active;

        return createElement(type, { id, ref: this.element, ...props }, children);
    }
}
