import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Label from '@App/components/form/Label';
import Errors from '@App/components/form/Errors';

export default class Checkbox extends Component {

    static propTypes = {
        value: PropTypes.bool,
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        required: PropTypes.bool,
        readOnly: PropTypes.bool,
        errors: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        onChange: PropTypes.func,
    };

    static defaultProps = {
        value: false,
        required: false,
        readOnly: false,
        errors: [],
        onChange: () => {},
    };

    constructor(props) {
        super(props);

        this.hasErrors = this.hasErrors.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    /**
     * @returns {Boolean}
     */
    hasErrors() {
        return [].concat(this.props.errors).length > 0;
    }

    onChange(event) {
        const { onChange } = this.props;
        const { checked } = event.target;

        onChange(checked, event);
    }

    render() {
        const { id, value, label, required, errors, readOnly, ...otherProps } = this.props;
        const className = classNames('checkbox', { 'form__input--error': this.hasErrors() });

        delete otherProps.onChange;

        if (readOnly) {
            otherProps.disabled = true;
        }

        return (
            <div className="checkbox">
                <input
                    id={id}
                    type="checkbox"
                    onChange={this.onChange}
                    className={className}
                    required={required}
                    checked={value}
                    {...otherProps}
                />
                <Label forId={id} required={required} className="">{label}</Label>
                <Errors errors={errors} />
            </div>
        );
    }
}
