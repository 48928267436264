import AppConfig from '@Common/AppConfig';
import ReactDOM from 'react-dom';
import { InMemoryCache } from '@apollo/client/cache';
import container, { get } from '@App/container';
import App from '@App/App';
import DomMetaHandler from '@App/service/meta/DomMetaHandler';
import { createLink } from '@App/utils/apollo';
import cacheOptions from '@App/api/cacheOptions';

AppConfig.MODE = 'BROWSER';
const element = document.getElementById('app');

if (element) {
    // Start Sentry:
    const sentry = get('sentry');
    sentry.init();
    sentry.setTags({ entrypoint: 'front' });

    // Configure apollo client
    container.registerParameter('client.config', {
        ssrMode: false,
        link: createLink(
            {
                uri: '/api/',
                credentials: 'include'
            },
            {
                batch: true,
                upload: true,
                errorHandler: debugErrorHandler
            }
        ),
        cache: new InMemoryCache(cacheOptions).restore(window.__APOLLO_STATE__),
    });

    // Host
    container.registerParameter('origin', window.location.origin);

    // Meta handler
    container.registerService('meta', DomMetaHandler, ['origin']);

    // Define initial Redux state
    container.registerParameter('store.initial-state', window.__PRELOADED_STATE__);
    container.registerParameter('store.enable-logger', AppConfig.NODE_ENV !== 'production');

    // Allow the passed state to be garbage-collected
    delete window.__PRELOADED_STATE__;
    delete window.__APOLLO_STATE__;

    // Load current url
    get('history').loadUrl(window.location.toString(), window.location.hash);

    // Init mobile watcher
    get('mobile-watcher');

    // Init authentication provider
    get('authentication').init();

    // Render app
    ReactDOM.hydrate(<App handleErrors />, element);
}

/**
 * Global error handling on GraphQL calls
 *
 * @see https://www.apollographql.com/docs/link/links/error.html
 *
 * @returns {ErrorLink}
 */
function debugErrorHandler(error) {
    const { graphQLErrors, networkError } = error;

    if (graphQLErrors) {
        graphQLErrors.forEach(({ api_problem, message, path, code }) => {
            let formattedError = `[GraphQL error]: Message "${message}" at path "${path}"`;
            if (code) {
                formattedError += ` (code: ${code})`;
            }
            // see App\Infra\GraphQL\Error\InvalidPayloadError
            if (api_problem) {
                formattedError += ':';
                api_problem.violations.forEach(({ code, path, reason }) => {
                    formattedError += `\n    - ✗ violation at path "${path}": "${reason}" (code: "${code}")`;
                });
            }
            // eslint-disable-next-line no-console
            console.error(formattedError, graphQLErrors);
        });
    }

    if (networkError) {
        // eslint-disable-next-line no-console
        console.error(`[Network error]: ${networkError}`);
    }
}
