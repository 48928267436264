import PropTypes from 'prop-types';
import I18n from 'i18n-js';

export default function SeeAllButton(props) {
    const { onSeeAll } = props;

    if (!onSeeAll) {
        return null;
    }

    return (
        <button type="button" className="link link--primary" onClick={onSeeAll}>
            {I18n.t('search.results.seeAll')}
        </button>
    );
}

SeeAllButton.propTypes = {
    onSeeAll: PropTypes.func,
};

SeeAllButton.defaultProps = {
    onSeeAll: null,
};
