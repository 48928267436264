import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import { showTags } from '@App/store/actions/search';
import Button from '@App/components/ui/Button';

class ShowAllTagsButton extends Component {
    static propTypes = {
        active: PropTypes.bool.isRequired,
        showTags: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.props.showTags(!this.props.active);
    }

    render() {
        const { active } = this.props;

        return (
            <Button
                icon="tag"
                onClick={this.onClick}
                className={`action-button ${active ? 'action-button--primary' : ''}`}
                label={I18n.t('search.all_tags.' + (active ? 'hide' : 'show'))}
                tooltip
            >
            </Button>
        );
    }
}

export default connect(
    state => ({
        active: state.search.showTags,
    }),
    dispatch => ({
        showTags: value => dispatch(showTags(value)),
    })
)(ShowAllTagsButton);
