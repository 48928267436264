import { Component } from 'react';
import I18n from 'i18n-js';
import ResultBox from '@App/components/search/ResultBox';
import ResultTabsContent from '@App/components/search/ResultTabsContent';

export default class ResultTabs extends Component {
    static propTypes = {
        ...ResultBox.propTypes,
    };

    static defaultProps = {
        ...ResultBox.defaultProps,
    };

    static TAB_TAGS = 'tags';
    static TAB_TRACKS = 'tracks';
    static TAB_ALBUMS= 'albums';
    static TAB_PLAYLISTS = 'playlists';

    constructor(props) {
        super(props);

        this.state = {
            tab: this.constructor.getDefaultTab(props),
        };

        this.onClick = this.onClick.bind(this);
    }

    static getDefaultTab(props) {
        if (props.loading) {
            return null;
        }

        const { tags, tracks, albums, playlists } = props;
        const { TAB_TAGS, TAB_TRACKS, TAB_ALBUMS, TAB_PLAYLISTS } = this;

        if (tags.count > 0) {
            return TAB_TAGS;
        }

        if (tracks.count > 0) {
            return TAB_TRACKS;
        }

        if (albums.count > 0) {
            return TAB_ALBUMS;
        }

        if (playlists.count > 0) {
            return TAB_PLAYLISTS;
        }

        return TAB_TAGS;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading && !this.props.loading) {
            this.setTab(
                this.constructor.getDefaultTab(this.props)
            );
        }
    }

    setTab(tab) {
        this.setState({ tab });
    }

    onClick(event) {
        this.setTab(event.currentTarget.value);
    }

    renderTabButton(tab, search, label) {
        if (this.props.loading) {
            return null;
        }

        const { count } = search;

        if (count < 1) {
            return null;
        }

        const active = this.state.tab === tab;

        return (
            <button value={tab} type="button" className={`tabs__item ${active ? 'active' : ''}`} onClick={this.onClick}>
                <span>{label} ({count})</span>
            </button>
        );
    }

    renderContent() {
        if (this.props.loading) {
            return null;
        }

        const { TAB_TAGS, TAB_TRACKS, TAB_ALBUMS, TAB_PLAYLISTS } = this.constructor;
        const { tags, tracks, albums, playlists, onSearch, onFind, onSubmit } = this.props;
        const { renderTag, renderTrack, renderAlbum, renderPlaylist } = ResultBox;
        const { tab } = this.state;

        switch(tab) {
            case TAB_TAGS:
                return <ResultTabsContent>{tags.list.map(item => renderTag(item, onSearch))}</ResultTabsContent>;

            case TAB_TRACKS:
                return <ResultTabsContent onSeeAll={onSubmit}>{tracks.list.map(item => renderTrack(item, onFind))}</ResultTabsContent>;

            case TAB_ALBUMS:
                return <ResultTabsContent>{albums.list.map(item => renderAlbum(item, onFind))}</ResultTabsContent>;

            case TAB_PLAYLISTS:
                return <ResultTabsContent>{playlists.list.map(item => renderPlaylist(item, onFind))}</ResultTabsContent>;

            default:
                return null;
        }
    }

    render() {
        const { TAB_TAGS, TAB_TRACKS, TAB_ALBUMS, TAB_PLAYLISTS } = this.constructor;
        const { tags, tracks, albums, playlists } = this.props;

        return (
            <div className="result-tabs">
                <div className="tabs">
                    {this.renderTabButton(TAB_TAGS, tags, I18n.t('search.results.tags'))}
                    {this.renderTabButton(TAB_TRACKS, tracks, I18n.t('search.results.tracks'))}
                    {this.renderTabButton(TAB_ALBUMS, albums, I18n.t('search.results.albums'))}
                    {this.renderTabButton(TAB_PLAYLISTS, playlists, I18n.t('search.results.playlists'))}
                </div>
                <div className="tabs-content">
                    {this.renderContent()}
                </div>
            </div>
        );
    }
}
