import { Component } from 'react';
import PropTypes from 'prop-types';
import SeeAllButton from '@App/components/search/SeeAllButton';

export default class ResultTabsContent extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        ...SeeAllButton.propTypes,
    };

    static defaultProps = {
        ...SeeAllButton.defaultProps,
    };

    render() {
        const { children, onSeeAll } = this.props;

        return (
            <div>
                {children}
                {onSeeAll ? <SeeAllButton onSeeAll={onSeeAll} /> : null}
            </div>
        );
    }
}
