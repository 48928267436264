import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from '@App/container';
import classNames from 'classnames';

SidePanel.propTypes = {
    id: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['left', 'right']).isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    active: PropTypes.bool.isRequired,
    sticky: PropTypes.bool,
    withPlayer: PropTypes.bool,
    footer: PropTypes.node,
};

SidePanel.defaultProps = {
    withPlayer: false,
    footer: null,
    sticky: false,
};

export default function SidePanel({
    id,
    direction,
    title,
    onClose,
    children,
    active,
    sticky,
    withPlayer,
    footer,
}){
    /**
     * Whenever the side panel is active, we add a listener to the escape key to close it,
     * as well as a class to the body to prevent scrolling.
     *
     * Whenever the side panel is inactive (or just unmounted), we remove the listener and the class.
     */
    useEffect(() => {
        if (active) {
            getShortcut().addEventListener('escape', onClose);
            document.body.classList.add('no-scroll');
        }

        return () => {
            getShortcut().removeEventListener('escape', onClose);
            document.body.classList.remove('no-scroll');
        };
    }, [active, onClose]);

    return <div className={classNames('sidepanel', `sidepanel--${direction}`, id, {
        'sidepanel--sticky': sticky,
        'sidepanel--show': active,
        'sidepanel--with-player': withPlayer,
    })}>
        <button className="sidepanel__close" type="button" onClick={onClose}>
            <i className="icon icon--clear"></i>
        </button>
        <span className="sidepanel__title">
            {title}
        </span>

        <div className="sidepanel__content">
            {children}
        </div>
        {footer ? <div className="sidepanel__footer">{footer}</div> : null}
    </div>;
}

SidePanelSection.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
};

export function SidePanelSection({ title = null, children = null }) {
    return <div className="sidepanel__section">
        {title !== null
            ? <SidePanelSubSection title={title}>
                {children}
            </SidePanelSubSection>
            : children
        }
    </div>;
}

SidePanelSubSection.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export function SidePanelSubSection({ title = null, children = null }) {
    return <div className="sidepanel__subsection">
        <span key="subtitle" className="subtitle">{title}</span>
        {children}
    </div>;
}

SidePanelFooter.propTypes = {
    children: PropTypes.node,
};

export function SidePanelFooter({ children = null }) {
    return <div className="sidepanel__footer">
        {children}
    </div>;
}

/**
 * @return {Shortcut}
 */
function getShortcut() {
    return get('shortcut');
}
