import LabelCard from '@App/components/model/label/LabelCard';
import PropTypes from 'prop-types';
import labelPropTypes from '@App/components/model/label/PropTypes';
import Query from '@App/components/api/Query';
import GetLabels from '@App/api/query/GetLabels.graphql';

function LabelList({ listClassName, labels }) {
    return (
        <div className={listClassName}>
            {labels.map(label => <LabelCard key={label.reference} label={label} />)}
        </div>
    );
}

LabelList.propTypes = {
    listClassName: PropTypes.string.isRequired,
    labels: PropTypes.arrayOf(labelPropTypes.isRequired).isRequired
};

LabelList.defaultProps = {
    listClassName: 'thumblist__row',
    labels: [],
};

export default function LabelListQuery() {
    return <Query
        query={GetLabels}
        component={LabelList}
        variables={{}}
        mapResult={data => ({labels: data.labels})}
    />;
}
