import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Link from '@App/components/navigation/Link';
import { ALBUM } from '@App/routes';
import albumPropTypes from '@App/components/model/album/PropTypes';

export default function AlbumCard({
    album,
    displayLabel
}) {
    const link = {
        route: ALBUM.name,
        parameters: { reference: album.reference },
    };

    return (
        <div className="album">
            <Link {...link}>
                <span className="album__image">
                    <span className="image" style={{ background: album.coverAsLargeThumbnail ? `url('${album.coverAsLargeThumbnail}')` : undefined }} />
                </span>
            </Link>
            <div className="album__title text-ellipsis">{album.title}</div>
            { displayLabel && <div className="album__info text-ellipsis">{album.label.name}</div> }
            <div className="album__info">{I18n.t('page.library.albums.nbTracks', {nbTracks: album.nbTracks})}</div>
        </div>
    );
}

AlbumCard.propTypes = {
    album: albumPropTypes.isRequired,
    displayLabel: PropTypes.bool.isRequired
};

AlbumCard.defaultProps = {
    displayLabel: true
};
