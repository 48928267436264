import { get } from '@App/container';

export const STATS_TRACK_PLAYED = 'STATS_TRACK_PLAYED';

export function trackPlayed(reference, streaming = false) {
    const statsClient = get('stats_client');
    statsClient.trackPlayed(reference, streaming);

    return {
        type: STATS_TRACK_PLAYED,
        payload: { reference, streaming },
    };
}
