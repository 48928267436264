import I18n from 'i18n-js';
import Link from '@App/components/navigation/Link';
import { LABEL } from '@App/routes';
import labelPropTypes from '@App/components/model/label/PropTypes';

export default function LabelCard({
    label,
}) {
    const link = {
        route: LABEL.name,
        parameters: { reference: label.reference, slug: label.slug },
    };

    return (
        <div className="album">
            <Link {...link}>
                <span className="album__image">
                    <span className="image" style={{ background: label.logoAsLargeThumbnail ? `url('${label.logoAsLargeThumbnail}')` : undefined }} />
                </span>
                <div className="album__title text-ellipsis">{label.name}</div>
                <div className="album__info">{I18n.t('page.library.labels.nbAlbums', {nbAlbums: label.nbAlbums})}</div>
            </Link>
        </div>
    );
}

LabelCard.propTypes = {
    label: labelPropTypes.isRequired,
};
