import { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import SidePanel, { SidePanelSection } from '@App/components/layout/SidePanel';
import VersionsSelector from '@App/components/form/VersionsSelector';
import FormatSelector from '@App/components/form/FormatSelector';
import InsightsEvent from '@App/searchAnalytics/insightsEvent';
import DownloadTrackButton from '@App/components/download/DownloadTrackButton';
import { convert } from '@App/store/actions/searchAnalytics';

function DownloadTrackPanel ({
    onClose,
    withPlayer,
    trackReference,
    versionReference,
    versions,
}) {
    const [format, setFormat] = useState(FormatSelector.FORMATS[0]);
    const [selectedVersions, setSelectedVersions] = useState([
        versionReference ? versionReference : findMain(versions).reference,
    ]);

    function findMain(versions) {
        return versions.find(version => version.main);
    }

    function renderFooter() {

        return <DownloadTrackButton
            reference={trackReference}
            versions={selectedVersions}
            format={format}
        />;
    }

    return <SidePanel
        id="download-panel"
        direction="right"
        title={I18n.t('panel.download-track.title')}
        onClose={onClose}
        withPlayer={withPlayer}
        footer={renderFooter()}
        active
    >
        <SidePanelSection title={I18n.t('panel.download-track.versions')}>
            <VersionsSelector
                options={versions}
                value={selectedVersions}
                onChange={setSelectedVersions} />
        </SidePanelSection>
        <SidePanelSection title={I18n.t('panel.download-track.format')}>
            <FormatSelector value={format} onChange={setFormat} />
        </SidePanelSection>
    </SidePanel>;
}

DownloadTrackPanel.propTypes = {
    onClose: PropTypes.func.isRequired,
    withPlayer: PropTypes.bool.isRequired,
    trackReference: PropTypes.string.isRequired,
    versionReference: PropTypes.string,
    versions: PropTypes.arrayOf(
        PropTypes.shape({
            reference: PropTypes.string.isRequired,
            main: PropTypes.bool.isRequired,
            label: PropTypes.string.isRequired,
            duration: PropTypes.number.isRequired,
        }).isRequired
    ).isRequired,
};

DownloadTrackPanel.defaultProps = {
    versionReference: null,
};

const DownloadTrackPanelWrapper = connect(
    null,
    (dispatch, props) => ({
        // Proxy provided onClose prop to add analytics:
        onClose: () => {
            props.onClose();

            // Generate conversion analytics event:
            dispatch(convert(InsightsEvent.TRACK_DOWNLOADED, props.trackReference, props.trackedQueryID));
        }
    }),
    (stateProps, dispatchProps, ownProps) => {
        // Do not forward unexpected props to the wrapped component:
        const { ...scopedOwnProps } = ownProps;

        delete scopedOwnProps.trackedQueryID;
        delete scopedOwnProps.dispatch;

        return Object.assign({}, scopedOwnProps, stateProps, dispatchProps);
    }
)(DownloadTrackPanel);

DownloadTrackPanelWrapper.propTypes = {
    onClose: PropTypes.func.isRequired,
    trackReference: PropTypes.string.isRequired,
    trackedQueryID: PropTypes.string,
};

DownloadTrackPanelWrapper.defaultProps = {
    trackedQueryID: null,
};

export default connect((state) => ({
    trackedQueryID: state.search.searchTracksQueryID,
}))(DownloadTrackPanelWrapper);
